import { ADD_ERROR, REMOVE_ERROR, RECOVER_ERROR, CLEAR_ERRORS } from '../constants';
import emitter from '../../emitter';

const addError = error => ({ type: ADD_ERROR, payload: error });

const removeError = error => dispatch => {
  dispatch({ type: REMOVE_ERROR, payload: error });
  emitter.emit('addMessage', { isUndo: true });
};

const recoverError = () => ({ type: RECOVER_ERROR });

const clearErrors = () => ({ type: CLEAR_ERRORS });

const errors = { addError, removeError, recoverError, clearErrors };

export default errors;