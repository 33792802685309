import React from 'react';
import validator from 'validator';
import { isValidPhoneNumber } from 'libphonenumber-js'
import { FormattedMessage } from 'react-intl';

export const emailValidator = value => {
    if (!value || validator.isEmail(value)) {
        return false;
    }
    return <FormattedMessage id='signUP.badEmail' defaultMessage='Bad email address' />;
}

export const phoneValidator = value => {
    if (!value || isValidPhoneNumber(value)) {
        return false;
    }
    return <FormattedMessage id='signUP.badPhone' defaultMessage='Bad phone number' />;
}

export const urlValidator = value => {
    if (!value || validator.isURL(value, { protocols: ['http', 'https'] })) {
        return false;
    }
    return <FormattedMessage id='signUP.badUrl' defaultMessage='Bad url' />;
}