import React from 'react';
import { Ticket } from '.';

class Tickets extends React.PureComponent {
    render = () => {
        const {
            tickets,
            customerId,
        } = this.props;

        if (!Array.isArray(tickets)) {
            return null;
        }

        return tickets.map((ticket, index) => {
            return <Ticket ticket={ticket} customerId={customerId} key={index} />;
        });
    };
};

export default Tickets