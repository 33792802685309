import React from 'react';
import { Box, FormControl, MenuItem, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { LocaleContext } from '../../context';

class LanguageSelector extends React.Component {
    static contextType = LocaleContext;

    handleChangeLanguage = event => this.context.switchLanguage(event.target.value);

    render() {
        const { locale, languages } = this.context;

        return (
            <Box mt={1}>
                <FormControl fullWidth>
                    <TextField
                        select
                        variant='outlined'
                        fullWidth
                        label={<FormattedMessage id='languageSelector.language' defaultMessage='Language' />}
                        value={locale}
                        onChange={this.handleChangeLanguage}
                    >
                        {languages.map(({ lang, locale }) => (
                            <MenuItem key={lang} value={locale}>
                                {lang}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Box>
        );
    };
};

export default LanguageSelector;