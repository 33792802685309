import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { ControlledButton, ControlledRadioGroup, ControlledTextField, ControlledToolbar, ControllingForm } from '../../Form';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ClearIcon from '@material-ui/icons/Clear';
import { withRouter } from 'react-router';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';

class FindTicket extends React.Component {
    state = {
        ticketList: [],
        ticketNotFound: false,
    };

    findTicketHandler = async data => {
        const { customerId } = this.props;
        try {
            const ticketList = await fetcher.get(`staff/tickets/${customerId}/${data.tn}`);
            if (Array.isArray(ticketList) && ticketList.length !== 0) {
                this.setState({
                    ticketNotFound: false,
                    ticketList,
                });
            } else {
                this.setState({
                    ticketNotFound: true,
                    ticketList: [],
                });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    attachTicketHandler = async ({ tn }) => {
        const { callId, onSubmit } = this.props;
        try {
            const result = await fetcher.post(`staff/call/${callId}/ticket`, { call_id: callId, tn });
            if (result) {
                if (typeof onSubmit === 'function') {
                    await onSubmit();
                }
                this.props.history.push(`/call/${callId}`);
                return true;
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const {
            callId,
            customerId
        } = this.props;
        const {
            ticketNotFound,
            ticketList,
        } = this.state;
        if (!callId || !customerId) {
            return null;
        }

        if (ticketList.length !== 0) {
            return (
                <ControllingForm name='selectTicket'>
                    <ControlledRadioGroup
                        name='tn'
                        required
                    >
                        {ticketList.map(({ number, subject }) =>
                            <FormControlLabel
                                value={number}
                                key={number}
                                control={<Radio />}
                                label={`${number}, ${subject}`}
                            />)}
                    </ControlledRadioGroup>
                    <ControlledToolbar>
                        <ControlledButton
                            isSubmitButton
                            primary
                            icon={<NoteAddIcon />}
                            onClick={this.attachTicketHandler}
                        >
                            <FormattedMessage id='call.ticketFind.attach' defaultMessage='Attach ticket' />
                        </ControlledButton>
                        <ControlledButton
                            icon={<ClearIcon />}
                            onClick={() => {
                                this.setState({ ticketList: [] });
                            }}
                        >
                            <FormattedMessage id='call.clear' defaultMessage='Clear' />
                        </ControlledButton>
                    </ControlledToolbar>
                </ControllingForm>
            );
        }

        return (
            <ControllingForm name='findTicket' padding={1} md={12} lg={8}>
                <Box m={1}>
                    <Typography variant='body1' color={ticketNotFound ? 'error' : undefined}>
                        {ticketNotFound ?
                            <FormattedMessage id='call.ticketsNotFound' defaultMessage='Tickets not found' />
                            :
                            <FormattedMessage id='call.enterTicketNumber' defaultMessage='Enter ticket number or it part' />
                        }
                    </Typography>
                </Box>
                <ControlledTextField
                    name='tn'
                    label={<FormattedMessage id='call.ticketNumber' defaultMessage='Ticket number' />}
                    required
                    autoFocus />
                <ControlledToolbar>
                    <ControlledButton
                        isSubmitButton
                        primary
                        icon={<SearchIcon />}
                        onClick={this.findTicketHandler}
                    >
                        <FormattedMessage id='call.ticketFind.search' defaultMessage='Search' />
                    </ControlledButton>
                    <ControlledButton
                        icon={<CancelIcon />}
                        onClick={() => {
                            this.props.history.push(`/call/${callId}`);
                            return true;
                        }}
                    >
                        <FormattedMessage id='call.cancel' defaultMessage='Cancel' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

FindTicket.propTypes = {
    callId: PropTypes.string,
    customerId: PropTypes.number,
    onSubmit: PropTypes.func,
};

export default withRouter(FindTicket);