import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell, AccordionDetails, Accordion, AccordionSummary } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Localize from '../common/Localize';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Attachments from './Attachments';

class TicketHeader extends React.Component {
    render() {
        const { tn, state, type, staff, attachments } = this.props;
        const staffName = staff ?
            (staff.first_name + ' ' + staff.last_name)
            :
            <FormattedMessage id='ticket.staffNotAssigned' defaultMessage='Not assigned' />;
        const fields = [
            [
                <FormattedMessage id='ticket.tn' defaultMessage='Ticket number' />,
                tn
            ],
            [
                <FormattedMessage id='ticket.state' defaultMessage='State' />,
                <Localize>{state}</Localize>
            ],
            [
                <FormattedMessage id='ticket.type' defaultMessage='Type' />,
                <Localize>{type}</Localize>
            ],
            [
                <FormattedMessage id='ticket.staff' defaultMessage='Assigned to' />,
                staffName
            ],
        ];
        if (Array.isArray(attachments) && attachments.length !== 0) {
            fields.push([
                <FormattedMessage id='ticket.attachments' defaultMessage='Attachments' />,
                <Attachments {...{ attachments }} />
            ]);
        }
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell padding='none'>
                                    <Localize>
                                        {state}
                                    </Localize>
                                </TableCell>
                                <TableCell padding='none'>
                                    <Localize>
                                        {type}
                                    </Localize>
                                </TableCell>
                                <TableCell padding='none'>
                                    {staff ?
                                        `${staff.first_name} ${staff.last_name}`
                                        :
                                        <FormattedMessage id='ticket.staffNotAssigned.full' defaultMessage='Staff not assigned' />
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableBody>
                            {fields.map(([name, value], index) => (
                                <TableRow key={index} hover>
                                    <TableCell padding='none'>{name}</TableCell>
                                    <TableCell padding='none'>{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        );
    };
};

TicketHeader.propTypes = {
    tn: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    staff: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
    }),
}

export default TicketHeader