import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import ArticleBodyQuote from './ArticleBodyQuote';
import Attachments from './Attachments';

const styles = () => ({
});

export const prepareContent = (lines, variant) => {
    let state = {
        components: [],
        quote: [],
        isQuoteCollecting: false,
    }
    const getQuoteElement = lines => () => <ArticleBodyQuote {...{ lines }} />
    return lines.reduce((state, currentLine, index, array) => {
        const trimmed = currentLine.trim();
        if (trimmed.startsWith('>')) {
            state.isQuoteCollecting = true;
            state.quote.push(trimmed.substr(1));
        } else {
            if (state.isQuoteCollecting) {
                state.components.push(getQuoteElement(state.quote));
                state.quote = [];
                state.isQuoteCollecting = false;
            }
            state.components.push(() => <Typography variant={variant} >{trimmed === '' ? <br /> : trimmed}</Typography>);
        }
        if (index === array.length - 1) {
            if (state.isQuoteCollecting) {
                state.components.push(getQuoteElement(state.quote));
            }
            return state.components;
        } else {
            return state;
        }
    }, state);

}

class ArticleBody extends React.Component {
    render() {
        const { body, attachments } = this.props;
        let content = [];
        if (body) {
            content = prepareContent(body.split('\n'), 'body1');
        }
        return (
            <Box m={1} ml={4} pb={2} overflow='auto'>
                {content.map((Component, index) => (
                    <Component key={index} />
                ))}
                <Attachments {...{ attachments }} />
            </Box>
        );
    };
};

ArticleBody.propTypes = {
    body: PropTypes.string.isRequired,
    attachments: PropTypes.array.isRequired,
}

export default compose(
    withStyles(styles),
)(ArticleBody)