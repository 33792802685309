import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import MenuCard from '../../components/MenuCard';
import { Box } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import MenuCardContainer from '../../components/MenuCardContainer';

class Mailings extends React.Component {
  render() {
    return (
      <Box m={3}>
        <Grid container justify='center'>
          <MenuCardContainer>
            <MenuCard
              uri="/mailings/lists"
              icon={<ListIcon />}
              name={<FormattedMessage id="mailings.lists" defaultMessage="Mailing lists" />}
            />
            <MenuCard
              uri="/mailings/subscriptions"
              icon={<ListIcon />}
              name={<FormattedMessage id="mailings.subscriptions" defaultMessage="Subscriptions" />}
            />
          </MenuCardContainer>
        </Grid>
      </Box>
    );
  }
}

export default Mailings;
