import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuCard from '../components/MenuCard';
import store from '../util/store';
import { Box } from '@material-ui/core';

const styles = theme => ({
  utm5Card: {
    backgroundColor: theme.palette.cards.utm5,
  },
  iptvCard: {
    backgroundColor: theme.palette.cards.iptv,
  }
});


class OldLinks extends React.Component {
  state = {
    sessionId: null,
  };

  componentDidMount() {
    const { auth: { sessionId } } = store.getState();
    this.setState({ sessionId });
  };

  render() {
    const { classes, intl: { locale } } = this.props;
    const { sessionId } = this.state;

    return (
      <Box m={3}>
        <Grid container justify='center'>
          <Grid container spacing={2} item md={8}>
            <MenuCard
              uri={locale === 'ru' ?
                `https://secure.netup.biz/redirect.php?uri=/UTM5/private_office.php&session_id=${sessionId}`
                : `https://www.utm-billing.com/redirect.php?uri=/customer.php&session_id=${sessionId}`
              }
              icon={<NavigateNextIcon />}
              name={<FormattedMessage id='oldLinks.UTM5' defaultMessage='UTM5' />}
              cardClassNames={classes['utm5Card']}
              isExternalLink
            />
            <MenuCard
              uri={locale === 'ru' ?
                `https://office.netup.tv/redirect.php?uri=/ru-RU/private_office.php&session_id=${sessionId}`
                : `https://office.netup.tv/redirect.php?uri=/en-EN/private_office.php&session_id=${sessionId}`
              }
              icon={<NavigateNextIcon />}
              name={<FormattedMessage id='oldLinks.IPTV' defaultMessage='IPTV' />}
              cardClassNames={classes['iptvCard']}
              isExternalLink
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

OldLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(OldLinks);
