import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import compose from 'recompose/compose';
import { getErrorMessage } from '../util/getErrorMessage';
import { fetcher } from '../util/deps';
import store from '../util/store';
import { authActions } from '../redux-stuff/actions';
import { ControllingForm, ControlledTextField, ControlledPassword, ControlledToolbar, ControlledButton } from '../components/Form';
import { Box, Typography } from '@material-ui/core';
import LanguageSelector from '../components/Form/LanguageSelector';

function handleRedirectOnLogin() {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  if (params.get('from') !== null) {
    window.location.href = decodeURIComponent(params.get('from'));
  }
}
class LoginPage extends Component {
  state = {
    error: null,
  }

  handleLogin = async data => {
    try {
      const browser_secret = localStorage.getItem('browser_secret');
      const { authorized, username, session_id: sessionId, uid, is_staff: isStaff, need_totp } = await fetcher.post('auth', {...data, browser_secret});
      if (need_totp) {
        this.props.history.push('/totp');
        return true;
      }
      if (authorized) {
        handleRedirectOnLogin();
        store.dispatch(authActions.setIsAuth(authorized));
        store.dispatch(authActions.setAuth({ authorized, username, sessionId, uid, isStaff }));
        const { referrer = '/' } = this.props.location.state || {};
        this.props.history.push(referrer);
        return true;
      }
      this.setState({
        error: <FormattedMessage id='loginPage.authError' defaultMessage='Login or password incorrect' />,
      });
    } catch (e) {
      this.setState({ error: getErrorMessage(e.message) });
    }
  };

  render() {
    const { error } = this.state;
    const { login, password } = this.props.match.params;
    return (
      <ControllingForm name='login' onSubmit={this.handleLogin}>
        {error ?
          <Box>
            <Typography color='error'>
              {error}
            </Typography>
          </Box>
          : null}
        <ControlledTextField
          name='login'
          required
          autoFocus
          value={login && Buffer.from(login, 'base64').toString()}
          label={<FormattedMessage id='loginPage.email' defaultMessage='E-mail' />}
          autoComplete='username'
        />
        <ControlledPassword
          name='password'
          required
          value={password && Buffer.from(password, 'base64').toString()}
          label={<FormattedMessage id='loginPage.password' defaultMessage='Password' />}
          autoComplete='current-password'
        />
        <LanguageSelector />
        <ControlledToolbar>
          <ControlledButton
            isSubmitButton
            primary
            onClick={this.handleLogin}
          >
            <FormattedMessage id='loginPage.login' defaultMessage='Login' />
          </ControlledButton>
          <ControlledButton
            onClick={() => {
              this.props.history.push('/sign-up');
              return true;
            }}
          >
            <FormattedMessage id='loginPage.signUp' defaultMessage='Sign up' />
          </ControlledButton>
          <ControlledButton
            onClick={() => {
              this.props.history.push('/password-recovery');
              return true;
            }}
          >
            <FormattedMessage id='loginPage.passwordRecovery' defaultMessage='Password recovery' />
          </ControlledButton>
        </ControlledToolbar>
      </ControllingForm>
    );
  }
}

export default compose(
  withRouter,
)(LoginPage);
