import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ControllingForm } from '../components/Form';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import LoadingWrapper from '../components/common/LoadingWrapper';
import { Box, CircularProgress, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';

class HashSubscriptions extends React.Component {
    state = {
        loading: true,
        error: false,
        email: undefined,
        subscriptions: {},
        updatingList: undefined,
    };

    async componentDidMount() {
        const { match: { params: { hash } } } = this.props;
        this.setState({ loading: true });
        try {
            const { email, lang, subscriptions } = await fetcher.get(`hash/subscriptions/${hash}`);
            this.setState({ loading: false, email, subscriptions, lang });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ loading: false, error: true });
        }
    }

    updateSubscription = async (list, subscribed) => {
        const { match: { params: { hash } } } = this.props;
        const path = `hash/subscriptions/${hash}`;
        this.setState({ updatingList: list });
        try {
            await (subscribed ? fetcher.put(`${path}/${list}`) : fetcher.delete(`${path}/${list}`));
            const { subscriptions } = await fetcher.get(path);
            this.setState({ updatingList: undefined, subscriptions });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ error: true });
        }
    }

    render() {
        const {
            email,
            loading,
            error,
            subscriptions,
            updatingList,
            lang,
        } = this.state;
        return (
            <LoadingWrapper wrap={loading} error={error}>
                <ControllingForm name='subscriptions'>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled
                        value={email}
                        name='email'
                        label={<FormattedMessage id='subscriptions.email' defaultMessage='E-mail' />}
                    />
                    {Object.entries(subscriptions).filter(([, { lang: entryLang }]) => entryLang === lang).map(([id, { name, description, subscribed }]) => (
                        <Box key={id}>
                            <FormControlLabel
                                control={
                                    updatingList === id ?
                                        <Box ml={1} mr={2}>
                                            <CircularProgress size={34} />
                                        </Box>
                                        :
                                        <Switch
                                            checked={subscribed}
                                            color='primary'
                                            onChange={({ target: { checked } }) => this.updateSubscription(id, checked)}
                                        />}
                                label={
                                    <React.Fragment>
                                        {name}
                                        {description && description.split("\n").map((line, i) => (
                                            <Typography variant='caption' component='p' key={i}>
                                                {line || <br />}
                                            </Typography>
                                        ))}
                                    </React.Fragment>
                                }
                            />
                        </Box>
                    ))}
                </ControllingForm>
            </LoadingWrapper>
        );
    };
};

export default HashSubscriptions