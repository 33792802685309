import { SET_ISSUES } from '../constants';

const issues = (issues = null, action) => {
  switch (action.type) {
    case SET_ISSUES:
      return action.payload;
    default:
      return issues;
  }
};

export default issues;