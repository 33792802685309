import React from 'react';
import { CustomerID, FindTicket, NoSupport, SupportActions } from '.';
import { AgentCreateTicket } from '../Ticket';

class CallActions extends React.PureComponent {
    render = () => {
        const {
            id,
            action,
            onSubmit,
            customerId,
            hasSupport,
        } = this.props;

        switch (action) {
            case 'set-customer':
                return <CustomerID
                    callId={id}
                    showCancelButton
                    onSubmit={onSubmit}
                />;
            case 'find-ticket':
                return <FindTicket
                    callId={id}
                    customerId={customerId}
                    showCancelButton
                    onSubmit={onSubmit}
                />;
            case 'create-ticket':
                return <AgentCreateTicket
                    callId={id}
                    customerId={customerId}
                    showCancelButton
                    onSubmit={onSubmit}
                />;
            default:
                if (!customerId) {
                    return <CustomerID
                        callId={id}
                        onSubmit={onSubmit}
                    />;
                } else if (!hasSupport) {
                    return <NoSupport callId={id} />;
                }
                return <SupportActions callId={id} />;
        }
    };
};

export default CallActions