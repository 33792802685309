import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Paper, Typography } from '@material-ui/core';
import { Article, ArticleList, TicketHeader } from '../../Ticket';
import { AgentReplyForm } from '.';

class AgentTicket extends React.Component {
    render = () => {
        const {
            customerId,
            ticket,
            onSubmit,
        } = this.props;

        if (!ticket) {
            return null;
        }

        const { tn, state, first_article, articles, last_article, can_answer, can_reopen, product } = ticket;

        return (
            <Box width='100%'>
                <TicketHeader {...ticket} />
                <Article {...first_article} />
                <ArticleList articles={articles} />
                {last_article ? <Article {...last_article} /> : null}
                {!can_answer && !can_reopen ?
                    <Paper>
                        <Box p={2}>
                            <Typography color='error'>
                                <FormattedMessage
                                    id='call.ticket.cantReopen'
                                    defaultMessage='Ticket is closed. Your need {product} support to reopen it'
                                    values={{ product: product.toUpperCase() }}
                                />
                            </Typography>
                        </Box>
                    </Paper>
                    :
                    <AgentReplyForm
                        tn={tn}
                        customerId={customerId}
                        closed={state === 'closed'}
                        onSubmit={onSubmit}
                    />
                }
            </Box>
        );
    }
}

AgentTicket.propTypes = {
    callId: PropTypes.string,
    showCancelButton: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default AgentTicket;