import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Localize from '../common/Localize';
import { ControlledTextField } from '../Form';

function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

class Utm5LicenseSelector extends ControlledTextField {
    render() {
        const {
            name,
            label,
            licenses,
            ...restProps
        } = this.props;

        return (
            <ControlledTextField
                {...restProps}
                name={name}
                label={label}
                select
                value={false}
            >
                <MenuItem value={false}>
                    <FormattedMessage id='addLicense.select' defaultMessage='<select>' />
                </MenuItem>
                {Array.isArray(licenses) && licenses.map(({ id, days, limitations }) => (
                    <MenuItem value={id} key={id}>
                        <Table size='small' padding='none'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage
                                                id='addLicense.licenseMenuItem'
                                                defaultMessage='License number {id}, valid till {till}'
                                                values={{
                                                    id,
                                                    till: (
                                                        <FormattedDate
                                                            value={addDays(new Date(), +days)}
                                                            year='numeric'
                                                            month='numeric'
                                                            day='numeric'
                                                        />
                                                    ),
                                                }}
                                            />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {Array.isArray(limitations) && limitations.length > 0 &&
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id='license.options' defaultMessage='Options' />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableHead>
                            <TableBody>
                                {Array.isArray(limitations) && limitations.map((limit) => Object.entries(limit).map(([name, value]) => (
                                    <TableRow key={name} hover>
                                        <TableCell padding='none'>
                                            <Localize>
                                                {name}
                                            </Localize>
                                        </TableCell>
                                        <TableCell padding='none'>
                                            {value}
                                        </TableCell>
                                    </TableRow>
                                )))
                                }
                            </TableBody>
                        </Table>
                    </MenuItem>
                ))}
            </ControlledTextField>
        );
    };
}

export default Utm5LicenseSelector;