import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuCard from './MenuCard';

const MenuCardContainer = ({ children }) => {
  const cards = React.Children.count(children) !== 1 ?
    children.filter(child => {
      return child && child.type === MenuCard;
    })
    :
    children && children.type === MenuCard ? [children] : [];

  const content = cards.length % 2 === 0 ?
    children
    :
    React.Children.map(children, child => {
      if (child === cards[cards.length - 1]) {
        return React.cloneElement(child, { isSingleCard: true });
      }
      return child;
    });

  return (
    <Grid container spacing={2} item md={8}>
      {content}
    </Grid>
  );
};

export default MenuCardContainer;