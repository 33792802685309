import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ControlledTextField, ControllingForm, Captcha, ControlledToolbar, ControlledButton, ControlledPassword } from '../components/Form';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import store from '../util/store';
import { authActions } from '../redux-stuff/actions';
import { emailValidator } from '../util/validators';

class PasswordRecoveryVerify extends React.Component {
    submitHandler = async (data) => {
        try {
            const result = await fetcher.post('password-reset/verify', data);
            const { authorized, username, session_id: sessionId, uid } = result;
            if (authorized) {
                store.dispatch(authActions.setIsAuth(authorized));
                store.dispatch(authActions.setAuth({ authorized, username, sessionId, uid }));
            } else {
                emitter.emit('addMessage', {
                    type: 'failure',
                    data: {
                        errorMessage: 'unexpected result',
                        details: result,
                        time: new Date(),
                        agent: 'signUpVerify',

                    }
                });
            }
            this.props.history.push(`/`);
            return true;
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render() {
        const { match: { params: { email: encodedEmail, key } } } = this.props;
        const email = encodedEmail && decodeURIComponent(encodedEmail);
        return (
            <ControllingForm name='passwordRecoveryVerify' >
                <ControlledTextField
                    name='email'
                    value={email}
                    label={<FormattedMessage id='passwordRecovery.email' defaultMessage='E-mail' />}
                    required
                    autoComplete='email'
                    maxLength={255}
                    validator={emailValidator}
                />
                <ControlledTextField
                    name='key'
                    value={key}
                    label={<FormattedMessage id='passwordRecovery.verificationKey' defaultMessage='Verification key' />}
                    required
                    autoComplete='one-time-code'
                    maxLength={32}
                />
                <ControlledPassword
                    name='password'
                    label={<FormattedMessage id='passwordRecovery.Password' defaultMessage='New password' />}
                    required
                    autoComplete='new-password'
                    showStrength
                    maxLength={255}
                />
                <Captcha />
                <ControlledToolbar>
                    <ControlledButton
                        isSubmitButton
                        primary
                        icon={<DoneIcon />}
                        onClick={this.submitHandler}
                    >
                        <FormattedMessage id='passwordRecoveryVerify.submit' defaultMessage='Set new password' />
                    </ControlledButton>
                    <ControlledButton
                        icon={<CancelIcon />}
                        onClick={() => {
                            this.props.history.push('/');
                            return true;
                        }}
                    >
                        <FormattedMessage id='passwordRecovery.cancel' defaultMessage='Cancel' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    };
};

export default PasswordRecoveryVerify