import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, withWidth } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const styles = theme => ({
  listItemText: {
    '& span': { whiteSpace: 'initial' },
  },
  actionButton: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
});

function ActionsMenu({ open, anchorEl, handleClose, items, classes, width }) {
  const mobile = width === 'xs' || isMobile;
  const elements = items.filter(e => e.show);
  if (mobile) {
    return elements.map(({ Icon, text, handler }, i) => (
      <Tooltip title={text} key={i}>
        <IconButton color='inherit' onClick={handler} className={classes.actionButton}>
          <Icon />
        </IconButton>
      </Tooltip>
    ));
  }
  return (
    <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
      {
        elements.map(({ Icon, text, handler }, i) => {
          return (
            <MenuItem key={i} onClick={handler}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} inset primary={text} />
            </MenuItem>
          );
        })}
    </Menu>
  );
}

export default compose(
  withStyles(styles),
  withWidth()
)(ActionsMenu);
