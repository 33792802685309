import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ControlledButton, ControlledTextField, ControlledToolbar, ControllingForm } from '../../Form';
import SaveIcon from '@material-ui/icons/Save';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';

class Comment extends React.Component {
    state = {
        comment: undefined,
    };

    updateCommentHandler = async (data) => {
        const { callId } = this.props;
        try {
            const result = await fetcher.post(`staff/call/${callId}/comment`, data);
            if (result) {
                const { comment } = data;
                this.setState({ comment });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    async componentDidMount() {
        const { callId } = this.props;
        try {
            const comment = await fetcher.get(`staff/call/${callId}/comment`);
            this.setState({ comment });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const { callId } = this.props;
        const { comment } = this.state;
        if (!callId || comment === undefined) {
            return null;
        }

        return (
            <ControllingForm name='callComment' padding={1} md={12} lg={8}>
                <ControlledTextField
                    name='comment'
                    label={<FormattedMessage id='call.comment' defaultMessage='Comment' />}
                    required
                    multiline
                    value={comment}
                />
                <ControlledToolbar>
                    <ControlledButton
                        isSubmitButton
                        primary
                        icon={<SaveIcon />}
                        onClick={this.updateCommentHandler}
                    >
                        <FormattedMessage id='call.comment.submit' defaultMessage='Save' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

Comment.propTypes = {
    callId: PropTypes.string,
};

export default Comment;