import React, { useState } from 'react';
import { AccordionSummary, Accordion, Box, AccordionDetails, Typography, Button } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Article } from '.';
import { FormattedMessage } from 'react-intl';

const ArticleList = props => {
    const { articles } = props;
    const [shift, setShift] = useState(0);
    const [renderArticles, setRenderArticles] = useState(false);
    if (!Array.isArray(articles) || articles.length === 0) {
        return null;
    }
    const page_size = 10;
    const start = articles.length < page_size * (1 + shift) ? 0 : articles.length - page_size * (1 + shift);
    return (
        <Box mt={1} mb={1}>
            <Accordion onChange={(e, expanded) => setRenderArticles(expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h6'>
                        <FormattedMessage id='ticket.otherArticles' defaultMessage='Other articles' />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {renderArticles &&
                        <Box width='100%'>
                            {articles.length > page_size * (1 + shift) &&
                                <Button
                                    fullWidth
                                    onClick={() => setShift(shift + 1)}
                                >
                                    <MoreHorizIcon/>
                            </Button>
                            }
                            {articles.slice(start).map((article, index) => (
                                <Article {...article} key={index} />
                            ))}
                        </Box>
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default ArticleList;