import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ErrorWrapper = (props) => {
    const { children, wrap } = props;
    return (
        wrap ?
            <Typography color="error" variant="body1" align='center'>
                <FormattedMessage id="errorMessage" defaultMessage="An error occurred" />
            </Typography>
            :
            children
    );
}

export default ErrorWrapper;