import React from 'react';
import FormattedDuration, { TIMER_FORMAT } from 'react-intl-formatted-duration';

class Duration extends React.Component {
    state = {
        timerId: undefined,
        duration: undefined,
    };

    componentWillUnmount() {
        const { timerId } = this.state;
        if (timerId) {
            clearTimeout(timerId);
        }
    }

    updateDuration = () => {
        const {
            start,
            end,
        } = this.props;
        const {
            timerId,
        } = this.state;
        if (!start) {
            this.setState({ duration: undefined });
        } else {
            if (!end && !timerId) {
                const timerId = setInterval(this.updateDuration, 1000);
                this.setState({ timerId });
            }
            if (end && timerId) {
                clearTimeout(timerId);
                this.setState({ timerId: undefined });
            }
            const duration = ((end ? end : new Date()) - start) / 1000;
            this.setState({ duration });
        }
    }

    componentDidMount() {
        this.updateDuration();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.end !== this.props.end) {
            this.updateDuration();
        }
    }

    render = () => {
        const {
            duration,
        } = this.state;
        if (!duration) {
            return null;
        }
        return <FormattedDuration seconds={duration} format={TIMER_FORMAT} />;
    }
}

export default Duration;