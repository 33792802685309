import { defineMessages } from 'react-intl';
export * from './issues';
export * from './snackbarMessageTypes';
export * from './drawer';
export * from './appBar';
export * from './auth';
export * from './errors';

export const USER_LOGOUT = Symbol();

export const unloadMessage = defineMessages({
  message: {
    id: 'unloadSnackbarMessage',
    defaultMessage: `Are you sure you want to leave this page? There are unsaved changes`,
  },
});
