import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  outer: {
    display: 'flex',
    justifyContent: 'center',

    marginTop: theme.spacing(1) * 3,
  },
});

const Page404 = ({ classes }) => {
  document.title = '404 Not Found'
  return (
    <div className={classes.outer}>
      <Typography variant="h4">
        <FormattedMessage id="errors.notfound" defaultMessage="404 Not Found" />
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Page404);
