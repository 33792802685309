import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Grid } from '@material-ui/core';
import LoadingWrapper from '../common/LoadingWrapper';
import { injectIntl } from 'react-intl';

const recaptchaRef = React.createRef();

class ControllingForm extends React.Component {
    state = {
        canSubmit: true,
        values: {},
    };

    canSubmit() {
        const { values } = this.state;
        const { children } = this.props;
        let rv = true;
        React.Children.forEach(children, (child) => {
            if (!child) {
                return;
            }
            const { controlType } = child.type;
            const props = child.props;
            const { name } = props;
            const value = values[name];
            switch (controlType) {
                case 'input':
                    const { validator, required } = props;
                    if ((required && !value) || (typeof validator === 'function' && validator(value))) {
                        rv = false;
                    }
                    break;
                case 'form':
                    if (!value || !value.canSubmit) {
                        rv = false;
                    }
                    break;
                case 'captcha':
                    if (!values['captcha']) {
                        rv = false;
                    }
                    break;
                default:
                    break;
            }
        })
        return rv;
    }

    getChangeHandler = (name) => (value, error = false) => {
        const { values } = this.state;
        const { onUpdate } = this.props;
        values[name] = value;
        this.setState({ values }, () => {
            const canSubmit = this.canSubmit() && !error;
            this.setState({ canSubmit });
            if (typeof onUpdate === 'function') {
                onUpdate({ data: values, canSubmit });
            }
        });
    }

    getClickHandler = (handler) => async () => {
        const { values } = this.state;
        const { intl: { locale }, children, name: formName } = this.props;
        if (!('locale' in values)) {
            values['locale'] = locale;
        }
        if (recaptchaRef.current && typeof recaptchaRef.current.reset === 'function') {
            recaptchaRef.current.reset();
        }
        if (typeof handler === 'function') {
            this.setState({ sending: true });
            // handler returns true if component will umount
            if (!await handler(values)) {
                this.setState({ sending: false });
            } else {
                React.Children.forEach(children, (child) => {
                    if (!child) {
                        return;
                    }
                    const { name } = child.props;
                    const { cleanup } = child.type;
                    if (typeof cleanup === 'function') {
                        cleanup(formName, name);
                    }
                });
            }
        }
    }

    handleEnterPressed = event => {
        const code = event.keyCode || event.which;
        if (code === 13) {
            const { onSubmit } = this.props;
            if (this.state.canSubmit && typeof onSubmit === 'function') {
                this.getClickHandler(onSubmit)();
            }
        }
    };

    render() {
        const {
            sending,
            canSubmit,
            values,
        } = this.state;
        const {
            name: formName,
            slim,
            padding,
            md,
            lg,
            xl,
            fullWidth,
            flat,
            children,
        } = this.props;

        const p = slim ? 0 : (padding ? padding : 3);
        return (
            <LoadingWrapper wrap={sending}>
                <Box pt={1} pl={slim ? 0 : 1} pr={slim ? 0 : 1} pb={slim ? 0 : 1} width='100%' display='flex'>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid
                            item
                            xs={12}
                            md={md ? md : (fullWidth ? 12 : 10)}
                            lg={lg ? lg : (fullWidth ? 12 : 6)}
                            xl={xl ? xl : (fullWidth ? 12 : 4)}
                        >
                            <Paper onKeyPress={this.handleEnterPressed} elevation={flat ? 0 : 1}>
                                <Box p={p} >
                                    {
                                        React.Children.map(children, (child, index) => {
                                            if (!child) {
                                                return null;
                                            }
                                            const { controlType } = child.type || {};
                                            const props = child.props;
                                            const { name } = props;
                                            switch (controlType) {
                                                case 'input':
                                                    const value = values[name] || props.value;
                                                    return React.cloneElement(child, {
                                                        ...props,
                                                        index,
                                                        formName,
                                                        value,
                                                        onChange: this.getChangeHandler(name),
                                                    });
                                                case 'form':
                                                    return React.cloneElement(child, {
                                                        ...props,
                                                        onUpdate: this.getChangeHandler(name),
                                                    });
                                                case 'captcha':
                                                    return React.cloneElement(child, {
                                                        ...props,
                                                        ref: recaptchaRef,
                                                        onChange: this.getChangeHandler('captcha'),
                                                    });
                                                case 'toolbar':
                                                    return React.cloneElement(child, {
                                                        ...props,
                                                        canSubmit,
                                                        clickHandler: this.getClickHandler,
                                                    });
                                                default:
                                                    return child;
                                            }
                                        })
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </LoadingWrapper>
        );
    };
};

ControllingForm.propTypes = {
    name: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    onUpdate: PropTypes.func,
    slim: PropTypes.bool,
    padding: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    fullWidth: PropTypes.bool,
};

export default injectIntl(ControllingForm);