import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Hidden, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, withWidth } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { countries } from 'countries-list';
import Localize from '../common/Localize';
import { DownloadLicenseButton } from '.';

class IptvLicense extends React.Component {
    render() {
        const {
            organization,
            certificate_number,
            organization_unit,
            country,
            state,
            location,
            systems,
            width,
        } = this.props;
        const { native, emoji } = countries[country] || { native: country, emoji: '' };
        return (
            <Paper>
                <Box mb={1} p={1} display='flex' width='100%'>
                    <Box width='100%'>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Hidden mdDown>
                                    <Grid item lg={1}>
                                        {certificate_number}
                                    </Grid>
                                </Hidden>
                                <Grid item xs={4} sm={2}>
                                    <FormattedMessage id='license.iptv' defaultMessage='IPTV' />
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    {organization}
                                </Grid>
                                <Hidden mdDown>
                                    <Grid item lg={4}>
                                        {organization_unit}
                                    </Grid>
                                </Hidden>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box width='100%'>
                                    {Array.isArray(systems) && systems.length !== 0 ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <Hidden xsDown>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.number' defaultMessage='License number' />
                                                        </TableCell>
                                                    </Hidden>
                                                    <TableCell padding='none'>
                                                        <FormattedMessage id='license.component' defaultMessage='Component' />
                                                    </TableCell>
                                                    <TableCell padding='none'>
                                                        <FormattedMessage id='license.validityPeriod' defaultMessage='Validity period' />
                                                    </TableCell>
                                                    <Hidden xsDown>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.options' defaultMessage='Options' />
                                                        </TableCell>
                                                    </Hidden>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {systems.map(({ type, license_number, till, options }) => (
                                                    license_number ?
                                                        <TableRow key={license_number} hover>
                                                            <Hidden xsDown>
                                                                <TableCell padding='none'>
                                                                    {license_number}
                                                                </TableCell>
                                                            </Hidden>
                                                            <TableCell padding='none'>
                                                                <Localize>
                                                                    {type}
                                                                </Localize>
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                <Typography color={new Date(till) > new Date() ? 'initial' : 'error'} variant='body2'>
                                                                    <FormattedDate
                                                                        value={till}
                                                                        year='numeric'
                                                                        month='numeric'
                                                                        day='numeric'
                                                                        minute={width === 'xs' ? undefined : 'numeric'}
                                                                        hour={width === 'xs' ? undefined : 'numeric'}
                                                                    />
                                                                </Typography>
                                                            </TableCell>
                                                            <Hidden xsDown>
                                                                <TableCell padding='none'>
                                                                    {Array.isArray(options) && options.length !== 0 ?
                                                                        options.map(({ name, value }, index) => (
                                                                            <Grid container key={index}>
                                                                                <Grid item xs={8}>
                                                                                    <Localize>
                                                                                        {name}
                                                                                    </Localize>
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    {typeof value === 'boolean' ?
                                                                                        (value ?
                                                                                            <FormattedMessage id='bool.true' defaultMessage='true' />
                                                                                            :
                                                                                            <FormattedMessage id='bool.false' defaultMessage='false' />
                                                                                        )
                                                                                        : value
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))
                                                                        :
                                                                        <FormattedMessage id='license.noOptions' defaultMessage='No options' />
                                                                    }
                                                                </TableCell>
                                                            </Hidden>
                                                        </TableRow>
                                                        : null
                                                ))}
                                            </TableBody>
                                        </Table>
                                        : null
                                    }
                                    <Accordion>
                                        <AccordionSummary>
                                            <FormattedMessage id='license.details' defaultMessage='Details' />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table>
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.id' defaultMessage='License number' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {certificate_number}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.organization' defaultMessage='Organization' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {organization}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.department' defaultMessage='Department' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {organization_unit}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.country' defaultMessage='Country' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {emoji} {native}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.region' defaultMessage='Region' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {state}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.city' defaultMessage='City' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {location}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box display='flex' flexWrap='nowrap' alignItems='center' mr={1}>
                        <DownloadLicenseButton type='iptv' id={certificate_number} />
                    </Box>
                </Box>
            </Paper>
        );
    }
}

export default withWidth()(IptvLicense);
