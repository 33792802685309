import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import compose from 'recompose/compose';
import MenuCard from '../../components/MenuCard';
import { Box } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import MenuCardContainer from '../../components/MenuCardContainer';

const styles = theme => ({
  callCard: {
    backgroundColor: theme.palette.cards.hotline,
  },
});


class StaffMainPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Box m={3}>
        <Grid container justify='center'>
          <MenuCardContainer>
            <MenuCard
              uri="/call/new"
              icon={<CallIcon />}
              name={<FormattedMessage id="mainPage.newCall" defaultMessage="New call" />}
              cardClassNames={classes['callCard']}
            />
            <MenuCard
              uri="/mailings"
              icon={<MailIcon />}
              name={<FormattedMessage id="mainPage.mailings" defaultMessage="Mailings" />}
            />
          </MenuCardContainer>
        </Grid>
      </Box>
    );
  }
}

StaffMainPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(StaffMainPage);
