import React from 'react';
import { FormattedMessage } from 'react-intl';

const Localize = props => {
    const {
        children,
        addProductType,
    } = props;
    switch (children) {
        case 'iptv_bugreport':
            return <FormattedMessage id="serverMessage.iptvBugreport" defaultMessage="Bug report" />
        case 'iptv_support':
            if (addProductType) {
                return <FormattedMessage id="serverMessage.iptvSupport.withProduct" defaultMessage="IPTV support" />
            }
            return <FormattedMessage id="serverMessage.iptvSupport" defaultMessage="Support" />
        case 'utm5_bugreport':
            return <FormattedMessage id="serverMessage.utm5Bugreport" defaultMessage="Bug report" />
        case 'utm5_support':
            if (addProductType) {
                return <FormattedMessage id="serverMessage.utm5Support.withProduct" defaultMessage="UTM5 support" />
            }
            return <FormattedMessage id="serverMessage.utm5Support" defaultMessage="Support" />
        case 'iptv_transcoder':
            return <FormattedMessage id="serverMessage.iptvTranscoder" defaultMessage="Transcoder" />
        case 'old_utm5_outsorcing':
            return <FormattedMessage id="serverMessage.oldUtm5Outsorcing" defaultMessage="Outsorcing (deprecated)" />
        case 'old_utm5_ext_support':
            return <FormattedMessage id="serverMessage.oldUtm5ExtSupport" defaultMessage="Advanced support (deprecated)" />
        case 'old_utm5_support':
            return <FormattedMessage id="serverMessage.oldUtm5Support" defaultMessage="Support (deprecated)" />
        case 'open':
            return <FormattedMessage id="serverMessage.open" defaultMessage="Open" />
        case 'closed':
            return <FormattedMessage id="serverMessage.closed" defaultMessage="Closed" />
        case 'request':
            return <FormattedMessage id="serverMessage.request" defaultMessage="Request" />
        case 'reply':
            return <FormattedMessage id="serverMessage.answer" defaultMessage="Answer" />
        case 'phone_request':
            return <FormattedMessage id="serverMessage.phoneRequest" defaultMessage="Phone request" />
        case 'note':
            return <FormattedMessage id="serverMessage.note" defaultMessage="Note" />
        case 'CORE':
            return <FormattedMessage id="serverMessage.CORE" defaultMessage="UTM5 core" />
        case 'RADIUS':
            return <FormattedMessage id="serverMessage.RADIUS" defaultMessage="RADIUS" />
        case 'TEL':
            return <FormattedMessage id="serverMessage.TEL" defaultMessage="Telephony" />
        case 'HOTSPOT':
            return <FormattedMessage id="serverMessage.HOTSPOT" defaultMessage="HotSpot" />
        case 'DYNASHAPE':
            return <FormattedMessage id="serverMessage.DYNASHAPE" defaultMessage="DynaShape" />
        case 'URFACLIENT':
            return <FormattedMessage id="serverMessage.URFACLIENT" defaultMessage="URFA client" />
        case 'DEALER':
            return <FormattedMessage id="serverMessage.DEALER" defaultMessage="Dealer" />
        case '1C':
            return <FormattedMessage id="serverMessage.1C" defaultMessage="1C" />
        case 'CASHIER':
            return <FormattedMessage id="serverMessage.CASHIER" defaultMessage="Cashier" />
        case 'DHCP':
            return <FormattedMessage id="serverMessage.DHCP" defaultMessage="DHCP" />
        case 'IPTV':
            return <FormattedMessage id="serverMessage.IPTV" defaultMessage="IPTV" />
        case 'CPORTAL':
            return <FormattedMessage id="serverMessage.CPORTAL" defaultMessage="Captive portal" />
        case 'MEGOGO':
            return <FormattedMessage id="serverMessage.MEGOGO" defaultMessage="MEGOGO" />
        case 'LIFESTREAM':
            return <FormattedMessage id="serverMessage.LIFESTREAM" defaultMessage="LIFESTREAM" />
        case 'BEELINE':
            return <FormattedMessage id="serverMessage.BEELINE" defaultMessage="BEELINE" />
        case 'IPTVPORTAL':
            return <FormattedMessage id="serverMessage.IPTVPORTAL" defaultMessage="IPTVPORTAL" />
        case 'IRDETO':
            return <FormattedMessage id="serverMessage.IRDETO" defaultMessage=" Irdeto Hosted CA" />
        case 'ENTERPRISE':
            return <FormattedMessage id="serverMessage.ENTERPRISE" defaultMessage="UTM5+ Enterprise" />
        case 'TELECOM':
            return <FormattedMessage id="serverMessage.TELECOM" defaultMessage="UTM5+ Telecom" />
        case 'MAX_USERS':
            return <FormattedMessage id="serverMessage.MAX_USERS" defaultMessage="Max users" />
        case 'MAX_ACCOUNTS':
            return <FormattedMessage id="serverMessage.MAX_ACCOUNTS" defaultMessage="Max accounts" />
        case 'MAX_ONCE_LINKS':
            return <FormattedMessage id="serverMessage.MAX_ONCE_LINKS" defaultMessage="Max once links" />
        case 'MAX_PERIODIC_LINKS':
            return <FormattedMessage id="serverMessage.MAX_PERIODIC_LINKS" defaultMessage="Max periodic links" />
        case 'MAX_IP_LINKS':
            return <FormattedMessage id="serverMessage.MAX_IP_LINKS" defaultMessage="Max IP-traffic links" />
        case 'MAX_TEL_LINKS':
            return <FormattedMessage id="serverMessage.MAX_TEL_LINKS" defaultMessage="Max tel links" />
        case 'MAX_HOTSPOT_LINKS':
            return <FormattedMessage id="serverMessage.MAX_HOTSPOT_LINKS" defaultMessage="Max hotspot links" />
        case 'MAX_DIALUP_LINKS':
            return <FormattedMessage id="serverMessage.MAX_DIALUP_LINKS" defaultMessage="Max dialup links" />
        case 'MAX_NF_RECORDS':
            return <FormattedMessage id="serverMessage.MAX_NF_RECORDS" defaultMessage="Max NetFlow records" />
        case 'MAX_RADIUS_AUTH':
            return <FormattedMessage id="serverMessage.MAX_RADIUS_AUTH" defaultMessage="Max RADIUS auth" />
        case 'MAX_RADIUS_ACCT':
            return <FormattedMessage id="serverMessage.MAX_RADIUS_ACCT" defaultMessage="Max RADIUS acct" />
        case 'RPCF_LIST':
            return <FormattedMessage id="serverMessage.RPCF_LIST" defaultMessage="Allowed URFA functions" />
        case 'MAX_DYNA_RULES':
            return <FormattedMessage id="serverMessage.MAX_DYNA_RULES" defaultMessage="Max dynashape rules" />
        case 'MAX_DEALER_USERS':
            return <FormattedMessage id="serverMessage.MAX_DEALER_USERS" defaultMessage="Max dealer users" />
        case 'MAX_DEALER_SERVICES':
            return <FormattedMessage id="serverMessage.MAX_DEALER_SERVICES" defaultMessage="Max dealer services" />
        case 'MAX_DEALER_TARIFFS':
            return <FormattedMessage id="serverMessage.MAX_DEALER_TARIFFS" defaultMessage="Max dealer tariffs" />
        case 'MAX_DEALER_PERIODS':
            return <FormattedMessage id="serverMessage.MAX_DEALER_PERIODS" defaultMessage="Max dealer accounting periods" />
        case 'MAX_HOTSPOT_SESSIONS':
            return <FormattedMessage id="serverMessage.MAX_HOTSPOT_SESSIONS" defaultMessage="Max hotspot sessions" />
        case 'MAX_HOTSPOT_AUTH':
            return <FormattedMessage id="serverMessage.MAX_HOTSPOT_AUTH" defaultMessage="Max hotspot auth" />
        case 'MAX_HOTSPOT_USERS':
            return <FormattedMessage id="serverMessage.MAX_HOTSPOT_USERS" defaultMessage="Max hotspot users" />
        case 'EVENT_LIST':
            return <FormattedMessage id="serverMessage.EVENT_LIST" defaultMessage="Event list" />
        case 'MAX_CASH_MOD':
            return <FormattedMessage id="serverMessage.MAX_CASH_MOD" defaultMessage="Max cashiers" />
        case 'MAX_DHCP_LEASES':
            return <FormattedMessage id="serverMessage.MAX_DHCP_LEASES" defaultMessage="Max DHCP leases" />
        case 'MAX_TRAFFIC_COLLECTORS':
            return <FormattedMessage id="serverMessage.MAX_TRAFFIC_COLLECTORS" defaultMessage="Max traffic collectors" />
        default:
            return props.children;
    }
}

export default Localize;