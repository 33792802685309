import React from 'react';
import { getErrorMessage } from './get-error-message';
import { FormattedMessage } from 'react-intl';

function getErrorMessageWrapper(message) {
  if (typeof message === 'object') {
    // if FormattedMessage
    if (React.isValidElement(message)) {
      return message;
    }

    // Old format defined wrong. This is so we can read localStorage entries from before upgrade.
    return <FormattedMessage {...message.props} />;
    // return <FormattedMessage id="error.invalidMessageString" defaultMessage="Invalid message format" />;
  }

  return getErrorMessage(message);
}

export { getErrorMessageWrapper as getErrorMessage };
