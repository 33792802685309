import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

class ControlledTextField extends React.Component {
    static controlType = 'input';

    static cleanup = (formName, name) => {
        localStorage.removeItem(`${formName}.${name}`)
    }

    constructor(props) {
        super(props);
        const { name, formName, onChange } = props;
        const value = props.value !== undefined ? props.value : localStorage.getItem(`${formName}.${name}`);
        this.state = {
            value,
            error: this.getLengthError(value),
        };
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null);
        }
    };

    getLengthError(value) {
        const { maxLength } = this.props;
        if (value && maxLength && value.length > maxLength) {
            return <FormattedMessage id='controlledTextField.tooLongMessage' defaultMessage='Value is too long' />;
        } else {
            return null;
        }
    }

    changeHandler = ({ target: { value } }) => {
        const { formName, onChange, name } = this.props
        localStorage.setItem(`${formName}.${name}`, value);
        const error = this.getLengthError(value);
        this.setState({
            value,
            error,
        });
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null, !!error);
        }
    };

    render() {
        const {
            value,
            error,
        } = this.state;
        const {
            index,
            validator,
            formName,
            select,
            value: unused,
            ...restProps
        } = this.props;

        if (!formName) {
            return null;
        }

        return (
            <Box mt={index === 0 ? 0 : 1} key={index}>
                <TextField
                    {...restProps}
                    fullWidth
                    variant='outlined'
                    onChange={this.changeHandler}
                    defaultValue={select?undefined:value}
                    value={select?value:undefined}
                    select={select}
                    error={(typeof validator === 'function' && !!validator(value)) || !!error}
                    helperText={(typeof validator === 'function' ? validator(value) : undefined) || error}
                />
            </Box>
        );
    };
};

ControlledTextField.propTypes = {
    index: PropTypes.number,
    name: PropTypes.string.isRequired,
    formName: PropTypes.string,
    label: PropTypes.element.isRequired,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    validator: PropTypes.func,
    onChange: PropTypes.func,
};

export default ControlledTextField;