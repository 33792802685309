import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { ControlledButton, ControlledTextField, ControlledToolbar, ControllingForm } from '../../Form';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { withRouter } from 'react-router';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';

class CustomerId extends React.Component {
    state = {
        customerNotFound: false,
    };

    getHelperText() {
        if (this.state.customerNotFound) {
            return <FormattedMessage id='call.customerNotFound' defaultMessage='Customer not found' />;
        }
        return <FormattedMessage id='call.specifyClientId' defaultMessage='Please specify your client id' />;
    }

    setCustomerHandler = async (data) => {
        const { callId, onSubmit, showCancelButton } = this.props;
        try {
            const result = await fetcher.post(`staff/call/${callId}/customer`, data);
            if (result) {
                this.setState({ customerNotFound: false });
                if (typeof onSubmit === 'function') {
                    await onSubmit();
                }
                if (showCancelButton) {
                    this.props.history.push(`/call/${callId}`);
                }
                return true;
            } else {
                this.setState({ customerNotFound: true });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const { callId, showCancelButton } = this.props;
        if (!callId) {
            return null;
        }

        return (
            <ControllingForm name='callCustomer' padding={1} md={12} lg={8}>
                <Box m={1}>
                    <Typography variant='body1'>
                        {this.getHelperText()}
                    </Typography>
                </Box>
                <ControlledTextField
                    name='customer_id'
                    label={<FormattedMessage id='call.customerId' defaultMessage='Customer ID' />}
                    required
                    autoFocus />
                <ControlledToolbar>
                    <ControlledButton
                        isSubmitButton
                        primary
                        icon={<SearchIcon />}
                        onClick={this.setCustomerHandler}
                    >
                        <FormattedMessage id='call.customer.submit' defaultMessage='Find' />
                    </ControlledButton>
                    {showCancelButton ?
                        <ControlledButton
                            icon={<CancelIcon />}
                            onClick={() => {
                                this.props.history.push(`/call/${callId}`);
                                return true;
                            }}
                        >
                            <FormattedMessage id='call.cancel' defaultMessage='Cancel' />
                        </ControlledButton>
                        : null
                    }
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

CustomerId.propTypes = {
    callId: PropTypes.string,
    showCancelButton: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default withRouter(CustomerId);