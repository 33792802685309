import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { ControllingForm } from '../../Form';
import Localize from '../../common/Localize';
import { Duration } from '.';
import { withRouter } from 'react-router';

class CallInfo extends React.PureComponent {
    render = () => {
        const {
            id,
            start,
            end,
            customer,
            support,
        } = this.props;
        const {
            company,
            email,
            id: customerId,
        } = customer || {};
        const hasSupport = support.length !== 0;

        return (
                <ControllingForm name='callInfo' slim md={12} lg={8}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell padding='none'>
                                            <Duration
                                                start={new Date(start)}
                                                end={end ? new Date(end) : undefined}
                                            />
                                        </TableCell>
                                        {customer ?
                                            <React.Fragment>
                                                <TableCell padding='none'>
                                                    {company}
                                                </TableCell>
                                                {hasSupport ?
                                                    support.map(({ type }) => (
                                                        <TableCell key={type} padding='none'>
                                                            <Localize addProductType>
                                                                {type}
                                                            </Localize>
                                                        </TableCell>
                                                    ))
                                                    :
                                                    <TableCell padding='none'>
                                                        <Typography color='error' variant='body1'>
                                                            <FormattedMessage id='call.noSupport' defaultMessage='No' />
                                                        </Typography>
                                                    </TableCell>
                                                }
                                            </React.Fragment>
                                            : null}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Table>
                                <TableBody>
                                    <TableRow hover>
                                        <TableCell padding='none'>
                                            <FormattedMessage id='call.start' defaultMessage='Call start' />
                                        </TableCell>
                                        <TableCell padding='none'>
                                            <FormattedDate
                                                value={start}
                                                year='numeric'
                                                month='numeric'
                                                day='numeric'
                                                hour='numeric'
                                                minute='numeric'
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {end ?
                                        <TableRow hover>
                                            <TableCell padding='none'>
                                                <FormattedMessage id='call.end' defaultMessage='Call end' />
                                            </TableCell>
                                            <TableCell padding='none'>
                                                <FormattedDate
                                                    value={end}
                                                    year='numeric'
                                                    month='numeric'
                                                    day='numeric'
                                                    hour='numeric'
                                                    minute='numeric'
                                                />
                                            </TableCell>
                                        </TableRow>
                                        : null}
                                    {customer ?
                                        <React.Fragment>
                                            <TableRow hover>
                                                <TableCell padding='none'>
                                                    <FormattedMessage id='call.customer' defaultMessage='Customer' />
                                                </TableCell>
                                                <TableCell padding='none'>
                                                    {company}, {email}, {customerId}
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => {
                                                            this.props.history.push(`/call/${id}/set-customer`);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding='none'>
                                                    <FormattedMessage id='call.support' defaultMessage='Support' />
                                                </TableCell>
                                                <TableCell padding='none'>
                                                    {hasSupport ?
                                                        <Table>
                                                            <TableBody>
                                                                {support.map(({ type, end }) => (
                                                                    <TableRow key={type} hover>
                                                                        <TableCell padding='none'>
                                                                            <Localize addProductType>
                                                                                {type}
                                                                            </Localize>
                                                                        </TableCell>
                                                                        <TableCell padding='none'>
                                                                            <FormattedDate
                                                                                value={end}
                                                                                year='numeric'
                                                                                month='numeric'
                                                                                day='numeric'
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        :
                                                        <Typography color='error' variant='body1'>
                                                            <FormattedMessage id='call.noSupport' defaultMessage='No' />
                                                        </Typography>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                        : null}
                                </TableBody>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                </ControllingForm >
        );
    };
};

export default withRouter(CallInfo);