import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IptvLicenseSelector } from '.';
import { ControlledCountryList, ControlledTextField, ControllingForm } from '../Form';

class NewIptvLicenseForm extends React.Component {
    static controlType = 'form';
    static formName = 'newIptvLicenseForm';

    render() {
        const {
            licenses,
            onUpdate,
        } = this.props;

        if (!licenses || !Array.isArray(licenses) || licenses.length === 0) {
            return (
                <Box mt={1}>
                    <Typography color='error' >
                        <FormattedMessage id='newIptvLicenseForm.noIptvLicenseAvailable' defaultMessage='No IPTV license avaliable' />
                    </Typography>
                </Box>
            );
        }

        return (
            <ControllingForm
                fullWidth
                flat
                slim
                name={NewIptvLicenseForm.formName}
                onUpdate={onUpdate}
            >
                <IptvLicenseSelector
                    name='license'
                    label={<FormattedMessage id='newIptvLicenseForm.license' defaultMessage='IPTV license' />}
                    required
                    licenses={licenses}
                />
                <ControlledTextField
                    name='organization'
                    label={<FormattedMessage id='license.organization' defaultMessage='Organization' />}
                    required
                    autoComplete='organization'
                />
                <ControlledTextField
                    name='department'
                    label={<FormattedMessage id='license.department' defaultMessage='Department' />}
                    required
                />
                <ControlledCountryList
                    name='country'
                    label={<FormattedMessage id='license.country' defaultMessage='Country' />}
                    required
                />
                <ControlledTextField
                    name='region'
                    label={<FormattedMessage id='license.region' defaultMessage='Region' />}
                    required
                />
                <ControlledTextField
                    name='city'
                    label={<FormattedMessage id='license.city' defaultMessage='City' />}
                    required
                />
            </ControllingForm>
        );
    };
};

export default NewIptvLicenseForm;