import React from 'react';
import PageLoadingIndicator from '../PageLoadingIndicator';
import ErrorWrapper from './ErrorWrapper';

const LoadingWrapper = (props) => {
    const { children, wrap, error } = props;
    return (
        <ErrorWrapper wrap={error}>
            {wrap ?
                <PageLoadingIndicator open />
                :
                children ? children : null
            }
        </ErrorWrapper>
    );
}

export default LoadingWrapper;