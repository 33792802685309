import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import compose from 'recompose/compose';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuCard from '../components/MenuCard';
import { Box, Paper, Typography } from '@material-ui/core';
import store from '../util/store';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MenuCardContainer from '../components/MenuCardContainer';

const styles = theme => ({
  hotlineCard: {
    backgroundColor: theme.palette.cards.hotline,
  },
  licenseCard: {
    backgroundColor: theme.palette.cards.license,
  },
  oldCard: {
    backgroundColor: theme.palette.cards.oldLinks,
  },
});


class MainPage extends React.Component {
  render() {
    const { classes } = this.props;
    const { auth: { uid } } = store.getState();
    return (
      <Box m={3}>
        <Grid container justify='center'>
          <Grid item md={8}>
            <Box m={1}>
              <Paper>
                <Box p={2}>
                  <Typography variant='body1' paragraph>
                    <FormattedMessage id='mainPage.helloMessage' defaultMessage='Hello!' />
                  </Typography>
                  <Typography variant='body1' paragraph>
                    <FormattedMessage id='mainPage.welcometMessage' defaultMessage='This is your new customer area' />
                  </Typography>
                  <Typography variant='body1'>
                    <FormattedMessage id='mainPage.clientIdMessage' defaultMessage='Client ID: {uid}' values={{ uid }} />
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Grid>
          <MenuCardContainer>
            <MenuCard
              uri="/hotline"
              icon={<LiveHelpIcon />}
              name={<FormattedMessage id="mainPage.hotline" defaultMessage="Support (HotLine)" />}
              cardClassNames={classes['hotlineCard']}
            />
            <MenuCard
              uri="/license"
              icon={<VpnKeyIcon />}
              name={<FormattedMessage id="mainPage.license" defaultMessage="License keys" />}
              cardClassNames={classes['licenseCard']}
            />
            <MenuCard
              uri="/old"
              icon={<NavigateNextIcon />}
              name={<FormattedMessage id="mainPage.old" defaultMessage="Old private office" />}
              cardClassNames={classes['oldCard']}
            />
          </MenuCardContainer>
        </Grid>
      </Box>
    );
  }
}

MainPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(MainPage);
