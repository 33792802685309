import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl';
import { isMobile } from 'react-device-detect';

import CreateIcon from '@material-ui/icons/Create';

import TablePaginationActions from '../components/TablePaginationActions';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import { Box, Button, Select, FormControl, MenuItem, FormLabel } from '@material-ui/core';
import { fetcher } from '../util/deps';
import Localize from '../components/common/Localize';
import TooltipWrapper from '../components/TooltipWrapper';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      display: 'none',
    },
    '&:hover .hover-button': {
      display: 'inherit',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
});

class TicketLists extends React.Component {
  state = {
    page: 0,
    rowsPerPage: +localStorage.getItem('ticketsPerPage') || 10,
    tickets: [],
    archive: false,
    canCreateTickets: false,
    loading: true,
  };

  togglePageSelection = displayedTickets => () => {
    this.actions.togglePageSelection(displayedTickets);
  };

  async getTickets(archive) {
    try {
      return await fetcher.get('tickets/' + this.props.match.params.type.toLowerCase() + (archive ? '/archive' : ''));
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async componentDidMount() {
    const { type } = this.props.match.params;
    const archive = sessionStorage.getItem(`tickets.${type}.archive`) === 'true';
    try {
      this.setState({
        tickets: await this.getTickets(archive),
        canCreateTickets: await fetcher.get(`ticket/create/${type.toLowerCase()}`),
        page: +sessionStorage.getItem(`tickets.${type}.page`),
        archive,
        loading: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleOpenTicket(tn) {
    this.props.history.push(this.props.location.pathname + '/ticket/' + tn);
  };

  handleNewTicket = () => {
    const { url } = this.props.match;
    this.props.history.push(`${url}/create`);
  };

  handleArchiveChange = async event => {
    const newArchiveValue = event.target.value;
    const { type } = this.props.match.params;
    if (this.state.archive !== newArchiveValue) {
      this.setState({ loading: true });
      try {
        const tickets = await this.getTickets(newArchiveValue);
        sessionStorage.setItem(`tickets.${type}.archive`, newArchiveValue);
        this.setState({ tickets, archive: newArchiveValue, loading: false });
      } catch (e) {
        console.error(e);
      }
    }
  };

  handleChangeRowsPerPage = event => {
    localStorage.setItem('ticketsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => {
    const { type } = this.props.match.params
    sessionStorage.setItem(`tickets.${type}.page`, page);
    this.setState({ page });
  };

  labelDisplayedRows = ({ from, to, count }) => (
    <FormattedMessage
      id="table.paginationLabelDisplayedRows"
      defaultMessage="{from}-{to} of {count}"
      values={{ from, to, count }}
    />
  );

  render() {
    const {
      classes,
      width,
    } = this.props;

    const {
      page,
      rowsPerPage,
      tickets,
    } = this.state;

    const xs = width === 'xs';
    const mdUp = !xs && width !== 'sm';
    const mobile = !mdUp || isMobile;
    const displayPagination = width === 'lg' || width === 'xl';
    let ticketsToBeDisplayed = [];
    if (tickets) {
      ticketsToBeDisplayed = displayPagination
        ? tickets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : tickets;
    }

    return (
      <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
        {this.state.loading ?
          <PageLoadingIndicator open />
          :
          <Paper className={classes.paper}>
            <Toolbar>
              <TooltipWrapper
                title={<FormattedMessage id="ticketPage.cantCreateTicketTooltip" defaultMessage="You can't create new ticket" />}
                wrap={!this.state.canCreateTickets}
              >
                <span>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={xs ? null : <CreateIcon />}
                    disabled={!this.state.canCreateTickets}
                    onClick={this.handleNewTicket}
                  >
                    {xs ?
                      <FormattedMessage id="ticketPage.createTicket.short" defaultMessage="Create" /> :
                      <FormattedMessage id="ticketPage.createTicket" defaultMessage="Create ticket" />
                    }
                  </Button>
                </span>
              </TooltipWrapper>
              <Box padding={2}>
                <FormControl>
                  <FormLabel>
                    {xs ?
                      <FormattedMessage id='tickets.shouldShow.short' defaultMessage='Show:' />
                      : null
                    }
                    <Select
                      value={this.state.archive}
                      onChange={this.handleArchiveChange}
                    >
                      <MenuItem key="no" value={false}>
                        {xs ?
                          <FormattedMessage id="ticketPage.showActiveTickets.short" defaultMessage="active tickets" /> :
                          <FormattedMessage id="ticketPage.showActiveTickets" defaultMessage="Show active tickets" />
                        }
                      </MenuItem>
                      <MenuItem key="yes" value={true}>
                        {xs ?
                          <FormattedMessage id="ticketPage.showArchiveTickets.short" defaultMessage="archive tickets" /> :
                          <FormattedMessage id="ticketPage.showArchiveTickets" defaultMessage="Show archive tickets" />
                        }
                      </MenuItem>
                    </Select>
                  </FormLabel>

                </FormControl>
              </Box>
            </Toolbar>
            {tickets.length === 0 ?
              <Box padding={2}>
                <Typography color="textSecondary" variant="body1" align="center">
                  <FormattedMessage id="ticketsPage.noTickets" defaultMessage="No tickets" />
                </Typography>
              </Box>
              :
              <Fragment>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="ticketsPage.tableHeading.ticketNumber" defaultMessage="Ticket number" />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage id="ticketsPage.tableHeading.subject" defaultMessage="Subject" />
                      </TableCell>
                      {xs ? null :
                        <React.Fragment>
                          <TableCell>
                            <FormattedMessage id="ticketsPage.tableHeading.type" defaultMessage="Type" />
                          </TableCell>
                          <TableCell>
                            <FormattedMessage id="ticketsPage.tableHeading.created" defaultMessage="Created" />
                          </TableCell>
                        </React.Fragment>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketsToBeDisplayed.map(ticket => {
                      const { number, subject, type, created } = ticket
                      return (
                        <TableRow
                          key={number}
                          className={classes.tableRow}
                          onClick={this.handleOpenTicket.bind(this, number)}
                        >
                          <TableCell>
                            <Typography>{number}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{subject}</Typography>
                          </TableCell>
                          {xs ? null :
                            <React.Fragment>
                              <TableCell>
                                <Typography><Localize>{type}</Localize></Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  <FormattedDate
                                    value={created}
                                    year='numeric'
                                    month='numeric'
                                    day='numeric'
                                    hour='numeric'
                                    minute='numeric'
                                  />
                                </Typography>
                              </TableCell>
                            </React.Fragment>
                          }
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {displayPagination && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={tickets.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          labelDisplayedRows={this.labelDisplayedRows}
                          labelRowsPerPage={<FormattedMessage id="table.rowsPerPage" defaultMessage="Rows per page" />}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Fragment>
            }
          </Paper>
        }
      </div>
    );
  }
}

TicketLists.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired
};

export default connect(TicketLists.mapStateToProps)(
  compose(
    withStyles(styles),
    withWidth(),
    injectIntl,
  )(TicketLists),
);
