import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import { FormattedMessage } from 'react-intl';

class ControlledPassword extends React.Component {
    static controlType = 'input';

    state = {
        value: null,
        showPassword: false,
    };

    constructor(props) {
        super(props);
        const { onChange, value } = props;
        this.state = {
            value,
            error: this.getLengthError(value),
        };
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null);
        }
    };

    getLengthError(value) {
        const { maxLength } = this.props
        if (value && maxLength && value.length > maxLength) {
            return <FormattedMessage id='controlledPassword.tooLongMessage' defaultMessage='Password is too long' />;
        }
        return null;
    };

    changeHandler = ({ target: { value } }) => {
        const { onChange } = this.props
        const error = this.getLengthError(value);
        this.setState({
            value,
            error,
        });
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null, !!error);
        }
    };

    showPasswordHandler = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        });
    }

    render() {
        const {
            value,
            error,
            showPassword,
        } = this.state;
        const {
            index,
            showStrength,
            formName,
            value: unused,
            ...restProps
        } = this.props;

        return (
            <Box mt={index === 0 ? 0 : 1}>
                <TextField
                    {...restProps}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant='outlined'
                    onChange={this.changeHandler}
                    defaultValue={value}
                    error={!!error}
                    helperText={error}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="Toggle password visibility" onClick={this.showPasswordHandler}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {showStrength && !error && <PasswordStrengthMeter password={value} />}
            </Box>
        );
    };
};

ControlledPassword.propTypes = {
    index: PropTypes.number,
    label: PropTypes.element.isRequired,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    showStrength: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ControlledPassword;