import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const TooltipWrapper = ({ wrap, title, children, ...tooltipProps }) =>
  wrap ? (
    <Tooltip title={title} {...tooltipProps}>
      {children}
    </Tooltip>
  ) : (
    children
  );

export default TooltipWrapper;
