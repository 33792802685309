import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import CustomLink from '../components/CustomLink';
import ErrorIcon from '@material-ui/icons/Error';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { getUriMap } from '../util/uriMap';
import store from '../util/store';

const styles = theme => ({
  item: {
    transition: 'padding 0.3s',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  nested: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  selectedListItem: {
    '&, &:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },
  },
  icon: {
    minWidth: 36,
    marginLeft: 0,
  },
  primary: {},
  chevron: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  listItemText: {
    paddingRight: '0',
  },
  rightIcon: {
    marginRight: 0,
  },
  badge: {
    top: 1,
    right: -15,
    backgroundColor: 'inherit',
  },
});

class DrawerItems extends React.Component {
  render() {
    const {
      classes,
      currentPage,
      openNested,
      toggleExpandNested,
      handleDrawerToggle,
      drawerExpanded,
      location,
      lgUp,
    } = this.props;
    const { auth: { isStaff } } = store.getState();
    const uriMap = getUriMap(isStaff);
    const displayIssue = false, colorIssue = false
    const makeDrawerList = (items, nested = false) =>
      items.map(({ uri, icon, children }) => {
        const displayIssueBadge = displayIssue && !drawerExpanded;
        const displayListItemTooltip = !drawerExpanded;

        const { drawerLink, pageName, drawerName } = uriMap[uri];
        const linkTo = drawerLink ? drawerLink : uri;
        const name = typeof drawerName === 'function' ? drawerName() : (typeof pageName === 'function'? pageName() : undefined);
        return !children ? (
          <CustomLink replace={uri === location.pathname} to={linkTo} key={uri}>
            <Tooltip title={(displayListItemTooltip && name) || ''} placement="right">
              <ListItem
                button
                className={classnames(
                  classes.item,
                  nested && classes.nested,
                  currentPage === uri && classes.selectedListItem,
                )}
                onClick={handleDrawerToggle}
              >
                <ListItemIcon className={classnames(lgUp ? classes.listItemIcon : '', classes.icon)}>
                  {displayIssueBadge ? (
                    <Badge
                      badgeContent={<ErrorIcon color={colorIssue ? 'error' : 'inherit'} />}
                      color="primary"
                      classes={{ badge: classes.badge }}
                    >
                      {icon}
                    </Badge>
                  ) : (
                      icon
                    )}
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.primary }}
                  primaryTypographyProps={{ noWrap: true }}
                >
                  {name}
                </ListItemText>
                {displayIssue && (
                  <ListItemIcon classes={{ root: classes.rightIcon }} className={classes.icon}>
                    <ErrorIcon color={colorIssue ? 'error' : 'inherit'} />
                  </ListItemIcon>
                )}
              </ListItem>
            </Tooltip>
          </CustomLink>
        ) : (
            <Fragment key={uri}>
              <ListItem
                button
                onClick={toggleExpandNested(uri)}
                className={classnames(classes.item, currentPage === uri && classes.selectedListItem)}
              >
                <ListItemIcon className={classnames(lgUp ? classes.listItemIcon : '', classes.icon)}>{icon}</ListItemIcon>
                <ListItemText primary={name} />
                {openNested.includes(uri) ? (
                  <ExpandLess className={classes.chevron} />
                ) : (
                    <ExpandMore className={classes.chevron} />
                  )}
              </ListItem>
              <Collapse in={openNested.includes(uri)}>{makeDrawerList(children, true)}</Collapse>
            </Fragment>
          );
      });
    return makeDrawerList(
      Object.entries(uriMap)
        .filter(([uri, { addToDrawer }]) => addToDrawer)
        .map(([uri, { drawerIcon: icon }]) => ({ uri, icon }))
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(DrawerItems);
