import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Hidden, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, withWidth } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { countries } from 'countries-list';
import Localize from '../common/Localize';
import { DownloadLicenseButton, RegenerateUtm5LicenseButton } from '.';
class Utm5License extends React.Component {
    render() {
        const {
            limitations,
            full_name,
            max_release_date,
            id,
            u_name,
            email,
            country,
            state,
            city,
            inn,
            kpp,
            person,
            tel,
            fax,
            web_page,
            jur_addr,
            jur_index,
            act_index,
            act_addr,
            can_regenerate,
            reload,
            width,
        } = this.props;
        const { native, emoji } = countries[country] || { native: country, emoji: '' };

        let type = width === 'xs' ?
            <FormattedMessage id='license.5.0' defaultMessage='5.0' />
            :
            <FormattedMessage id='license.utm5.0' defaultMessage='UTM 5.0' />;
        let expired = limitations.CORE && (new Date(limitations.CORE.expiration) < new Date());
        let modulesExpired = Object.values(limitations).reduce((result, { expiration }) => result || (new Date(expiration) < new Date()), false);
        if ('ENTERPRISE' in limitations) {
            type = width === 'xs' ?
                <FormattedMessage id='license.enterprise' defaultMessage='Enterprise' />
                :
                <FormattedMessage id='license.utm5+Enterprise' defaultMessage='UTM5+ Enterprise' />;
            expired = expired || (limitations.ENTERPRISE && (new Date(limitations.ENTERPRISE.expiration) < new Date()));
            modulesExpired = false;
        } else if ('TELECOM' in limitations) {
            type = width === 'xs' ?
                <FormattedMessage id='license.Telecom' defaultMessage='Telecom' />
                :
                <FormattedMessage id='license.utm5+Telecom' defaultMessage='UTM5+ Telecom' />;
            expired = expired || (limitations.TELECOM && (new Date(limitations.TELECOM.expiration) < new Date()));
            modulesExpired = false;
        }
        return (
            <Paper>
                <Box mb={1} p={1} display='flex' width='100%'>
                    <Box width='100%'>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Hidden mdDown>
                                    <Grid item lg={1}>
                                        {id}
                                    </Grid>
                                </Hidden>
                                <Grid item xs={4} sm={2}>
                                    {type}
                                </Grid>
                                <Grid item xs={8} sm={3}>
                                    {full_name}
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item sm={3} lg={2}>
                                        {max_release_date ?
                                            <Tooltip
                                                title={
                                                    <FormattedMessage id='license.maxRleaseDate' defaultMessage='Supports upgrading till' />
                                                }
                                            >
                                                <Typography color={new Date(max_release_date) > new Date() ? 'initial' : 'error'}>
                                                    <FormattedDate
                                                        value={max_release_date}
                                                        year='numeric'
                                                        month='numeric'
                                                        day='numeric'
                                                    />
                                                </Typography>
                                            </Tooltip>
                                            :
                                            <Typography color='error'>
                                                <FormattedMessage id='license.updatesNotAvailable' defaultMessage='Updates not available' />
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item sm={4}>
                                        {expired ?
                                            <Typography color='error'>
                                                <FormattedMessage id='license.expired' defaultMessage='Expired' />
                                            </Typography>
                                            : (modulesExpired ?
                                                <Typography color='error'>
                                                    <FormattedMessage id='license.someModulesExpired' defaultMessage='Some modules expired' />
                                                </Typography>
                                                : null
                                            )
                                        }
                                    </Grid>
                                </Hidden>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box width='100%'>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding='none'>
                                                    <FormattedMessage id='license.module' defaultMessage='Module' />
                                                </TableCell>
                                                {width !== 'xs' &&
                                                    <TableCell padding='none'>
                                                        <FormattedMessage id='license.created' defaultMessage='Created' />
                                                    </TableCell>
                                                }
                                                <TableCell padding='none'>
                                                    <FormattedMessage id='license.expirationDate' defaultMessage='Expiration date' />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(limitations).map(([name, { created, expiration, limitations }]) => (
                                                <React.Fragment key={name}>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <Localize>
                                                                {name}
                                                            </Localize>
                                                        </TableCell>
                                                        {width !== 'xs' &&
                                                            <TableCell padding='none'>
                                                                <FormattedDate
                                                                    value={created}
                                                                    year='numeric'
                                                                    month='numeric'
                                                                    day='numeric'
                                                                    minute='numeric'
                                                                    hour='numeric'
                                                                />
                                                            </TableCell>
                                                        }
                                                        <TableCell padding='none'>
                                                            <Typography color={new Date(expiration) > new Date() ? 'initial' : 'error'} variant='body2'>
                                                                <FormattedDate
                                                                    value={expiration}
                                                                    year='numeric'
                                                                    month='numeric'
                                                                    day='numeric'
                                                                    minute={width === 'xs' ? undefined : 'numeric'}
                                                                    hour={width === 'xs' ? undefined : 'numeric'}
                                                                />
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {Array.isArray(limitations) && limitations.length !== 0 ?
                                                        <TableRow>
                                                            <TableCell colSpan={2} padding='none'>
                                                                <Box ml={1} mb={1}>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell padding='none'>
                                                                                    <FormattedMessage id='license.options' defaultMessage='Options' />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {limitations.map((limit) => Object.entries(limit).map(([name, value]) => (
                                                                                <TableRow key={name} hover>
                                                                                    <TableCell padding='none'>
                                                                                        <Localize>
                                                                                            {name}
                                                                                        </Localize>
                                                                                    </TableCell>
                                                                                    <TableCell padding='none'>
                                                                                        {value}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )))
                                                                            }
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                </React.Fragment>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <Accordion>
                                        <AccordionSummary>
                                            <FormattedMessage id='license.details' defaultMessage='Details' />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Table>
                                                <TableBody>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.id' defaultMessage='License number' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {id}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <Typography color={new Date(max_release_date) > new Date() ? 'initial' : 'error'} variant='body2'>
                                                                <FormattedMessage id='license.maxRleaseDate' defaultMessage='Supports upgrading till' />
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {max_release_date ?
                                                                <Typography color={new Date(max_release_date) > new Date() ? 'initial' : 'error'} variant='body2'>
                                                                    <FormattedDate
                                                                        value={max_release_date}
                                                                        year='numeric'
                                                                        month='numeric'
                                                                        day='numeric'
                                                                    />
                                                                </Typography>
                                                                :
                                                                <Typography color='error' variant='body2'>
                                                                    <FormattedMessage id='license.updatesNotAvailable' defaultMessage='Updates not available' />
                                                                </Typography>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.organization' defaultMessage='Organization' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {full_name}
                                                        </TableCell>
                                                    </TableRow>
                                                    {u_name ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.department' defaultMessage='Department' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {u_name}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.email' defaultMessage='Email' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {email}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.country' defaultMessage='Country' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {emoji} {native}
                                                        </TableCell>
                                                    </TableRow>
                                                    {state ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.region' defaultMessage='Region' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {state}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    {city ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.city' defaultMessage='City' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {city}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.inn' defaultMessage='Tax ID' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {inn}
                                                        </TableCell>
                                                    </TableRow>
                                                    {kpp ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.kpp' defaultMessage='KPP' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {kpp}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    {person ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.person' defaultMessage='Person' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {person}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.tel' defaultMessage='Phone' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {tel}
                                                        </TableCell>
                                                    </TableRow>
                                                    {fax ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.fax' defaultMessage='Fax' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {fax}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    {web_page ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.webSite' defaultMessage='Web site' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {web_page}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                    <TableRow hover>
                                                        <TableCell padding='none'>
                                                            <FormattedMessage id='license.legalAddress' defaultMessage='Legal address' />
                                                        </TableCell>
                                                        <TableCell padding='none'>
                                                            {jur_index ? `${jur_index},` : null} {jur_addr}
                                                        </TableCell>
                                                    </TableRow>
                                                    {act_addr ?
                                                        <TableRow hover>
                                                            <TableCell padding='none'>
                                                                <FormattedMessage id='license.officeAddress' defaultMessage='Office address' />
                                                            </TableCell>
                                                            <TableCell padding='none'>
                                                                {act_index ? `${act_index},` : null} {act_addr}
                                                            </TableCell>
                                                        </TableRow>
                                                        : null
                                                    }
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box display='flex' flexWrap='nowrap' alignItems='center' mr={1}>
                        <RegenerateUtm5LicenseButton id={id} canRegenerate={can_regenerate} reload={reload} />
                        <DownloadLicenseButton type='utm5' id={id} />
                    </Box>
                </Box>
            </Paper>
        );
    }
}

export default withWidth()(Utm5License);
