import React from 'react';

export default class ErrorBoundaryWrapper extends React.Component {
  state = { localError: false };

  static getDerivedStateFromError() {
    return { localError: true };
  }

  render() {
    return <this.props.children {...this.props} localError={!!this.state.localError} />;
  }
}
