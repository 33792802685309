import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import root from '../redux-stuff/reducers';
import StoreDecorator from './store-decorator';

const getInitialErrors = () => {
  const errors = localStorage.getItem('systemInterfaceErrors');
  return errors ? JSON.parse(errors) : [];
};

const initial = {
  drawer: { expanded: localStorage.getItem('drawerOpen') !== 'false', openNested: [] },
  appBar: {
    leftButton: null,
    rightButtons: null,
    text: null,
  },
  errors: getInitialErrors(),
  isAuth: null,
  auth: {
    uid: null,
    username: null,
    sessionId: null,
    authorized: false,
  },

};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(root, initial, composeEnhancers(applyMiddleware(thunk)));

export default new StoreDecorator(store);
