import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, withStyles, Typography } from '@material-ui/core';
import zxcvbn from 'zxcvbn';
import { FormattedMessage } from 'react-intl';
import { green, lightGreen, amber, orange, deepOrange } from '@material-ui/core/colors';

const styles = () => ({
    veryWeakProgressBar: {
        backgroundColor: deepOrange[700],
    },
    veryWeakText: {
        color: deepOrange[700],
    },
    weakProgressBar: {
        backgroundColor: orange[700],
    },
    weakText: {
        color: orange[700],
    },
    mediumProgressBar: {
        backgroundColor: amber[700],
    },
    mediumText: {
        color: amber[700],
    },
    strongProgressBar: {
        backgroundColor: lightGreen[700],
    },
    strongText: {
        color: lightGreen[700],
    },
    veryStrongProgressBar: {
        backgroundColor: green[700],
    },
    veryStrongText: {
        color: green[700],
    },
    invisible: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    }
});

const PasswordStrengthMeter = ({ password, classes }) => {
    if (!password) {
        return null;
    }
    const options = [
        {
            progressValue: 20,
            progressStyle: classes.veryWeakProgressBar,
            textStyle: classes.veryWeakText,
            message: <FormattedMessage id='passwordStrengthMeter.veryWeak' defaultMessage='Very weak'/>
        },
        {
            progressValue: 40,
            progressStyle: classes.weakProgressBar,
            textStyle: classes.weakText,
            message: <FormattedMessage id='passwordStrengthMeter.weak' defaultMessage='Weak' />
        },
        {
            progressValue: 60,
            progressStyle: classes.mediumProgressBar,
            textStyle: classes.mediumText,
            message: <FormattedMessage id='passwordStrengthMeter.medium' defaultMessage='Medium' />
        },
        {
            progressValue: 80,
            progressStyle: classes.strongProgressBar,
            textStyle: classes.strongText,
            message: <FormattedMessage id='passwordStrengthMeter.strong' defaultMessage='Strong' />
        },
        {
            progressValue: 100,
            progressStyle: classes.veryStrongProgressBar,
            textStyle: classes.veryStrongText,
            message: <FormattedMessage id='passwordStrengthMeter.veryStrong' defaultMessage='Very strong' />
        }
    ];
    const { score } = zxcvbn(password);
    return (
        <Box mt={1}>
            <LinearProgress
                value={options[score].progressValue}
                variant='determinate'
                classes={{ barColorPrimary: options[score].progressStyle, colorPrimary: classes.invisible}}
            />
            <Box ml={2} mb={2}>
                <Typography className={options[score].textStyle} variant='caption'>
                    {options[score].message}
                </Typography>
            </Box>
        </Box>
    );
};

PasswordStrengthMeter.propTypes = {
    password: PropTypes.string,
};

export default withStyles(styles)(PasswordStrengthMeter);