import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import LoadingWrapper from './common/LoadingWrapper';
import { Box, CircularProgress, FormControlLabel, Switch, Typography } from '@material-ui/core';
import ResponsiveDialog from './ResponsiveDialog';
import { LocaleContext } from '../context';

class SubscriptionsDialog extends React.Component {
    state = {
        loading: true,
        error: false,
        userLang: undefined,
        subscriptions: {},
        updatingList: undefined,
    };

    static contextType = LocaleContext;

    async componentDidMount() {
        const {
            locale
        } = this.context;
        this.setState({ loading: true });
        try {
            const lang = await fetcher.get('lang');
            const userLang = lang ? (lang === 'ru-RU' ? 'ru' : 'en') : locale;
            const subscriptions = await fetcher.get('subscriptions');
            this.setState({ loading: false, subscriptions, userLang });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ loading: false, error: true });
        }
    }

    updateSubscription = async (list, subscribed) => {
        this.setState({ updatingList: list });
        try {
            await (subscribed ? fetcher.put(`subscriptions/${list}`) : fetcher.delete(`subscriptions/${list}`));
            const subscriptions = await fetcher.get('subscriptions');
            this.setState({ updatingList: undefined, subscriptions });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ error: true });
        }
    }

    render() {
        const {
            loading,
            error,
            subscriptions,
            updatingList,
            userLang,
        } = this.state;
        const {
            open,
            onClose,
        } = this.props;
        return (
            <ResponsiveDialog
                open={open}
                title={<FormattedMessage id='subscriptions.DialogTitle' defaultMessage='Subscriptions' />}
                message={
                    <LoadingWrapper wrap={loading} error={error}>
                        {Object.entries(subscriptions).filter(([, { lang }]) => userLang === lang).map(([id, { name, description, subscribed }]) => (
                            <Box key={id}>
                                <FormControlLabel
                                    control={
                                        updatingList === id ?
                                            <Box ml={1} mr={2}>
                                                <CircularProgress size={34} />
                                            </Box>
                                            :
                                            <Switch
                                                checked={subscribed}
                                                color='primary'
                                                onChange={({ target: { checked } }) => this.updateSubscription(id, checked)}
                                            />}
                                    label={
                                        <React.Fragment>
                                            {name}
                                            {description && description.split("\n").map((line, i) => (
                                                <Typography variant='caption' component='p' key={i}>
                                                    {line || <br />}
                                                </Typography>
                                            ))}
                                        </React.Fragment>
                                    }
                                />
                            </Box>
                        ))}
                    </LoadingWrapper>
                }
                fullWidth
                withoutConfirm
                closeMessage={<FormattedMessage id='subscriptions.close' defaultMessage='Close' />}
                onClose={onClose}
            />

        );
    };
};

export default SubscriptionsDialog;