import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar, withWidth } from '@material-ui/core';

class ControlledToolbar extends React.Component {
    static controlType = 'toolbar';
    render() {
        const {
            children,
            canSubmit,
            clickHandler,
            width,
        } = this.props;
        const Wrapper = width === 'xs' ? React.Fragment : Toolbar;

        return (
            <Wrapper variant='dense'>
                {React.Children.map(children, (child, index) => {
                    if (!child) {
                        return null;
                    }
                    const { isSubmitButton, disabled, onClick, ...otherProps } = child.props;
                    const handler = typeof clickHandler === 'function' ? clickHandler(onClick) : onClick;
                    return (
                        <Box
                            mt={width === 'xs' ? 1 : 0}
                            ml={width === 'xs' ? 0 : (index === 0 ? 0 : 1)}
                            key={index}
                        >
                            {React.createElement(child.type, {
                                disabled: isSubmitButton ? !canSubmit : disabled,
                                onClick: handler,
                                fullWidth: width === 'xs',
                                ...otherProps
                            })}
                        </Box>
                    );
                })}
            </Wrapper>
        );
    };
};

ControlledToolbar.propTypes = {
    canSubmit: PropTypes.bool,
    clickHandler: PropTypes.func,
};

export default withWidth()(ControlledToolbar);