import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'recompose/compose';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import MenuCard from '../components/MenuCard';
import { Box } from '@material-ui/core';
import LoadingWrapper from '../components/common/LoadingWrapper';
import { fetcher } from '../util/deps';
import emitter from '../emitter';

const styles = theme => ({
  utm5Card: {
    backgroundColor: theme.palette.cards.utm5,
  },
  iptvCard: {
    backgroundColor: theme.palette.cards.iptv,
  }
});


class Hotline extends React.Component {
  state = {
    loading: true,
    error: false,
    support: {}
  };

  async componentDidMount() {
    try {
      const support = await fetcher.get('support');
      this.setState({ loading: false, support });
    } catch (e) {
      emitter.emit('addMessage', { type: 'failure', data: e });
      this.setState({ loading: false, error: true });
    }
  }

  render() {
    const { classes, intl } = this.props;
    const { loading, error, support } = this.state;
    const { utm5_support, iptv_support } = support;
    return (
      <LoadingWrapper wrap={loading} error={error}>
        <Box m={3}>
          <Grid container justify='center'>
            <Grid container spacing={2} item md={8}>
              <MenuCard
                uri="/hotline/UTM5"
                icon={<LiveHelpIcon />}
                name={utm5_support && new Date(utm5_support.end) > new Date() ?
                  <FormattedMessage
                    id="hotline.netup5+support till"
                    defaultMessage="NetUP 5+ (support till {date})"
                    values={{
                      date: intl.formatDate(utm5_support && utm5_support.end, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      })
                    }}
                  />
                  :
                  <FormattedMessage id="hotline.netup5+expired" defaultMessage="NetUP 5+ (support expired)" />
                }
                cardClassNames={classes['utm5Card']}
              />
              <MenuCard
                uri="/hotline/IPTV"
                icon={<LiveHelpIcon />}
                name={iptv_support && new Date(iptv_support.end) > new Date() ?
                  <FormattedMessage
                    id="hotline.iptv+support till"
                    defaultMessage="IPTV (support till {date})"
                    values={{
                      date: intl.formatDate(iptv_support && iptv_support.end, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      })
                    }}
                  />
                  :
                  <FormattedMessage id="hotline.IPTVexpired" defaultMessage="IPTV (support expired)" />
                }
                cardClassNames={classes['iptvCard']}
              />
            </Grid>
          </Grid>
        </Box>
      </LoadingWrapper>
    );
  }
}

Hotline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  injectIntl,
)(Hotline);
