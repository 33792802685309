import React from 'react';
import { RadioGroup } from '@material-ui/core';

class ControlledRadioGroup extends React.Component {
    static controlType = 'input';

    state = {
        value: undefined,
    };

    changeHandler = ({ target: { value } }) => {
        const { onChange } = this.props
        this.setState({ value });
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    componentDidMount() {
        const { onChange } = this.props
        if (typeof onChange === 'function') {
            onChange(undefined);
        }
    }

    render() {
        const {
            children,
        } = this.props;

        return (
            <RadioGroup
                onChange={this.changeHandler}
            >
                {children}
            </RadioGroup>
        );
    };
};

export default ControlledRadioGroup;