import React from 'react';
import { injectIntl } from 'react-intl';
import { countries } from 'countries-list';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField } from '@material-ui/core';

const countriesList = Object.entries(countries).map(([code, { emoji, native }]) => ({
    code,
    emoji,
    native,
}));

class ControlledCountryList extends React.Component {
    static controlType = 'input';

    state = {
        code: 'US',
    };

    componentDidMount() {
        const {
            intl: {
                locale,
            },
            onChange,
        } = this.props;
        const code = locale === 'ru' ? 'RU' : 'US';
        this.setState({ code });
        if (typeof onChange === 'function') {
            onChange(code);
        }
    }

    changeHandler = (e, value) => {
        const { onChange } = this.props
        const code = value && value.code;
        this.setState({ code });
        if (typeof onChange === 'function') {
            onChange(code);
        }
    };

    render() {
        const {
            formName,
            name,
            ...restProps
        } = this.props;
        const {
            code,
        } = this.state;

        return (
            <Box mt={1}>
                <Autocomplete
                    options={countriesList}
                    value={code}
                    autoHighlight
                    getOptionLabel={(option) => {
                        if (!option) {
                            return '';
                        }
                        if (typeof option === 'object') {
                            return `${option.emoji} ${option.native} (${option.code})`;
                        }
                        return `${countries[option].emoji} ${countries[option].native} (${option})`;
                    }}
                    getOptionSelected={(option, value) => option.code === value}
                    onChange={this.changeHandler}
                    renderInput={(params) => (
                        <TextField
                            {...restProps}
                            {...params}
                            autoComplete='off'
                            fullWidth
                            variant='outlined'
                            inputProps={{
                                ...params.inputProps,
                            }}

                        />
                    )}
                />
            </Box>
        );
    };
};

export default injectIntl(ControlledCountryList);