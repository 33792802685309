import { SET_DRAWER_STATE, TOGGLE_DRAWER_STATE } from '../constants';

const drawer = (drawerState = { expanded: false, openNested: [] }, action) => {
  switch (action.type) {
    case SET_DRAWER_STATE:
      return { ...drawerState, ...action.payload };
    case TOGGLE_DRAWER_STATE:
      localStorage.setItem('drawerOpen', !drawerState.expanded);
      return { ...drawerState, expanded: !drawerState.expanded };
    default:
      return drawerState;
  }
};

export default drawer;