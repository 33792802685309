import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Dropzone from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import { Paper, Box, Toolbar, Button, Typography, CircularProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Uploader, fetcher } from '../util/deps';
import emitter from '../emitter';
import FileChip from './FileChip';
import LoadingWrapper from './common/LoadingWrapper';
import FileSizeLabel from './FileSizeLabel';

class FileUploader extends React.Component {
    state = {
        uploading: false,
        files: [],
        progress: 0,
        restrictions: {},
        loading: true,
        error: false,
    };

    progressEventHandler = ({ percentage: progress }) => {
        this.setState({ progress });
    }

    fileDropHandler = async (files) => {
        const { restrictions } = this.state;
        if (!this.state.uploading) {
            this.setState({
                uploading: true,
                files: this.state.files.concat(files),
            });
            try {
                const uploader = new Uploader(files.filter((file) => {
                    if ('max_filesize' in restrictions && file.size <= restrictions.max_filesize) {
                        return true;
                    } else {
                        const errorMessage = (
                            <FormattedMessage
                                id='upload.fileIsTooLarge'
                                defaultMessage='File {filename} is too large: file size {filesize}, maximum file size {maxsize}'
                                values={{
                                    filename: file.name,
                                    filesize: <FileSizeLabel size={file.size}/>,
                                    maxsize: <FileSizeLabel size={restrictions.max_filesize}/>,
                                }}
                            />
                        );
                        const error = new Error(errorMessage);
                        error.errorMessage = errorMessage;
                        error.time = new Date();

                        emitter.emit('addMessage', { type: 'failure', data: error });
                        return false;
                    }
                }), this.props.type);
                uploader.on('progress', this.progressEventHandler);
                const result = await uploader.upload();
                if (result && 'attachments' in result) {
                    if (this.props.onUpload(result.attachments)) {
                        // component will umount by handler
                        return;
                    }
                    
                }
            } catch (e) {
                emitter.emit('addMessage', { type: 'failure', data: e });
            }
            this.setState({
                uploading: false,
                files: [],
                progress: 0,
            });
        } else {
            console.error('uploading in progress');
        }
    };

    async componentDidMount() {
        try {
            const restrictions = await fetcher.get('upload/restrictions');
            this.setState({
                restrictions,
                loading: false,
            });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ error: true });
        }
    };

    render() {
        const {
            children,
            theme,
        } = this.props;
        const {
            uploading,
            progress,
            files,
            loading,
            error,
        } = this.state;

        return (
            <LoadingWrapper wrap={loading} error={error} >
                <Dropzone onDrop={this.fileDropHandler}>
                    {({ getRootProps, getInputProps }) => (
                        <Paper {...{ ...getRootProps(), onClick: undefined }}>
                            <Box p={2}>
                                {children}
                                <Toolbar>
                                    <Button
                                        color='secondary'
                                        variant='contained'
                                        startIcon={<PublishIcon />}
                                        onClick={getRootProps().onClick}
                                        disabled={uploading}
                                    >
                                        <input {...getInputProps()} />
                                        <FormattedMessage id='uploadButtonText' defaultMessage='Upload file' />
                                    </Button>
                                    {uploading ?
                                        <React.Fragment>
                                            <Box m={1}>
                                                <Typography variant='body1' color='textSecondary'>
                                                    {progress === 100 ?
                                                        <FormattedMessage id='upload.preparing' defaultMessage='Preparing:' />
                                                        :
                                                        <FormattedMessage id='upload.uploading' defaultMessage='Uploading:' />
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box m={1}>
                                                <CircularProgress
                                                    value={progress}
                                                    variant={progress === 100 ? 'indeterminate' : 'determinate'}
                                                    size={theme.typography.h5.fontSize}
                                                    color='secondary'
                                                />
                                            </Box>
                                            <Box>
                                                {files.map((file, index) => (
                                                    <FileChip
                                                        key={index}
                                                        icon={<AttachFileIcon />}
                                                        filename={file.name}
                                                        filesize={file.size} />
                                                ))}
                                            </Box>
                                        </React.Fragment>
                                        : null}
                                </Toolbar>
                            </Box>
                        </Paper>
                    )}
                </Dropzone>
            </LoadingWrapper>
        );
    };
};

FileUploader.propTypes = {
    type: PropTypes.string.isRequired,
    onUpload: PropTypes.func.isRequired,
}

export default compose(
    withTheme,
)(FileUploader)