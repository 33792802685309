import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Breadcrumbs } from '@material-ui/core';
import { Link } from 'react-router-dom';

const makeTitle = ({ pageName: PageNameComponent, classes, intl, hideFromBreadcrumbs, location, path }) => (props) => {
  if (props.match.isExact) {
    if (location !== props.match.path) {
      return null;
    }
    document.title = intl.formatMessage(PageNameComponent({}).props, { ...props.match.params })
    return (
      <Breadcrumbs color="inherit" classes={{ root: classes.leftMarginText }}>
        {path.map(({ PageNameComponent, location, params }) => (
          <Link to={location} className={classes.link} color="inherit" key={location}>
            <Typography variant="h6" noWrap>
              <PageNameComponent {...params} />
            </Typography>
          </Link>
        ))}
        <Typography variant="h6" noWrap>
          <PageNameComponent {...props.match.params} />
        </Typography>
      </Breadcrumbs>
    )
  } else {
    if (!hideFromBreadcrumbs) {
      path.push({ PageNameComponent, location: props.match.url, params: { ...props.match.params } })
    }
    return null;
  }
};

export default makeTitle;
