import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { grey } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { prepareContent } from './ArticleBody';

const styles = theme => ({
    quote: {
        paddingLeft: theme.spacing(1),
        borderLeftStyle: 'solid',
        borderLeftColor: grey[400],
        backgroundColor: grey[100],
    },
});

class ArticleBodyQuote extends React.PureComponent {
    state = {
        showQuote: false,
    };

    render() {
        const { lines, classes } = this.props;
        const { showQuote } = this.state;
        const content = prepareContent(lines, 'body2');
        return (
            <Accordion
                className={classes.quote}
                onChange={(e, showQuote) => this.setState({ showQuote })}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FormattedMessage id='article.quote' defaultMessage='Quote' />
                </AccordionSummary>
                <AccordionDetails>
                    {showQuote &&
                        <Box>
                            {content.map((Component, index) => (
                                <Component key={index} />
                            ))}
                        </Box>
                    }
                </AccordionDetails>
            </Accordion>
        );
    };
};

ArticleBodyQuote.propTypes = {
    lines: PropTypes.array.isRequired,
}

export default compose(
    withStyles(styles),
)(ArticleBodyQuote)