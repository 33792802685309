import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Breadcrumbs, Tooltip, Box } from '@material-ui/core';
import { FormattedDate } from 'react-intl';
import { green, grey, orange } from '@material-ui/core/colors';
import MessageIcon from '@material-ui/icons/Message';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import NoteIcon from '@material-ui/icons/Note';
import CallIcon from '@material-ui/icons/Call';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArticleBody from './ArticleBody';
import Localize from '../common/Localize';

const styles = () => ({
    reply: {
        backgroundColor: green[50],
    },
    note: {
        backgroundColor: orange[50],
    },
    requestHeader: {
        backgroundColor: grey[200],
    },
    phone_requestHeader: {
        backgroundColor: green[50],
    },
    replyHeader: {
        backgroundColor: green[200],
    },
    noteHeader: {
        backgroundColor: orange[200],
    },
});

class Article extends React.Component {
    render() {
        const { subject, body, create_time, create_by, type, classes, attachments } = this.props;
        let Icon;
        switch (type) {
            case 'request':
                Icon = HelpOutlineIcon;
                break;
            case 'phone_request':
                Icon = CallIcon;
                break;
            case 'reply':
                Icon = MessageIcon;
                break;
            case 'note':
                Icon = NoteIcon;
                break;
            default:
                Icon = LiveHelpIcon;
                break;
        }
        return (
            <Paper className={classes[type]}>
                <Paper className={classes[type + 'Header']}>
                    <Box textAlign='center' display='flex' p={2}>
                        <Breadcrumbs separator='·'>
                            <Box textAlign='center' display='flex'>
                                <Tooltip title={<Localize>{type}</Localize>}>
                                    <Icon />
                                </Tooltip>
                                <FormattedDate
                                    value={create_time}
                                    year='numeric'
                                    month='numeric'
                                    day='numeric'
                                    hour='numeric'
                                    minute='numeric'
                                />
                            </Box>
                            {create_by ?
                                <Box>
                                    {create_by.first_name} {create_by.last_name}
                                </Box>
                                : null}
                            <Box>
                                {subject}
                            </Box>
                        </Breadcrumbs>
                    </Box>
                </Paper>
                <ArticleBody {...{ body, attachments }} />
            </Paper>
        );
    };
};

Article.propTypes = {
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    create_time: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
}

export default compose(
    withStyles(styles),
)(Article)