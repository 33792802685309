import React from 'react';
// import { FormattedMessage } from 'react-intl';
import lightBlue from '@material-ui/core/colors/lightBlue';

function factory(name, extraData) {
  let messages = {
    success: {
      icon: 'check',
      // colored: <FormattedMessage id="snackbar.success" defaultMessage="snackbar.Success" />,
      color: '#64DD17',
      text: (
        <span>
          {/* {': '} */}
          {extraData}
        </span>
      ),
    },
    failure: {
      icon: 'clear',
      // colored: <FormattedMessage id="snackbar.failedSign" defaultMessage="snackbar.Failure" />,
      color: '#DD6417',
      text: (
        <span>
          {/* {': '} */}
          {extraData}
        </span>
      ),
    },
    info: {
      icon: 'info',
      // colored: <FormattedMessage id="snackbar.infoTitle" defaultMessage="snackbar.Info" />,
      color: lightBlue[400],
      text: (
        <span>
          {/* {': '} */}
          {extraData}
        </span>
      ),
    },
  };

  return messages[name];
}

export default factory;
