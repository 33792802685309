import React from 'react';
import { FormattedMessage } from 'react-intl';
import MainPage from '../containers/MainPage';
import StaffMainPage from '../containers/staff/StaffMainPage';
import Page404 from '../components/Page404';
import LoginPage from '../containers/LoginPage';
import Hotline from '../containers/Hotline';
import TicketList from '../containers/TicketList';
import Ticket from '../containers/Ticket';
import NewTicket from '../containers/NewTicket';
import SignUp from '../containers/SignUp';
import SignUpVerify from '../containers/SignUpVerify';
import PasswordRecovery from '../containers/PasswordRecovery';
import OldLinks from '../containers/OldLinks';
import HomeIcon from '@material-ui/icons/Home';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PasswordRecoveryVerify from '../containers/PasswordRecoveryVerify';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import License from '../containers/License';
import CallIcon from '@material-ui/icons/Call';
import Call from '../containers/staff/Call';
import Totp from '../containers/Totp';
import MailIcon from '@material-ui/icons/Mail';
import Mailings from '../containers/staff/Mailings';
import MailingLists from '../containers/staff/MailingLists';
import Subscriptions from '../containers/staff/Subscriptions';
import HashSubscriptions from '../containers/HashSubscriptions';

export const getUriMap = is_staff => is_staff ?
  {
    '/': {
      exact: true,
      noDrawer: true,
      hideFromBreadcrumbs: true,
      addToDrawer: true,
      drawerIcon: <HomeIcon />,
      pageName: () => <FormattedMessage id="pageNames.home" defaultMessage="Home" />,
      Component: StaffMainPage,
    },
    '/call/:id/:action?': {
      exact: true,
      addToDrawer: true,
      drawerLink: '/call/new',
      drawerIcon: <CallIcon />,
      drawerName: () => <FormattedMessage id="pageNames.newCall" defaultMessage="New call" />,
      pageName: () => <FormattedMessage id="pageNames.call" defaultMessage="Call" />,
      Component: Call,
    },
    '/mailings': {
      exact: true,
      addToDrawer: true,
      drawerIcon: <MailIcon />,
      pageName: () => <FormattedMessage id="pageNames.mailings" defaultMessage="Mailings" />,
      Component: Mailings,
    },
    '/mailings/lists': {
      exact: true,
      pageName: () => <FormattedMessage id="pageNames.mailingLists" defaultMessage="Mailing lists" />,
      Component: MailingLists,
    },
    '/mailings/subscriptions': {
      exact: true,
      pageName: () => <FormattedMessage id="pageNames.subscriptions" defaultMessage="Subscriptions" />,
      Component: Subscriptions,
    },
    '/login/:login?/:password?': {
      noAuth: true,
      pageName: () => <FormattedMessage id="pageNames.login" defaultMessage="Login" />,
      Component: LoginPage,
    },
    '/totp': {
      noAuth: true,
      pageName: () => <FormattedMessage id="pageNames.login" defaultMessage="Login" />,
      Component: Totp,
    },
    '/hash/subscriptions/:hash': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.subscriptions' defaultMessage='Subscriptions' />,
      Component: HashSubscriptions,
    },
    '': {
      hideFromBreadcrumbs: true,
      pageName: () => <FormattedMessage id="pageNames.404" defaultMessage="404 Not Found" />,
      Component: Page404,
    },
  }
  :
  {
    '/': {
      exact: true,
      noDrawer: true,
      hideFromBreadcrumbs: true,
      addToDrawer: true,
      drawerIcon: <HomeIcon />,
      pageName: () => <FormattedMessage id="pageNames.home" defaultMessage="Home" />,
      Component: MainPage,
    },
    '/login/:login?/:password?': {
      noAuth: true,
      pageName: () => <FormattedMessage id="pageNames.login" defaultMessage="Login" />,
      Component: LoginPage,
    },
    '/totp': {
      noAuth: true,
      pageName: () => <FormattedMessage id="pageNames.login" defaultMessage="Login" />,
      Component: Totp,
    },
    '/sign-up': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.signUp' defaultMessage='Sign up' />,
      Component: SignUp,
    },
    '/sign-up/verify/:email?/:key?': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.signUpVerify' defaultMessage='Email verification' />,
      Component: SignUpVerify,
    },
    '/hash/subscriptions/:hash': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.subscriptions' defaultMessage='Subscriptions' />,
      Component: HashSubscriptions,
    },
    '/password-recovery': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.passwordRecovery' defaultMessage='Recover password' />,
      Component: PasswordRecovery,
    },
    '/password-recovery/verify/:email?/:key?': {
      exact: true,
      noAuth: true,
      pageName: () => <FormattedMessage id='pageNames.passwordRecoveryVerify' defaultMessage='Email verification' />,
      Component: PasswordRecoveryVerify,
    },
    '/hotline': {
      exact: true,
      addToDrawer: true,
      drawerIcon: <LiveHelpIcon />,
      pageName: () => <FormattedMessage id="pageNames.hotline" defaultMessage="Hotline" />,
      Component: Hotline,
    },
    '/license': {
      exact: true,
      addToDrawer: true,
      drawerIcon: <VpnKeyIcon />,
      pageName: () => <FormattedMessage id="pageNames.license" defaultMessage="License keys" />,
      Component: License,
    },
    '/old': {
      exact: true,
      addToDrawer: true,
      drawerIcon: <NavigateNextIcon />,
      pageName: () => <FormattedMessage id="pageNames.old" defaultMessage="Old private office" />,
      Component: OldLinks,
    },
    '/hotline/:type': {
      exact: true,
      pageName: ({ type }) => <FormattedMessage id="pageNames.hotline.ticketList" defaultMessage="{type} tickets" values={{ type }} />,
      Component: TicketList,
    },
    '/hotline/:type/ticket/:tn': {
      exact: true,
      pageName: ({ tn }) => <FormattedMessage id="pageNames.hotline.ticket" defaultMessage="Ticket #{tn}" values={{ tn }} />,
      Component: Ticket,
    },
    '/hotline/:type/create': {
      exact: true,
      pageName: () => <FormattedMessage id="pageNames.hotline.ticketCreate" defaultMessage="Create ticket" />,
      Component: NewTicket,
    },
    '': {
      hideFromBreadcrumbs: true,
      pageName: () => <FormattedMessage id="pageNames.404" defaultMessage="404 Not Found" />,
      Component: Page404,
    },
  };