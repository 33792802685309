import React from 'react';
import { FormattedMessage } from 'react-intl';
// prettier-ignore
const messages = {
  AUTH_LOGIN_OR_PASSOWRD_INCORRECT: (
    <FormattedMessage
      id="error.AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
      defaultMessage="error.AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
    />
  ),
  BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT: (
    <FormattedMessage
      id="error.BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
      defaultMessage="error.BASIC_AUTH_LOGIN_OR_PASSOWRD_INCORRECT"
    />
  ),
  UNKNOWN_ERROR: (
    <FormattedMessage
      id='error.message.noMessageProvided'
      defaultMessage='Unknown error'
    />
  ),
};

// prettier-ignore
export function getErrorMessage(error) {
  return messages[error] || error;
}
