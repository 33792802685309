import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  message: {
    color: '#fff',
  },
};

const reloadPage = () => window.location.reload();

const PageErrorSnackbar = ({ classes, open }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={open}
    onClose={null}
    onClick={null}
    message={
      <Fragment>
        <Typography color="error" variant="subheading">
          <FormattedMessage id="pageError.snackbar.title" defaultMessage="An error occurred" />
        </Typography>
        <Typography className={classes.message} variant="body1">
          <FormattedMessage id="pageError.snackbar.message" defaultMessage="Please, reload the page and try again" />
        </Typography>
      </Fragment>
    }
    action={
      <Button color="primary" onClick={reloadPage}>
        <FormattedMessage id="pageError.button.reloadPage" defaultMessage="Reload" />
      </Button>
    }
  />
);

export default withStyles(styles)(PageErrorSnackbar);
