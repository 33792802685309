import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Localize from '../common/Localize';
import { ControlledTextField } from '../Form';

class IptvLicenseSelector extends ControlledTextField {
    render() {
        const {
            name,
            label,
            licenses,
            ...restProps
        } = this.props;

        return (
            <ControlledTextField
                {...restProps}
                name={name}
                label={label}
                select
                value={false}
            >
                <MenuItem value={false}>
                    <FormattedMessage id='addLicense.select' defaultMessage='<select>' />
                </MenuItem>
                {Array.isArray(licenses) && licenses.map(({ id, systems }) => (
                    <MenuItem value={id} key={id}>
                        <Table size='small' padding='none'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            <FormattedMessage id='addLicense.iptvLicenseMenuItem' defaultMessage='License ID {id}' values={{ id }} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {systems.length > 0 &&
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id='license.component' defaultMessage='Component' />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id='license.validityPeriod' defaultMessage='Validity period' />
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableHead>
                            <TableBody>
                                {systems.map(({ type, till }, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <Localize>
                                                {type}
                                            </Localize>
                                        </TableCell>
                                        <TableCell>
                                            <Typography color={new Date(till) > new Date() ? 'initial' : 'error'} variant='body2'>
                                                <FormattedDate
                                                    value={till}
                                                    year='numeric'
                                                    month='numeric'
                                                    day='numeric'
                                                />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </MenuItem>
                ))}
            </ControlledTextField>
        );
    };
}

export default IptvLicenseSelector;