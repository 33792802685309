import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const classes = {
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px 0px',
  },
};

class PageLoadingIndicator extends React.Component {
  state = { openDelayed: false };

  componentDidMount() {
    if (this.props.open) {
      this.timerId = setTimeout(() => this.setState({ openDelayed: true }), 200);
    }
  }

  componentDidUpdate() {
    if (this.state.openDelayed && !this.props.open) {
      clearTimeout(this.timerId);
      this.setState({ openDelayed: false });
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Collapse in={this.state.openDelayed} unmountOnExit>
        <Fade in={this.state.openDelayed}>
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        </Fade>
      </Collapse>
    );
  }
}

export default withStyles(classes)(PageLoadingIndicator);
