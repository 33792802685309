import React from 'react';
import { fetcher } from '../../util/deps';
import emitter from '../../emitter';
import ButtonWithProgress from '../ButtonWithProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';

class DownloadLicenseButton extends React.Component {
    state = {
        downloading: false,
    };

    downloadLicense = async () => {
        const {
            type,
            id,
        } = this.props;
        if (this.state.downloading) {
            return false;
        }

        this.setState({
            downloading: true,
        });
        try {
            await fetcher.get(`license/${type}/${id}`);
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
        this.setState({
            downloading: false,
        });
    }

    render() {
        const {
            type,
            id,
        } = this.props;
        const {
            downloading,
        } = this.state;
        if (!type || (!id && type !== 'payment_systems')) {
            return null;
        }
        return (
            <Tooltip
                title={
                    <FormattedMessage id='license.download' defaultMessage='Download license' />
                }
            >
                <div>
                    <ButtonWithProgress showProgress={downloading} onClick={this.downloadLicense}>
                        <GetAppIcon />
                    </ButtonWithProgress>
                </div>
            </Tooltip>
        );
    };
};

export default DownloadLicenseButton;