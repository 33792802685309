import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import stacktrace from 'stacktrace-js';

import { getUriMap } from '../util/uriMap';
import { fetcher } from '../util/deps';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Main from './Main';
import { authActions } from '../redux-stuff/actions';
import store from '../util/store';
import SnackbarContainer from '../components/common/SnackbarContainer';
import ErrorDetails from '../components/common/ErrorDetails';
import ErrorBoundaryWrapper from '../components/ErrorBoundaryWrapper';
import emitter from '../emitter';

const styles = {
  root: {
    flexGrow: 1,
    zIndex: 1,
  },
};

// const allPages = Object.keys(uriMap).filter(Boolean);

class AppWrapper extends React.Component {
  state = {
    pageError: false,
    snackbarDetailsModalContents: null,
    isStaff: undefined,
  };

  componentDidCatch(error) {
    // prettier-ignore
    stacktrace
      .fromError(error)
      .then(
        stackframes => {
          const errorMessage = error.message || <FormattedMessage id='error.CRITICAL_ERROR' defaultMessage='Something went wrong' />;
          const error_ = new Error(errorMessage);
          error_.errorMessage = errorMessage;

          error_.details = stackframes.map(
            frame => {
              return `${frame.functionName} in ${frame.fileName}:${frame.lineNumber},${frame.columnNumber}`;
            }
          );

          error_.time = new Date();

          this.addMessage({ type: 'failure', data: error_ });
        }
      );

    console.error(error); // eslint-disable-line
    this.setState({ pageError: true });
  }

  initialLoad = async () => {
    let isAuth;
    try {
      const info = await fetcher.get('auth');
      const { authorized, username, session_id: sessionId, uid, is_staff: isStaff } = info || {};
      isAuth = authorized;
      this.props.setAuth({ authorized, username, sessionId, uid, isStaff });
      this.setState({ isStaff });
    } catch (e) {
      isAuth = false;
    }
    this.props.setIsAuth(isAuth);
    return isAuth;
  };

  async componentDidMount() {
    let prevIsAuth = await this.initialLoad();
    store.subscribe(() => {
      const { isAuth } = store.getState();
      if (!prevIsAuth && isAuth) {
        this.initialLoad();
      }
      prevIsAuth = isAuth;
    });
  }

  openSnackbarDetailsModal = snackbarDetailsModalContents =>
    this.setState({ snackbarDetailsModalContents, snackbarDetailsOpen: true });

  closeSnackbarDetailsModal = () => this.setState({ snackbarDetailsOpen: false });

  addMessage = message => emitter.emit('addMessage', message);
  addMessageInjectLocationIfError = location => message => {
    if (message.data instanceof Error) {
      message.data.location = location;
    }
    this.addMessage(message);
  };

  componentDidUpdate(prevProps, prevState) {
    // Reset error after page change
    if (prevState.pageError && this.state.pageError) {
      this.setState({ pageError: false });
    }
  }

  render() {
    const { classes, isAuth } = this.props;
    const {
      snackbarDetailsModalContents,
      snackbarDetailsOpen,
      pageError,
      isStaff,
    } = this.state;
    const uriMap = getUriMap(isStaff);
    const referrer = this.props.location.pathname;

    return (
      <div className={classes.root}>
        <ErrorBoundaryWrapper
          allPages={Object.keys(uriMap).filter(Boolean)}
          setIsAuth={this.props.setIsAuth}
          addMessage={this.addMessage}
          pageError={pageError}
        >
          {AppBar}
        </ErrorBoundaryWrapper>
        <ErrorBoundaryWrapper pageError={pageError}>
          {Drawer}
        </ErrorBoundaryWrapper>
        {/*Initial isAuth === null*/}
        {isAuth !== null && (
          <Main pageError={pageError}>
            <Switch>
              {Object.entries(uriMap).map(([location, { exact, noAuth, Component }]) => {
                if ((isAuth && isStaff !== undefined) || noAuth) {
                  return (
                    <Route
                      key={location}
                      exact={!!exact}
                      path={location.length ? location : undefined}
                      render={props => (
                        <Component
                          {...props}
                          addMessage={this.addMessageInjectLocationIfError(location)}
                          emitter={emitter}
                        />
                      )}
                    />
                  );
                }
                return null;
              })}
              {!isAuth ?
                <Redirect to={{ pathname: '/login', state: { referrer } }} />
                :
                null
              }
            </Switch>
          </Main>
        )}
        <SnackbarContainer events={emitter} openDetails={this.openSnackbarDetailsModal} />
        <ErrorDetails
          open={snackbarDetailsOpen}
          error={snackbarDetailsModalContents}
          onClose={this.closeSnackbarDetailsModal}
        />
      </div>
    );
  }
}

AppWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    ({ isAuth }) => ({ isAuth }),
    {
      setIsAuth: authActions.setIsAuth,
      setAuth: authActions.setAuth,
    },
  ),
  withStyles(styles),
)(AppWrapper);
