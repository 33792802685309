import './App.css';

import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import AppWrapper from './core/AppWrapper';
import store from './util/store';
import { LocaleContext } from './context';
import { blueGrey, grey, blue } from '@material-ui/core/colors';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blueGrey,
    secondary: grey,
    error: red,
    borderColor: 'rgba(0, 0, 0, 0.12)',
    appBarBackgroundColor: '#212121',
    homeAppBarColor: '#757575',
    table: {
      hover: 'rgba(0, 0, 0, 0.05)',
    },
    icons: {
      default: 'rgba(0, 0, 0, 0.54)',
      success: green[500],
      info: lightBlue[400],
      warning: orange[300],
      danger: red[700],
    },
    alerts: {
      warning: 'rgb(255, 243, 205)',
      danger: 'rgb(248, 215, 218)',
      info: '#d1ecf1',
    },
    widget: {
      headerBackground: '#757575',
      headerBackgroundError: '#e53935',
      headerText: '#fff',
    },
    detailsTable: {
      background: '#eee',
      border: '#aaa',
    },
    cards: {
      default: blueGrey[600],
      utm5: green[600],
      iptv: lightBlue[700],
      hotline: blue[500],
      oldLinks: grey[500],
      license: 'rgb(85, 26, 139)',
    }
  },
});

const languages = [{ lang: 'English', locale: 'en' }, { lang: 'Русский', locale: 'ru' }];

const MESSAGES_RU = {
  locale: 'ru',
  messages: require('./locale/ru_RU.json'),
};
const MESSAGES_EN = {
  locale: 'en',
  messages: require('./locale/en_US.json'),
};

if (!localStorage.getItem('locale')) {
  if (['ru', 'en'].includes(localStorage.systemLanguage)) {
    localStorage.setItem('locale', localStorage.systemLanguage);
  } else if (['ru', 'en'].includes(localStorage.interfaceLanguage)) {
    localStorage.setItem('locale', localStorage.interfaceLanguage);
  }
  delete localStorage.systemLanguage;
  delete localStorage.interfaceLanguage;
}

const navigatorLanguage = navigator.language.startsWith('ru') ? 'ru' : 'en';
const cookieLocale = ['ru', 'en'].includes(localStorage.getItem('locale')) && localStorage.getItem('locale');
const initialLocale = cookieLocale || navigatorLanguage;

export default class App extends Component {
  state = {
    messages: initialLocale === 'ru' ? MESSAGES_RU : MESSAGES_EN,
    snackbarDetailsModalContents: null,
  };

  switchLanguage = language => {
    this.setState({ messages: language === 'ru' ? MESSAGES_RU : MESSAGES_EN }, () => {
      localStorage.setItem('locale', language);
    });
  };

  render() {
    const { messages } = this.state;
    return (
      <Provider store={store}>
        <IntlProvider locale={messages.locale} messages={messages.messages}>
          <MuiThemeProvider theme={theme}>
            <Helmet htmlAttributes={{ lang: messages.locale }}>
              <meta http-equiv="Content-Language" content={messages.locale} />
            </Helmet>
            <LocaleContext.Provider value={{ locale: messages.locale, switchLanguage: this.switchLanguage, languages }}>
              <AppWrapper />
            </LocaleContext.Provider>
          </MuiThemeProvider>
        </IntlProvider>
      </Provider>
    );
  }
}
