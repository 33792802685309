import React from 'react';
import { FormattedMessage } from 'react-intl';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { ControlledButton, ControlledToolbar, ControllingForm, LanguageSelector } from '../../components/Form';
import { fetcher } from '../../util/deps';
import emitter from '../../emitter';
import LoadingWrapper from '../../components/common/LoadingWrapper';
import { CallActions, CallInfo, Comment, Tickets } from '../../components/staff/Call';

class Call extends React.Component {
    state = {
        loading: true,
    };

    async componentDidMount() {
        const { match: { params: { id } }, history: { push } } = this.props;
        let call_id = id;
        try {
            if (id === 'new') {
                const id = await fetcher.put('staff/call');
                call_id = id;
                push(`/call/${id}`);
            }
            const info = await this.getCallInfo(call_id);
            this.setState({
                loading: false,
                ...info
            });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    async componentDidUpdate(oldProps) {
        const { match: { params: { id } }, history: { push } } = this.props;
        if (id === 'new' && oldProps.match.params.id !== 'new') {
            this.setState({ loading: true });
            try {
                const id = await fetcher.put('staff/call');
                push(`/call/${id}`);
                const info = await this.getCallInfo(id);
                this.setState({
                    loading: false,
                    customer: null,
                    tickets: [],
                    end: null,
                    ...info
                });
            } catch (e) {
                emitter.emit('addMessage', { type: 'failure', data: e });
            }
            this.setState({ loading: false });
        }
    }

    async getCallInfo(id) {
        try {
            const info = await fetcher.get(`staff/call/${id}`);
            return info;
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    endCallHandler = async () => {
        try {
            this.setState({ loading: true });
            await fetcher.delete(`staff/call`);
            const info = await this.getCallInfo(this.state.id);
            this.setState({
                loading: false,
                ...info
            });
            return true;
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    updateCallInfo = async () => {
        const { match: { params: { id } } } = this.props;
        this.setState({
            ...await this.getCallInfo(id)
        });
    }

    render = () => {
        const {
            loading,
            start,
            end,
            customer,
            tickets,
        } = this.state;
        const { match: { params: { id, action } } } = this.props;
        const {
            id: customerId,
            support,
        } = customer || {};
        const supportEnd = [];
        for (let type in support) {
            const end = new Date(support[type].end);
            if (end > new Date()) {
                supportEnd.push({
                    type,
                    end,
                })
            }
        }
        const hasSupport = supportEnd.length !== 0;

        return (
            <LoadingWrapper wrap={loading}>
                <CallInfo
                    id={id}
                    start={start}
                    end={end}
                    customer={customer}
                    support={supportEnd}
                />
                <Tickets
                    tickets={tickets}
                    customerId={customerId}
                />
                <CallActions
                    id={id}
                    action={action}
                    onSubmit={this.updateCallInfo}
                    customerId={customerId}
                    hasSupport={hasSupport}
                />
                <Comment callId={id} />
                {
                    !end ?
                        <React.Fragment>
                            <ControllingForm name='language' padding={1} md={12} lg={8}>
                                <LanguageSelector />
                            </ControllingForm>
                            <ControllingForm name='callFinish' slim md={12} lg={8}>
                                <ControlledToolbar>
                                    <ControlledButton
                                        icon={<CallEndIcon />}
                                        primary
                                        onClick={this.endCallHandler}
                                    >
                                        <FormattedMessage id='call.finish' defaultMessage='Finish call' />
                                    </ControlledButton>
                                </ControlledToolbar>
                            </ControllingForm>
                        </React.Fragment>
                        : null
                }
            </LoadingWrapper >
        );
    };
};

export default Call