import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Button, Tooltip } from '@material-ui/core';
import AppBarComponent from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChangePassword from '@material-ui/icons/VpnKey';
import ResponsiveDialog from '../components/ResponsiveDialog';

import { authActions, drawerActions } from '../redux-stuff/actions';
import { USER_LOGOUT } from '../redux-stuff/constants';
import { getUriMap } from '../util/uriMap';
import { fetcher } from '../util/deps';
import ActionsMenu from '../components/ActionsMenu';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import { makeTitle, makeExpandDrawerIcon } from '../components/mainPage';
import PageErrorSnackbar from '../components/common/PageErrorSnackbar';
import ErrorBellContainer from '../components/AppBarErrorBellContainer';
import store from '../util/store';
import SubscriptionsDialog from '../components/SubscriptionsDialog';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover $hideIcon': {
      color: 'rgba(255, 255, 255, .5)',
    },
  },
  homePage: {
    backgroundColor: theme.palette.homeAppBarColor,
  },
  hideIcon: {
    color: 'rgba(0, 0, 0, 0)',
  },
  hover: {
    '&:hover svg': {
      color: 'inherit !important',
    },
  },
  appBarSelectedItems: {
    backgroundColor: theme.palette.appBarBackgroundColor,
    transition: 'all 0.2s cubic-bezier(.25,.8,.25,1)',
  },
  leftMarginText: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: theme.spacing(1) * 3,
    },
  },
  leftMarginButton: {
    marginLeft: theme.spacing(1) * 2,
    '&:first-child': {
      marginLeft: theme.spacing(1) * 1.5,
    },
  },
  midPlaceholder: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(1) * 1.5,
    marginRight: theme.spacing(1) * 2,
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  actionButton: {
    marginRight: theme.spacing(1),
    marginLeft: 0,
  },
  userMenuButton: {
    marginRight: theme.spacing(1),
    textTransform: 'none',
  },
  link: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    color: 'white',
  },
});

class AppBar extends React.Component {
  state = {
    userMenuOpen: false,
    logoutDialogOpen: false,
    anchorEl: null,
    username: undefined,
    uid: undefined,
    isStaff: undefined,
    appBar: undefined,
    subscriptionsDialogOpen: false,
  };

  openUserMenu = event => this.setState({ userMenuOpen: true, anchorEl: event.currentTarget });
  closeUserMenu = () => this.setState({ userMenuOpen: false });

  openChangePasswordDialog = () => {
    this.setState({ changePasswordDialogOpen: true });
    this.closeUserMenu();
  };
  openLogoutDialog = () => {
    this.setState({ logoutDialogOpen: true });
    this.closeUserMenu();
  };

  openSubscriptionsDialog = () => {
    this.setState({ subscriptionsDialogOpen: true });
    this.closeUserMenu();
  };

  closeChangePasswordDialog = () => this.setState({ changePasswordDialogOpen: false });
  closeLogoutDialog = () => this.setState({ logoutDialogOpen: false });

  handleLogout = () => {
    this.setState({
      userMenuOpen: false,
      username: undefined,
      uid: undefined,
      isStaff: undefined,
    });
    store.dispatch(authActions.setAuth({ isStaff: undefined }));
    store.dispatch(authActions.setIsAuth(false));
    fetcher.post('logout', undefined, false);
    this.props.history.push('/login');
    this.props.logout();
  };

  getStoreData = () => {
    const {
      appBar,
      auth: {
        username,
        uid,
        isStaff,
      } } = store.getState();
    this.setState({
      appBar,
      username,
      uid,
      isStaff,
    });
  }

  componentDidMount() {
    this.getStoreData();
    store.subscribe(() => {
      this.getStoreData();
    });
  }

  render() {
    const {
      classes,
      width,
      addMessage,
      pageError,
      localError,
      intl,
    } = this.props;
    const {
      appBar,
      username,
      uid,
      isStaff,
      subscriptionsDialogOpen,
    } = this.state;
    const mobile = width === 'xs' || isMobile;
    const ExpandDrawerIcon = makeExpandDrawerIcon(this.props.toggleDrawerState, classes);
    const homePage = this.props.location.pathname === '/';
    let path = [];
    const uriMap = getUriMap(isStaff);
    const authorized = !!uid;

    return (
      <Fragment>
        {!localError && (
          <AppBarComponent
            className={classNames(
              classes.appBar,
              mobile && appBar && appBar.text && classes.appBarSelectedItems,
              homePage && classes.homePage,
            )}
          >
            <Toolbar disableGutters className={'toolBar'}>
              {mobile && appBar && appBar.leftButton ? (
                <Tooltip title={appBar.leftButton.title}>
                  <IconButton
                    color="inherit"
                    aria-label="undo select"
                    onClick={appBar.leftButton.action}
                    className={classes.menuButton}
                  >
                    {appBar.leftButton.icon}
                  </IconButton>
                </Tooltip>
              ) : (
                  <Switch>
                    {Object.entries(uriMap).filter(([, { noAuth, noDrawer }]) => noAuth || noDrawer).map(([path, { exact, noAuth }], index) => (
                      noAuth ?
                        <Route exact={exact} path={path} key={index} />
                        :
                        <Route exact={exact} path={path} render={pageError ? ExpandDrawerIcon : null} key={index} />
                    ))}
                    <Route render={ExpandDrawerIcon} />
                  </Switch>
                )}
              {mobile && appBar && appBar.text ? (
                <Typography variant="h6" classes={{ root: classes.leftMarginText }} color="inherit" noWrap>
                  {appBar.text}
                </Typography>
              ) : (
                  <Fragment>
                    {Object.entries(uriMap).map(([location, { pageName, hideFromBreadcrumbs }]) => (
                      <Route
                        key={location}
                        path={location}
                        component={makeTitle({ pageName, classes, intl, hideFromBreadcrumbs, location, path })} />
                    ))}
                  </Fragment>
                )}
              <div className={classes.midPlaceholder} />
              {mobile && appBar &&
                appBar.rightButtons &&
                appBar.rightButtons.map(action => (
                  <Tooltip key={action.key} title={action.title}>
                    <IconButton color="inherit" onClick={action.action} className={classes.actionButton}>
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              {(!mobile || (mobile && (!appBar || !appBar.rightButtons))) && <ErrorBellContainer />}
              {authorized && !mobile &&
                <Button color="inherit" onClick={this.openUserMenu} className={classes.userMenuButton}>
                  {username} (ID: {uid})
                </Button>
              }
              <ActionsMenu
                open={this.state.userMenuOpen}
                anchorEl={this.state.anchorEl}
                handleClose={this.closeUserMenu}
                items={[
                  {
                    show: authorized,
                    Icon: ChangePassword,
                    text: <FormattedMessage id="appBar.actionsMenu.changePassword" defaultMessage="Change password" />,
                    handler: this.openChangePasswordDialog,
                  },
                  {
                    show: authorized && !isStaff,
                    Icon: MailIcon,
                    text: <FormattedMessage id='appBar.actionsMenu.subscriptions' defaultMessage='Subscriptions' />,
                    handler: this.openSubscriptionsDialog,
                  },
                  {
                    show: authorized,
                    Icon: ExitToAppIcon,
                    text: <FormattedMessage id="appBar.logout" defaultMessage="Logout ({username})" values={{ username }} />,
                    handler: this.openLogoutDialog,
                  },
                ]}
              />
            </Toolbar>

            <ChangePasswordDialog
              open={!!this.state.changePasswordDialogOpen}
              anchorName={username}
              onClose={this.closeChangePasswordDialog}
              addMessage={addMessage}
            />

            <ResponsiveDialog
              open={this.state.logoutDialogOpen}
              title={<FormattedMessage id="appBar.logoutDialog.title" defaultMessage="Logout" />}
              message={
                <FormattedMessage
                  id="appBar.logoutDialog.message"
                  defaultMessage="Are you sure you want to logout?"
                  values={{ roleToBeDeleted: this.state.anchorName }}
                />
              }
              confirmButtonText={<FormattedMessage id="appBar.logoutDialog.button.logout" defaultMessage="Logout" />}
              onClose={this.closeLogoutDialog}
              onConfirm={this.handleLogout}
              closeOnConfirm
              fullWidth
            />
            
            {authorized && !isStaff && <SubscriptionsDialog open={subscriptionsDialogOpen} onClose={() => this.setState({ subscriptionsDialogOpen: false })} />}
          </AppBarComponent>
        )}
        <PageErrorSnackbar open={localError} />
      </Fragment>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  injectIntl,
  withRouter,
  connect(
    null,
    {
      toggleDrawerState: drawerActions.toggleDrawerState,
      logout: () => ({ type: USER_LOGOUT }),
    },
  ),
  withStyles(styles),
  withWidth(),
)(AppBar);
