import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Paper, Typography } from '@material-ui/core';
import { ControlledButton, ControlledToolbar, ControllingForm } from '../../Form';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';

class NoSupport extends React.PureComponent {
    render = () => {
        const {
            callId,
        } = this.props;
        return (
            <ControllingForm name='callCustomer' padding={1} md={12} lg={8}>
                <Paper>
                    <Box p={2}>
                        <Typography variant='body1' color='error'>
                            <FormattedMessage id='call.noSupport.helperText' defaultMessage='No support' />
                        </Typography>
                        <Typography>
                            <FormattedMessage id='call.noSupport.helperText2' defaultMessage='Only old tickets processed' />
                        </Typography>
                    </Box>
                </Paper>
                <ControlledToolbar>
                    <ControlledButton
                        primary
                        icon={<SearchIcon />}
                        onClick={() => {
                            this.props.history.push(`/call/${callId}/find-ticket`);
                            return true;
                        }}
                    >
                        <FormattedMessage id='call.ticket.find' defaultMessage='Find ticket' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

export default withRouter(NoSupport);