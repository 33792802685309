import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Utm5LicenseSelector } from '.';
import { emailValidator, phoneValidator, urlValidator } from '../../util/validators';
import { ControlledCountryList, ControlledPhone, ControlledTextField, ControllingForm } from '../Form';
import store from '../../util/store';

class NewUtm5LicenseForm extends React.Component {
    static controlType = 'form';
    static formName = 'newUtm5LicenseForm';

    componentDidMount() {
        const {
          auth: { username },
        } = store.getState();
        if (localStorage.getItem(`${NewUtm5LicenseForm.formName}.email`))
          return;
        localStorage.setItem(`${NewUtm5LicenseForm.formName}.email`, username);
    }

    render() {
        const {
            core,
            module,
            onUpdate,
        } = this.props;

        if (!core || !Array.isArray(core) || core.length === 0) {
            return (
                <Box mt={1}>
                    <Typography color='error'>
                        <FormattedMessage id='newUtm5LicenseForm.noCoreLicenseAvailable' defaultMessage='No core license avaliable' />
                    </Typography>
                </Box>
            );
        }

        if (!module || !Array.isArray(module) || module.length === 0) {
            return (
                <Box mt={1}>
                    <Typography color='error' >
                        <FormattedMessage id='newUtm5LicenseForm.noModuleLicenseAvailable' defaultMessage='No module license avaliable' />
                    </Typography>
                </Box>
            );
        }

        return (
            <ControllingForm
                fullWidth
                flat
                slim
                name={NewUtm5LicenseForm.formName}
                onUpdate={onUpdate}
            >
                <Utm5LicenseSelector
                    name='module_license'
                    label={<FormattedMessage id='newUtm5LicenseForm.moduleLicense' defaultMessage='Module license' />}
                    required
                    licenses={module}
                />
                <ControlledTextField
                    name='organization'
                    label={<FormattedMessage id='license.organization' defaultMessage='Organization' />}
                    required
                    autoComplete='organization'
                />
                <ControlledTextField
                    name='department'
                    label={<FormattedMessage id='license.department' defaultMessage='Department' />}
                />
                <ControlledTextField
                    name='email'
                    label={<FormattedMessage id='license.email' defaultMessage='Email' />}
                    required
                    validator={emailValidator}
                    autoComplete='email'
                />
                <ControlledCountryList
                    name='country'
                    label={<FormattedMessage id='license.country' defaultMessage='Country' />}
                    required
                />
                <ControlledTextField
                    name='region'
                    label={<FormattedMessage id='license.region' defaultMessage='Region' />}
                />
                <ControlledTextField
                    name='city'
                    label={<FormattedMessage id='license.city' defaultMessage='City' />}
                />
                <ControlledTextField
                    name='inn'
                    label={<FormattedMessage id='license.inn' defaultMessage='Tax ID' />}
                    required
                />
                <ControlledTextField
                    name='kpp'
                    label={<FormattedMessage id='license.kpp' defaultMessage='KPP' />}
                />
                <ControlledTextField
                    name='person'
                    label={<FormattedMessage id='license.person' defaultMessage='Person' />}
                    autoComplete='name'
                />
                <ControlledPhone
                    name='tel'
                    label={<FormattedMessage id='license.tel' defaultMessage='Phone' />}
                    required
                    autoComplete='tel'
                    validator={phoneValidator}
                />
                <ControlledPhone
                    name='fax'
                    label={<FormattedMessage id='license.fax' defaultMessage='Fax' />}
                    autoComplete='fax'
                    validator={phoneValidator}
                />
                <ControlledTextField
                    name='web_page'
                    label={<FormattedMessage id='license.webSite' defaultMessage='Web site' />}
                    autoComplete='url'
                    validator={urlValidator}
                />
                <ControlledTextField
                    name='jur_addr'
                    label={<FormattedMessage id='license.legalAddress' defaultMessage='Legal address' />}
                    required
                />
                <ControlledTextField
                    name='act_addr'
                    label={<FormattedMessage id='license.officeAddress' defaultMessage='Office address' />}
                />
            </ControllingForm>
        );
    };
};

export default NewUtm5LicenseForm;