import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import DrawerComponent from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { drawerActions } from '../redux-stuff/actions';
import DrawerItems from '../components/DrawerItems';
import PageErrorSnackbar from '../components/common/PageErrorSnackbar';
import { getUriMap } from '../util/uriMap';
import store from '../util/store';

const drawerWidth = 256;

const styles = theme => {
  const makeTransition = (props, duration) =>
    theme.transitions.create(props, {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration[duration],
    });
  return {
    drawerPaper: {
      position: 'fixed',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: makeTransition('width', 'enteringScreen'),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: makeTransition('width', 'leavingScreen'),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8)
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar,
    },
    list: {
      [theme.breakpoints.up('lg')]: {
        padding: 0,
      },
    },
  };
};

class Drawer extends React.Component {
  state = {
    isStaff: undefined,
  };

  setDrawerState = state => this.props.dispatch(drawerActions.setDrawerState(state));

  handleDrawerToggle = () => this.props.dispatch(drawerActions.toggleDrawerState());

  toggleExpandNested = item => () => {
    const { openNested } = this.props.drawer;
    this.setDrawerState({
      openNested: openNested.includes(item) ? openNested.filter(name => name !== item) : [...openNested, item],
    });
  };

  static mapStateToProps({ drawer }) {
    return { drawer };
  }

  componentDidMount() {
    const { auth: { isStaff } } = store.getState();
    this.setState({isStaff});
    store.subscribe(() => {
      const { auth: { isStaff } } = store.getState();
      this.setState({ isStaff });
    });
  }

  render() {
    const { classes, drawer, width, pageError, location, localError } = this.props;
    const { isStaff } = this.state;
    if (isStaff === undefined) {
      return null;
    }
    const uriMap = getUriMap(isStaff);
    const actualUri = location.pathname.match(/(\/[-\w]*).*/)[1];

    const lgUp = width === 'lg' || width === 'xl';
    const renderDrawer = () =>
      lgUp ? (
        <DrawerComponent
          variant="permanent"
          classes={{ paper: classNames(classes.drawerPaper, !drawer.expanded && classes.drawerPaperClose) }}
          open={drawer.expanded}
        >
          <div className={classes.toolbar} />
          <Divider />
          <List className={classes.list}>
            <DrawerItems
              toggleExpandNested={this.toggleExpandNested}
              openNested={drawer.openNested}
              currentPage={actualUri}
              drawerExpanded={drawer.expanded}
              lgUp
            />
          </List>
          <Divider />
        </DrawerComponent>
      ) : (
          <SwipeableDrawer
            variant="temporary"
            open={drawer.expanded}
            onOpen={this.handleDrawerToggle}
            onClose={this.handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            <div>
              <List className={classes.list}>
                <DrawerItems
                  toggleExpandNested={this.toggleExpandNested}
                  openNested={drawer.openNested}
                  currentPage={actualUri}
                  handleDrawerToggle={this.handleDrawerToggle}
                  drawerExpanded={drawer.expanded}
                />
              </List>
              <Divider />
            </div>
          </SwipeableDrawer>
        );
    return (
      <Fragment>
        {!localError && (
          <Switch>
            {Object.entries(uriMap).filter(([path, { noAuth, noDrawer }]) => noAuth || noDrawer).map(([path, { exact, noAuth }], index) => (
              noAuth ?
                <Route exact={exact} path={path} render={null} key={index} />
                :
                <Route exact={exact} path={path} render={pageError ? renderDrawer : null} key={index} />
            ))}
            <Route render={renderDrawer} />
          </Switch>
        )}
        <PageErrorSnackbar open={localError} />
      </Fragment>
    );
  }
}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  connect(Drawer.mapStateToProps),
  withStyles(styles),
  withWidth(),
)(Drawer);
