import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const styles = theme => ({
  card: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
  },
  cardText: {
    color: '#fff',
  },
  cardContent: {
    padding: '24px 12px',
    flexGrow: 1,
  },
  cardSvg: {
    alignSelf: 'flex-end',
    flexShrink: 0,
    fill: 'rgba(255, 255, 255, .7)',
  },
  link: {
    display: 'flex',
    alignItems: 'stretch',
    flexGrow: 1,
    textDecoration: 'none',
  },
  defaultCard: {
    backgroundColor: theme.palette.cards.default,
  },
});

const MenuCard = ({ classes, uri, isExternalLink, cardClassNames, name, isSingleCard, SvgComponent }) => {
  const size = isSingleCard ? 12 : 6;

  const card = (
    <Card className={classNames(classes.card, cardClassNames || classes.defaultCard)}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" className={classNames(classes.cardText)}>
          {name}
        </Typography>
      </CardContent>
      {SvgComponent ? <SvgComponent className={classNames(classes.cardSvg)} /> : null}
    </Card>
  );

  return (
    <Grid item container xs={12} sm={size} display='flex' alignItems='stretch'>
      {isExternalLink ?
        <a className={classes.link} href={uri}>
          {card}
        </a>
        :
        <Link className={classes.link} to={uri}>
          {card}
        </Link>
      }
    </Grid>
  );
};

export default withStyles(styles)(MenuCard);
