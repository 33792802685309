import React from 'react';
import LoadingWrapper from '../components/common/LoadingWrapper';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import { Licenses } from '../components/Licenses';

class License extends React.Component {
  state = {
    loading: true,
    licenses: {},
    error: false,
  }

  loadLicenses = async () => {
    try {
      const licenses = await fetcher.get(`license`);
      this.setState({
        licenses,
        loading: false,
        error: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: true,
      })
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  }

  async componentDidMount() {
    this.loadLicenses();
  }

  render() {
    const {
      loading,
      error,
      licenses,
    } = this.state;

    return (
      <LoadingWrapper wrap={loading} error={error}>
        <Licenses licenses={licenses} reload={this.loadLicenses} />
      </LoadingWrapper>
    );
  }
}

export default License;
