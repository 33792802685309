import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function ButtonWithProgress({ classes, showProgress, children, className, disabled, ...rest }) {
  return (
    <div className={classNames(classes.wrapper, className ? className : '')}>
      <Button {...rest} disabled={disabled || showProgress}>
        {children}
      </Button>
      {showProgress && <CircularProgress color='secondary' thickness={5} size={24} className={classes.buttonProgress} />}
    </div>
  );
}

ButtonWithProgress.propTypes = {
  showProgress: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default withStyles(styles)(ButtonWithProgress);
