import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ControlledSwitch, ControlledTextField, ControlledPhone, ControllingForm, ControlledPassword, ControlledToolbar, ControlledButton, Captcha } from '../components/Form';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CancelIcon from '@material-ui/icons/Cancel';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import { emailValidator, phoneValidator, urlValidator } from '../util/validators';
import { LocaleContext } from '../context';

class SignUp extends React.Component {
    static contextType = LocaleContext;

    submitHandler = async data => {
        const { locale: lang } = this.context;
        try {
            const result = await fetcher.post('signup', { ...data, lang });
            const encodedEmail = encodeURIComponent(data.email);
            if (result) {
                this.props.history.push(`/sign-up/verify/${encodedEmail}`);
                return true;
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => (
        <ControllingForm name='signUp'>
            <ControlledTextField
                name='full_name'
                label={<FormattedMessage id='signUp.FullName' defaultMessage='Full name' />}
                required
                autoFocus
                autoComplete='name'
                maxLength={255}
            />
            <ControlledTextField
                name='email'
                label={<FormattedMessage id='signUp.email' defaultMessage='E-mail' />}
                required
                autoComplete='email'
                maxLength={255}
                validator={emailValidator}
            />
            <ControlledPassword
                name='password'
                label={<FormattedMessage id='signUp.Password' defaultMessage='Password' />}
                required
                autoComplete='new-password'
                showStrength
                maxLength={255}
            />
            <ControlledTextField
                name='company'
                label={<FormattedMessage id='signUp.company' defaultMessage='Company' />}
                required
                autoComplete='organization'
                maxLength={255} />
            <ControlledPhone
                name='phone'
                label={<FormattedMessage id='signUp.phone' defaultMessage='Phone' />}
                autoComplete='tel'
                validator={phoneValidator} />
            <ControlledTextField
                name='site'
                label={<FormattedMessage id='signUp.site' defaultMessage='Web site' />}
                autoComplete='url'
                validator={urlValidator}
                maxLength={255} />
            <ControlledSwitch
                name='subscribe'
                label={<FormattedMessage id='signUp.subscribe' defaultMessage='I would like to receive news via email' />}
                checked />
            <Captcha />
            <ControlledToolbar>
                <ControlledButton
                    isSubmitButton
                    primary
                    icon={<PersonAddIcon />}
                    onClick={this.submitHandler}
                >
                    <FormattedMessage id='signUp.submit' defaultMessage='Sign up' />
                </ControlledButton>
                <ControlledButton
                    icon={<CancelIcon />}
                    onClick={() => {
                        this.props.history.push('/');
                        return true;
                    }}
                >
                    <FormattedMessage id='signUp.cancel' defaultMessage='Cancel' />
                </ControlledButton>
            </ControlledToolbar>
        </ControllingForm>
    );
};

export default SignUp