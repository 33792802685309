import { combineReducers } from 'redux';
import issues from './issues';
import drawer from './drawer';
import appBar from './appBar';
import { isAuth, auth } from './auth';
import errors from './errors';
import { USER_LOGOUT } from '../constants';

const appReducer = combineReducers({
  issues,
  drawer,
  appBar,
  isAuth,
  auth,
  errors,
});

const rootReducer = (state, action) => {
  // In case of logout we should reset store
  if (action.type === USER_LOGOUT) {
    state = {
      isAuth: false,
      auth: {
        uid: null,
        username: null,
        sessionId: null,
        authorized: false,
        isStaff: false,
      }
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
