import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@material-ui/core';

class ControlledSwitch extends React.Component {
    static controlType = 'input';

    static cleanup = (formName, name) => {
        localStorage.removeItem(`${formName}.${name}`)
    }

    constructor(props) {
        super(props);
        const { name, formName, checked, onChange } = props;
        const stored = localStorage.getItem(`${formName}.${name}`);
        const value = stored ? stored === 'true' : checked;
        this.state = {
            value,
        };
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    changeHandler = ({ target: { checked: value } }) => {
        const { formName, onChange, name } = this.props
        localStorage.setItem(`${formName}.${name}`, value);
        this.setState({ value });
        if (typeof onChange === 'function') {
            onChange(value);
        }
    };

    render() {
        const {
            value,
        } = this.state;
        const {
            label,
        } = this.props;

        return (
            <FormControlLabel
                control={<Switch
                    checked={!!value}
                    color='primary'
                    onChange={this.changeHandler}
                />}
                label={label}
            />
        );
    };
};

ControlledSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    formName: PropTypes.string,
    label: PropTypes.element.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ControlledSwitch;