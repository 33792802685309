import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ControllingForm } from '../../Form';
import Localize from '../../common/Localize';
import { FormattedMessage } from 'react-intl';
import { AgentTicket } from '../Ticket';
import LoadingWrapper from '../../common/LoadingWrapper';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';

class Tickets extends React.PureComponent {
    state = {
        ticket: undefined,
        loading: false,
    }

    ticketUpdate = async () => {
        const { customerId } = this.props;
        const { ticket: { tn } } = this.state;
        this.setState({ loading: true });
        try {
            const ticket = await fetcher.get(`staff/ticket/${customerId}/${tn}`);
            this.setState({ ticket });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
        this.setState({ loading: false });
    }

    componentDidMount() {
        const { ticket } = this.props;
        this.setState({ ticket });
    }

    render = () => {
        const {
            ticket,
            loading,
        } = this.state;
        const {
            customerId,
        } = this.props;

        if (!ticket) {
            return null;
        }
        const { tn, state, first_article, staff } = ticket;
        const { subject } = first_article;

        return (
            <LoadingWrapper wrap={loading}>
                <ControllingForm name='Tickets' slim md={12} lg={8}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell padding='none'>
                                            {tn}
                                        </TableCell>
                                        <TableCell padding='none'>
                                            <Localize>
                                                {state}
                                            </Localize>
                                        </TableCell>
                                        <TableCell padding='none'>
                                            {subject}
                                        </TableCell>
                                        <TableCell padding='none'>
                                            {staff ?
                                                `${staff.first_name} ${staff.last_name}`
                                                :
                                                <FormattedMessage id='call.ticket.staffNotAssigned' defaultMessage='Staff not assigned' />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AgentTicket
                                ticket={ticket}
                                customerId={customerId}
                                onSubmit={this.ticketUpdate}
                            />
                        </AccordionDetails>
                    </Accordion>
                </ControllingForm>
            </LoadingWrapper>
        );
    };
};

export default Tickets