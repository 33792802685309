import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withTheme } from '@material-ui/core/styles';
import { TextField, Box, FormControl, Toolbar } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';
import FileUploader from '../FileUploader';
import Attachments from './Attachments';
import { fetcher } from '../../util/deps';
import LoadingWrapper from '../common/LoadingWrapper';
import emitter from '../../emitter';
import ButtonWithProgress from '../ButtonWithProgress';

class ReplyForm extends React.Component {
    state = {
        answerText: '',
        attachments: [],
        loading: true,
        error: false,
        sending: false,
        shouldClose: false,
    };

    async sendReply(shouldClose) {
        const { tn, onSubmit } = this.props;
        const { answerText, attachments } = this.state;
        this.setState({ sending: true, shouldClose });
        try {
            const reply = await fetcher.post(`ticket/${tn}`, {
                text: answerText,
                attachments: attachments.map((attach) => attach.id),
                close: shouldClose,
            });
            if (reply) {
                this.setState({
                    answerText: '',
                    attachments: await fetcher.get('upload/otrs'),
                });
                localStorage.removeItem(`answerText.${this.props.tn}`);
                onSubmit();
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ sending: false });
        }
    };

    getReplyHandler = () => () => {
        this.sendReply(false);
    };

    getCloseHandler = () => () => {
        this.sendReply(true);
    };

    getAnswerChangeHandler = () => (event) => {
        const answerText = event.target.value
        this.setState({ answerText });
        localStorage.setItem(`answerText.${this.props.tn}`, answerText);
    };

    attachmentDeleteHandler = async (id) => {
        try {
            if (await fetcher.delete(`upload/otrs/${id}`)) {
                this.setState({
                    attachments: this.state.attachments.filter((attach) => attach.id !== id),
                });
            } else {
                const errorMessage = <FormattedMessage id='attachment.cantDelete' defaultMessage="Can't delete attachment" />
                const error = new Error(errorMessage);
                error.errorMessage = errorMessage;
                error.details = this.state.attachments.filter((attach) => attach.id === id);
                error.time = new Date();

                emitter.emit('addMessage', { type: 'failure', data: error });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }

    };

    attachmentsUploadHandler = (attachments) => {
        this.setState({
            attachments: this.state.attachments.concat(attachments),
        });
    };

    async componentDidMount() {
        try {
            const attachments = await fetcher.get('upload/otrs');
            this.setState({
                answerText: localStorage.getItem(`answerText.${this.props.tn}`),
                attachments,
                loading: false,
            });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ error: true });
        }

    };

    render() {
        const {
            answerText,
            attachments,
            loading,
            error,
            sending,
            shouldClose,
        } = this.state;
        return (
            <LoadingWrapper wrap={loading} error={error} >
                <Box p={3}>
                    <FormControl fullWidth>
                        <FileUploader type='otrs' onUpload={this.attachmentsUploadHandler}>
                            <TextField
                                multiline
                                autoFocus
                                label={<FormattedMessage id='ticket.answerLabel' defaultMessage='Answer' />}
                                rows={5}
                                rowsMax={15}
                                variant='outlined'
                                fullWidth
                                defaultValue={answerText}
                                onChange={this.getAnswerChangeHandler()}
                            />
                            <Attachments {...{ attachments }} deleteHandler={this.attachmentDeleteHandler} />
                        </FileUploader>
                        <Toolbar>
                            <Box mr={3}>
                                <ButtonWithProgress
                                    showProgress={sending && !shouldClose}
                                    color='primary'
                                    variant='contained'
                                    startIcon={<ReplyIcon />}
                                    disabled={!answerText || answerText === '' || sending}
                                    onClick={this.getReplyHandler()}
                                >
                                    <FormattedMessage id='ticket.replyButtonText' defaultMessage='Reply' />
                                </ButtonWithProgress>
                            </Box>
                            <Box>
                                <ButtonWithProgress
                                    showProgress={sending && shouldClose}
                                    disabled={sending}
                                    color='secondary'
                                    variant='contained'
                                    startIcon={<DoneIcon />}
                                    onClick={this.getCloseHandler()}
                                >
                                    {answerText && answerText !== '' ?
                                        <FormattedMessage id='ticket.replyAndCloseButtonText' defaultMessage='Reply and close ticket' />
                                        :
                                        <FormattedMessage id='ticket.closeButtonText' defaultMessage='Close ticket' />
                                    }
                                </ButtonWithProgress>
                            </Box>
                        </Toolbar>
                    </FormControl>
                </Box>
            </LoadingWrapper>
        );
    };
};

ReplyForm.propTypes = {
    tn: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

export default compose(
    withTheme,
)(ReplyForm)