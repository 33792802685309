import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Toolbar, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import { AddLicenseDialog, IptvLicense, PaymentSystemsLicense, Utm5License } from '.';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Licenses extends React.Component {
    state = {
        showAddDialog: false,
    };
    render() {
        const {
            licenses,
            reload,
        } = this.props;
        const {
            utm5_enterprise = [],
            utm5_telecom = [],
            utm5 = [],
            iptv = [],
            payment_systems = [],
            available,
        } = licenses;
        const {
            showAddDialog,
        } = this.state;

        return (
            <Grid item xs={12} xl={10}>
                { available && (
                    (Array.isArray(available.iptv) && available.iptv.length > 0) ||
                    (Array.isArray(available.utm5_enterprise) && available.utm5_enterprise.length > 0) ||
                    (Array.isArray(available.utm5_telecom) && available.utm5_telecom.length > 0)
                ) &&
                    <Toolbar>
                        <Button
                            variant='outlined'
                            startIcon={<AddIcon />}
                            onClick={() => this.setState({ showAddDialog: true })}
                        >
                            <FormattedMessage id='licenses.add' defaultMessage='Add license' />
                        </Button>
                        <AddLicenseDialog
                            open={showAddDialog}
                            onClose={() => this.setState({ showAddDialog: false })}
                            available={available}
                            reload={reload}
                        />
                    </Toolbar>
                }
                {!Object.values(licenses).reduce((result, value) => result || (Array.isArray(value) && value.length !== 0), false) ?
                    <Typography variant='body1' color='error' align='center'>
                        <FormattedMessage id='noLicenses' defaultMessage='No licenses' />
                    </Typography>
                    :
                    <React.Fragment>
                        {utm5_enterprise.map((license, index) => (
                            <Utm5License
                                key={index}
                                reload={reload}
                                {...license}
                            />
                        ))}
                        {utm5_telecom.map((license, index) => (
                            <Utm5License
                                key={index}
                                reload={reload}
                                {...license}
                            />
                        ))}
                        {iptv.map((license, index) => (
                            <IptvLicense
                                key={index}
                                {...license}
                            />
                        ))}
                        {((Array.isArray(utm5) && utm5.length !== 0) || (Array.isArray(payment_systems) && payment_systems.length !== 0)) ?
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <FormattedMessage id='licenses.deprecatedLicenses' defaultMessage='Deprecated licenses' />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box width='100%'>
                                        {utm5.map((license, index) => (
                                            <Utm5License
                                                key={index}
                                                reload={reload}
                                                {...license}
                                            />
                                        ))}
                                        {payment_systems ?
                                            <PaymentSystemsLicense
                                                {...payment_systems}
                                            />
                                            : null
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            : null
                        }
                    </React.Fragment>
                }
            </Grid>
        );
    }
}

export default Licenses;
