import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

const FileSizeLabel = ({ filename, size }) => {
    const gb = 1024 * 1024 * 1024;
    const mb = 1024 * 1024;
    const kb = 1024;

    if (size > gb) {
        size = <FormattedMessage
            id='values.gb' defaultMessage='{size} GB'
            values={{
                size: Math.round(size / gb * 100) / 100
            }}
        />
    } else if (size > mb) {
        size = <FormattedMessage
            id='values.mb' defaultMessage='{size} MB'
            values={{
                size: Math.round(size / mb * 100) / 100
            }}
        />
    } else if (size > kb) {
        size = <FormattedMessage
            id='values.kb' defaultMessage='{size} KB'
            values={{
                size: Math.round(size / kb * 100) / 100
            }}
        />
    } else {
        size = <FormattedMessage
            id='values.b' defaultMessage='{size} B'
            values={{
                size
            }}
        />
    }
    return filename ?
        <React.Fragment>
            <Typography>
                <React.Fragment>
                    {filename} ({size})
                    </React.Fragment>
            </Typography>
        </React.Fragment>
        : size;
};

FileSizeLabel.propTypes = {
    filename: PropTypes.string,
    size: PropTypes.number.isRequired,
};

export default FileSizeLabel;
