import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

class ControlledButton extends React.Component {
    render() {
        const {
            primary,
            icon,
            submit,
            children,
            ...restProps
        } = this.props;

        return (
            <Button
                color={primary ? 'primary' : 'secondary'}
                variant={primary ? 'contained' : 'outlined'}
                startIcon={icon}
                {...restProps}
            >
                {children}
            </Button>
        );
    };
};

ControlledButton.propTypes = {
    primary: PropTypes.bool,
    icon: PropTypes.element,
    submit: PropTypes.bool,
};

export default ControlledButton;