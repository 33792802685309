import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import compose from 'recompose/compose';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import PageLoadingIndicator from '../components/PageLoadingIndicator';
import { fetcher } from '../util/deps';
import { TicketHeader, Article, ArticleList, ReplyForm } from '../components/Ticket';
import emitter from '../emitter';
import ErrorWrapper from '../components/common/ErrorWrapper';

const styles = theme => ({
  root: {
    margin: 'auto',
    maxWidth: 1100,
  },
  mobileRoot: {
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    height: `calc(100vh - 56px)`,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: `calc(100vh - 48px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - 64px)`,
    },
  },
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      display: 'none',
    },
    '&:hover .hover-button': {
      display: 'inherit',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
});

class Ticket extends React.Component {
  state = {
    loading: true,
    error: false,
  };

  getTicket = async () => {
    try {
      this.setState({ loading: true });
      this.setState({
        ticket: await fetcher.get('ticket/' + this.props.match.params.tn),
        loading: false
      });
    } catch (e) {
      emitter.emit('addMessage', { type: 'failure', data: e });
      this.setState({ error: true });
    }
  };

  async componentDidMount() {
    this.getTicket();
  };

  render() {
    const {
      classes,
      width,
    } = this.props;

    const {
      ticket,
      loading,
      error,
    } = this.state;

    const xs = width === 'xs';
    const mdUp = !xs && width !== 'sm';
    const mobile = !mdUp || isMobile;

    return (
      <div className={classnames(classes.root, mobile ? classes.mobileRoot : '')}>
        <ErrorWrapper wrap={error}>
          {loading ?
            <PageLoadingIndicator open />
            :
            <Paper className={classes.paper}>
              <TicketHeader {...ticket} />
              <Article {...ticket.first_article} />
              <ArticleList articles={ticket.articles} />
              {ticket.last_article ? <Article {...ticket.last_article} /> : null}
              {ticket.can_answer ? <ReplyForm tn={ticket.tn} onSubmit={this.getTicket} /> : null}
            </Paper>
          }
        </ErrorWrapper>
      </div>
    );
  }
}

Ticket.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string.isRequired,
      tn: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired
};

export default connect(Ticket.mapStateToProps)(
  compose(
    withStyles(styles),
    withWidth(),
    injectIntl,
  )(Ticket),
);