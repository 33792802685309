import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { ControlledButton, ControlledSwitch, ControlledTextField, ControlledToolbar, ControllingForm } from '../../Form';
import PersonIcon from '@material-ui/icons/Person';
import ReplyIcon from '@material-ui/icons/Reply';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';

class AgentReplyForm extends React.Component {
    ticketReplyHandler = (type) => async (data) => {
        const { customerId, tn, onSubmit } = this.props;
        try {
            const result = await fetcher.post(`staff/ticket/${tn}`, {customer_id: customerId, type, ...data});
            if (result) {
                if (typeof onSubmit === 'function') {
                    await onSubmit();
                }
                return true;
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const {
            helper,
            closed,
        } = this.props;

        return (
            <ControllingForm name='agentReply' padding={2} md={12} lg={12} xl={12}>
                {helper ?
                    <Box m={1}>
                        <Typography variant='body1'>
                            {helper}
                        </Typography>
                    </Box>
                    : null
                }
                < ControlledTextField
                    name='text'
                    label={<FormattedMessage id='ticket.replyText' defaultMessage='Reply Text' />}
                    multiline
                    required
                    rows={5}
                    rowsMax={15}
                    autoFocus
                    value=''
                />
                {closed ?
                    <ControlledSwitch
                        name='reopen'
                        label={<FormattedMessage id='ticket.reopen' defaultMessage='Reopen' />}
                        checked
                    />
                    :
                    <ControlledSwitch
                        name='close'
                        label={<FormattedMessage id='ticket.close' defaultMessage='Close' />}
                    />
                }
                <ControlledToolbar>
                    <ControlledButton
                        isSubmitButton
                        icon={<PersonIcon />}
                        onClick={this.ticketReplyHandler('phone')}
                    >
                        <FormattedMessage id='call.customer.replyCustomer' defaultMessage='Reply as customer' />
                    </ControlledButton>
                    <ControlledButton
                        isSubmitButton
                        icon={<ReplyIcon />}
                        onClick={this.ticketReplyHandler('agent')}
                    >
                        <FormattedMessage id='call.customer.replyAgent' defaultMessage='Reply as agent' />
                    </ControlledButton>
                    <ControlledButton
                        isSubmitButton
                        icon={<NoteAddIcon />}
                        onClick={this.ticketReplyHandler('note')}
                    >
                        <FormattedMessage id='call.customer.addNote' defaultMessage='Add note' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

AgentReplyForm.propTypes = {
    customerId: PropTypes.number,
    tn: PropTypes.string,
    closed: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    onSubmit: PropTypes.func,
};

export default AgentReplyForm;