import { ADD_ERROR, REMOVE_ERROR, RECOVER_ERROR, CLEAR_ERRORS } from '../constants';

const MAX_ERRORS = 42;

const errors = (errors = [], action) => {
  switch (action.type) {
    case ADD_ERROR: {
      const newErrors = [action.payload, ...errors].slice(0, MAX_ERRORS);
      localStorage.setItem('systemInterfaceErrors', JSON.stringify(newErrors));
      return newErrors;
    }
    case REMOVE_ERROR: {
      const newErrors = errors.filter(e => e !== action.payload);
      newErrors.lastRemoved = action.payload;
      localStorage.setItem('systemInterfaceErrors', JSON.stringify(newErrors));
      return newErrors;
    }
    case RECOVER_ERROR: {
      const newErrors = [...errors, errors.lastRemoved];
      localStorage.setItem('systemInterfaceErrors', JSON.stringify(newErrors));
      return newErrors;
    }
    case CLEAR_ERRORS: {
      localStorage.removeItem('systemInterfaceErrors');
      return [];
    }
    default:
      return errors;
  }
};

export default errors;