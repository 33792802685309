import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { FormattedMessage } from 'react-intl';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ButtonWithProgress from './ButtonWithProgress';
import ContentCopy from './customIcons/ContentCopy';
import classNames from 'classnames';

import Transition from './util/DialogTransition';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1) * 2,
  },
  button: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onCopy } = props;
  return (
    <MuiDialogTitle disableTypography className={onCopy ? classes.root : ''}>
      <Typography variant="h6">{children}</Typography>
      {onCopy && (
        <IconButton className={classes.button} onClick={onCopy}>
          <ContentCopy />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  appBarColored: {
    backgroundColor: theme.palette.appBarBackgroundColor,
  },
  toolbar: {
    padding: `0 ${theme.spacing(1) * 1.5}px`,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    height: theme.spacing(1) * 3,
  },
  toolBarTypography: {
    flex: 1,
  },
  dialogActions: {
    color: theme.palette.primary.main,
  },
  disablePaddingForMobile: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    willChange: 'transform',
  },
});

/** If we want to add spinner, we should not set 'open' value to false in onConfirm handler */

class ResponsiveDialog extends React.Component {
  state = { spinnerIsActive: false };

  componentDidUpdate(prevProps) {
    if (this.props.withSpinner) {
      if (prevProps.pendingBackendEvent && !this.props.pendingBackendEvent) {
        if (!this.props.dontVanish) {
          this.props.onClose();
        }
        this.setState({ spinnerIsActive: false });
      }
    }
  }

  handleConfirm = event => {
    event.stopPropagation();
    this.props.onConfirm();
    if (this.props.withSpinner) {
      this.setState({ spinnerIsActive: true });
    } else if (this.props.closeOnConfirm) {
      this.props.onClose();
    }
  };

  render() {
    const {
      buttons,
      leftButtons,
      onClose,
      onCopy,
      onExited,
      title,
      noTitle,
      message,
      children,
      fullScreen,
      open,
      confirmButtonText,
      closeMessage,
      classes,
      disablePaddingForMobile,
      fullWidth,
      ConfirmIcon,
      maxWidth = 'sm',
      colored,
      disableButtons,
      withoutConfirm,
      disableConfirm,
    } = this.props;

    if (!open) {
      return null;
    }
    
    return (
      <Dialog
        open={open}
        onClose={this.state.spinnerIsActive ? null : onClose}
        fullScreen={fullScreen}
        TransitionComponent={fullScreen ? Transition : undefined}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onExited={onExited}
      >
        {fullScreen && !noTitle && (
          <AppBar className={classNames(classes.appBar, colored && classes.appBarColored)}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                onClick={onClose}
                disabled={this.state.spinnerIsActive}
                aria-label="Close"
                className={classes.cancelButton}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.toolBarTypography}>
                {title}
              </Typography>
              {!disableButtons && !withoutConfirm && !buttons && (
                <ButtonWithProgress showProgress={this.state.spinnerIsActive || this.props.forceSpinner}>
                  <IconButton
                    color="inherit"
                    onClick={this.handleConfirm}
                    disabled={this.state.spinnerIsActive || disableConfirm}
                    aria-label="Ok"
                  >
                    {ConfirmIcon ? <ConfirmIcon /> : <CheckIcon />}
                  </IconButton>
                </ButtonWithProgress>
              )}
              {buttons}
              {onCopy && (
                <IconButton color="inherit" onClick={onCopy}>
                  <ContentCopy />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        )}

        {fullScreen && <div className={classes.spacer} />}
        {!fullScreen && !noTitle && <DialogTitle onCopy={onCopy}>{title}</DialogTitle>}

        <DialogContent
          className={classNames(disablePaddingForMobile && classes.disablePaddingForMobile, classes.dialogContent)}
        >
          {message && <DialogContentText component='div' className={classes.text}>{message}</DialogContentText>}
          {children}
        </DialogContent>

        {!fullScreen && !disableButtons && (
          <DialogActions className={classNames(classes.dialogActions, leftButtons ? classes.buttonsContainer : '')}>
            {leftButtons}
            {buttons && <div>{buttons}</div>}
            {!buttons && (
              <Button
                onClick={onClose}
                disabled={this.state.spinnerIsActive}
                color='secondary'
                variant='outlined'
              >
                {closeMessage || <FormattedMessage id="ResponsiveDialog.cancel" defaultMessage="Cancel" />}
              </Button>
            )}

            {!withoutConfirm && !buttons && (
              <ButtonWithProgress
                showProgress={this.state.spinnerIsActive || this.props.forceSpinner}
                onClick={this.handleConfirm}
                disabled={this.state.spinnerIsActive || disableConfirm}
                color="primary"
                variant='contained'
                autoFocus
              >
                {confirmButtonText}
              </ButtonWithProgress>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

ResponsiveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
};

export default compose(
  withStyles(styles),
  withMobileDialog(),
)(ResponsiveDialog);
