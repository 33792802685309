import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const ref = React.createRef();

class Captcha extends React.Component {
    static controlType = 'captcha';
    reset = () => ref.current.reset();
    
    render = () => (
        <ReCAPTCHA
            ref={ref}
            sitekey='6LeIB-0SAAAAADXB7ARn3inSd7J_niUR0kOlZlzY'
            {...this.props} />
    );
};

export default Captcha;