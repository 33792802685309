import EventEmitter from 'events';
import React from 'react';
import { FormattedMessage } from 'react-intl';

class Uploader extends EventEmitter {
  static deps = { prefix: new URL('backend', window.location).pathname };

  constructor(files, type) {
    super();
    this.files = files;
    this.type = type;
  }

  upload() {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(this.files) || this.files.length === 0) {
        resolve(false);
        return;
      }
      this.xhr = new XMLHttpRequest();

      const uri = `${Uploader.deps.prefix}/upload/${this.type}`;

      this.xhr.open('POST', uri, true);

      this.xhr.addEventListener('loadend', () => {
        this.emit('end');

        if (this.didAbort) return;

        let xhr = this.xhr;
        let contentTypeStr;
        try {
          contentTypeStr = xhr.getResponseHeader('content-type');
        } catch (e) { }

        if (xhr.status >= 200 && xhr.status < 300) {
          let isJson = contentTypeStr && contentTypeStr.includes('application/json');
          let res;
          if (isJson) {
            res = JSON.parse(xhr.response);
          } else {
            res = xhr.response;
          }
          resolve(res.data || res);
        } else {
          let error = parseErrorResponse(xhr.response, contentTypeStr || '', uri, xhr.status);
          reject(error);
        }
      });

      this.xhr.upload.onprogress = evt => {
        this.emit('progress', {
          percentage: evt.lengthComputable ? (evt.loaded / evt.total) * 100 : 0,
        });
      };

      const formData = new FormData();
      this.files.forEach((file, index) => {
        formData.append(index, file);
      })

      this.xhr.send(formData);
    });
  }

  stop() {
    this.didAbort = true;
    this.xhr.abort();
  }
}

function parseErrorResponse(res, contentTypeStr, uri, statusCode) {
  let errorMessage;
  let errorDetails;
  const unknownError = (
    <FormattedMessage
      id="error.UNKNOWN_UPLOAD_ERROR"
      defaultMessage="error.UNKNOWN_UPLOAD_ERROR"
    />
  );


  if (contentTypeStr.includes('application/json')) {
    try {
      let res_ = JSON.parse(res);
      errorMessage = res_.message || res_.type || res_;
      errorDetails = res_;
    } catch (e) {
      console.error(e);
      errorMessage = res || unknownError;
    }
  } else if (contentTypeStr.includes('text/html')) {
    let res_ = res;

    const matches = res_.match(/<title>(.*?)<\/title>/);
    errorMessage = matches[1].trim();
  } else {
    // possibly will need a case for text/plain
    errorMessage = res || unknownError;
  }

  const error = new Error(errorMessage);
  error.errorMessage = errorMessage;
  error.details = errorDetails;
  error.contentType = contentTypeStr;
  error.time = new Date();
  error.agent = 'uploader';
  error.uri = uri;
  error.statusCode = statusCode;

  return error;
}

export default Uploader;
