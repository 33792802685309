import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import emitter from './emitter';

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root'),
);

if (process && process.env && process.env.NODE_ENV === 'production') {
  fetch('/revision', { cache: 'reload' }).then(async response => {
    if (response.ok) {
      const revision = await response.text();
      if (revision.trim() !== process.env.REACT_APP_REVISION) {
        emitter.emit('addMessage', { isReload: true });
      }
    }
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
