import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import FileSizeLabel from './FileSizeLabel';

const FileChip = ({ icon, filename, filesize, onClick, clickable, deletable, onDelete }) => (
    <Chip
        label={<FileSizeLabel filename={filename} size={filesize} />}
        {...{icon, onClick, clickable, onDelete, deletable}}

    />
);

FileChip.propTypes = {
    icon: PropTypes.element.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    deletable: PropTypes.bool,
    onDelete: PropTypes.func,
}

export default FileChip;
