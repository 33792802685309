import { MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NewIptvLicenseForm, NewUtm5LicenseForm } from '.';
import emitter from '../../emitter';
import { fetcher } from '../../util/deps';
import { ControlledTextField, ControllingForm } from '../Form';
import ResponsiveDialog from '../ResponsiveDialog';

class AddLicenseDialog extends React.Component {
    state = {
        sending: false,
        data: {},
        canSubmit: false,
    };

    handleAddLicense = () => {
        const {
            data: {
                type,
                license: {
                    data: license,
                }
            }
        } = this.state;
        const { reload } = this.props;
        this.setState({ sending: true }, async () => {
            try {
                switch (type) {
                    case 'enterprise':
                    case 'telecom':
                        const { available } = this.props;
                        if (!available || !available.utm5 || !Array.isArray(available.utm5.core) || available.utm5.core.length === 0) {
                            const e = new Error('no core license');
                            e.errorMessage = 'no core license';
                            e.time = new Date();
                            throw e;
                        }
                        const core_license = available.utm5.core[available.utm5.core.length - 1].id;
                        await fetcher.put(`license/${type}`, { ...license, core_license });
                        break;
                    default:
                        await fetcher.put(`license/${type}`, license);
                        break;
                }
                if (typeof reload === 'function') {
                    await reload();
                }
                // clear stored form data on success
                localStorage.removeItem('addLicense.type')
                const storagePrefix = type === 'iptv' ? NewIptvLicenseForm.formName : NewUtm5LicenseForm.formName;
                for (const key in license) {
                    localStorage.removeItem(`${storagePrefix}.${key}`);
                }        
            } catch (e) {
                emitter.emit('addMessage', { type: 'failure', data: e });
            }
            this.setState({ sending: false });
        });
    }

    handelClose = (_, reason) => {
        if (reason === 'backdropClick') return;
        this.props.onClose();
    }

    render() {
        const {
            open,
            available,
        } = this.props;
        const {
            canSubmit,
            sending,
            data: {
                type,
            }
        } = this.state;

        const iptvAvailable = available && Array.isArray(available.iptv) && available.iptv.length > 0;
        const coreAvailable = available && available.utm5 && Array.isArray(available.utm5.core) && available.utm5.core.length > 0;
        const enterpriseAvailable = available && coreAvailable && Array.isArray(available.utm5_enterprise) && available.utm5_enterprise.length > 0;
        const telecomAvailable = available && coreAvailable && Array.isArray(available.utm5_telecom) && available.utm5_telecom.length > 0;
        const defaultLicenseType = iptvAvailable ? 'iptv' : (enterpriseAvailable ? 'enterprise' : 'telecom');
        return (
            <ResponsiveDialog
                open={open}
                title={<FormattedMessage id='addLicense.DialogTitle' defaultMessage='Add license' />}
                message={
                    !iptvAvailable && !enterpriseAvailable && !telecomAvailable ?
                        <Typography color='error'>
                            <FormattedMessage id='addLicense' defaultMessage='No new licenses available' />
                        </Typography>
                        :
                        <ControllingForm
                            fullWidth
                            flat
                            slim
                            name='addLicense'
                            onUpdate={(data) => this.setState(data)}
                        >
                            <ControlledTextField
                                name='type'
                                required
                                fullWidth
                                label={<FormattedMessage id='addLicense.type' defaultMessage='Type' />}
                                select
                                value={defaultLicenseType}
                            >
                                {iptvAvailable &&
                                    <MenuItem value='iptv'>
                                        <FormattedMessage id='addLicense.iptv' defaultMessage='IPTV' />
                                    </MenuItem>
                                }
                                {enterpriseAvailable &&
                                    <MenuItem value='enterprise'>
                                        <FormattedMessage id='addLicense.enterprise' defaultMessage='UTM5+ Enterprise' />
                                    </MenuItem>
                                }
                                {telecomAvailable &&
                                    <MenuItem value='telecom'>
                                        <FormattedMessage id='addLicense.telecom' defaultMessage='UTM5+ Telecom' />
                                    </MenuItem>
                                }
                            </ControlledTextField>
                            {(type === 'enterprise' || type === 'telecom') &&
                                <NewUtm5LicenseForm
                                    name='license'
                                    core={available.utm5.core}
                                    module={(type === 'enterprise' && available.utm5_enterprise) || (type === 'telecom' && available.utm5_telecom)}
                                />
                            }
                            {type === 'iptv' &&
                                <NewIptvLicenseForm
                                    name='license'
                                    licenses={available.iptv}
                                />
                            }
                        </ControllingForm>
                }
                fullWidth
                confirmButtonText={<FormattedMessage id='addLicense.add' defaultMessage='Add' />}
                closeMessage={<FormattedMessage id='addLicense.cancel' defaultMessage='Cancel' />}
                onClose={this.handelClose}
                disableConfirm={!canSubmit}
                withSpinner
                onConfirm={this.handleAddLicense}
                pendingBackendEvent={sending}
            />

        );
    };
};

export default AddLicenseDialog;