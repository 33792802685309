import React from 'react';
import ResponsiveDialog from '../ResponsiveDialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import copy from 'copy-to-clipboard';
import { isMobile } from 'react-device-detect';

import { getErrorMessage } from '../../util/getErrorMessage';
import ContentCopy from '../customIcons/ContentCopy';
import emitter from '../../emitter';

const styles = theme => ({
  moduleContainer: {
    display: 'grid',

    gridTemplateColumns: '146px 1fr auto',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '72px 1fr auto',
    },

    gridAutoFlow: 'column',
    gridGap: '10px',
    // '& > div': { alignSelf: 'center' },
    paddingLeft: '12px',
    borderBottom: `1px solid ${theme.palette.borderColor}`,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  textPadding: {
    padding: '12px 0',
  },
  expand: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  moduleDetails: {
    backgroundColor: theme.palette.detailsTable.background,
    borderTop: `1px solid ${theme.palette.detailsTable.border}`,
    borderBottom: `1px solid ${theme.palette.detailsTable.border}`,
    marginBottom: '8px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  detailsContainer: {
    padding: '8px 0',
    '& pre': {
      whiteSpace: 'pre-line',
    },
  },
  // might need this later
  moduleDetailsTable: {
    tableLayout: 'fixed',
    '& td:first-child': {
      width: '100px',
    },
  },

  table: {
    tableLayout: 'fixed',
    '& td': {
      overflowWrap: 'break-word',
      wordBreak: 'break-word',

      [theme.breakpoints.only('xs')]: {
        '&:nth-child(1)': {
          width: '60px',
        },
        '&:last-child': {
          width: '52px',
        },
      },
    },
  },
  tableRow: {
    '& .copy-button': {
      visibility: 'hidden',
    },
    '&:hover .copy-button': {
      visibility: 'inherit',
    },
  },
});

class ErrorDetails extends React.Component {
  handleCopy = content => () => {
    copy(content);
    emitter.emit('addMessage', {
      type: 'success',
      data: <FormattedMessage id="errorDetails.copiedToClipboard" defaultMessage="Copied to clipboard" />,
    });
  };

  render() {
    const { classes, intl, width, error, open, onClose } = this.props;

    const xs = width === 'xs';
    const mdUp = width !== 'xs' && width !== 'sm';
    const mobile = !mdUp || isMobile;

    const { errorMessage, details, time, contentType = '', location, uri, statusCode } = error || {};
    const timeString = intl.formatDate(time || null, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    let errorMessage_ = getErrorMessage(errorMessage);
    let errorMessage_copyable;
    if (errorMessage_) {
      errorMessage_copyable = errorMessage_.props ? intl.formatMessage({ ...errorMessage_.props }) : errorMessage_;
    }

    let details_;
    let details_copyableString;
    if (details) {
      if (contentType.includes('application/json')) {
        details_ = details.extra && JSON.stringify(details.extra);
        details_copyableString = details_;
      } else if (contentType.includes('text/html')) {
        details_ = <div dangerouslySetInnerHTML={{ __html: details }} />;
        details_copyableString = details;
      } else {
        details_ = <pre dangerouslySetInnerHTML={{ __html: details }} />;
        details_copyableString = details;
      }
    }

    const detailsTable = (
      <Table padding="checkbox" className={classes.table}>
        {!xs && (
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '65%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
        )}

        <TableBody>
          <TableRow hover className={classes.tableRow}>
            <TableCell>
              <FormattedMessage id="errorDetailsDialog.time" defaultMessage="Time" />
            </TableCell>
            <TableCell>{timeString}</TableCell>
            <TableCell padding="none" align="right">
              <IconButton onClick={this.handleCopy(timeString)}>
                <ContentCopy className={mobile ? '' : 'copy-button'} />
              </IconButton>
            </TableCell>
          </TableRow>

          <TableRow hover className={classes.tableRow}>
            <TableCell>
              <FormattedMessage id="errorDetailsDialog.message" defaultMessage="Message" />
            </TableCell>
            <TableCell>{errorMessage_}</TableCell>
            <TableCell padding="none" align="right">
              <IconButton onClick={this.handleCopy(errorMessage_copyable)}>
                <ContentCopy className={mobile ? '' : 'copy-button'} />
              </IconButton>
            </TableCell>
          </TableRow>

          {details_ && (
            <TableRow hover className={classes.tableRow}>
              <TableCell>
                <FormattedMessage id="errorDetailsDialog.details" defaultMessage="Details" />
              </TableCell>
              <TableCell>
                <div className={classes.detailsContainer}>{details_}</div>
              </TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleCopy(details_copyableString)}>
                  <ContentCopy className={mobile ? '' : 'copy-button'} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}

          {location && (
            <TableRow hover className={classes.tableRow}>
              <TableCell>
                <FormattedMessage id="errorDetailsDialog.location" defaultMessage="Location" />
              </TableCell>
              <TableCell>{location}</TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleCopy(location)}>
                  <ContentCopy className={mobile ? '' : 'copy-button'} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}

          {uri && (
            <TableRow hover className={classes.tableRow}>
              <TableCell>
                <FormattedMessage id="errorDetailsDialog.uri" defaultMessage="URI" />
              </TableCell>
              <TableCell>{uri}</TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleCopy(uri)}>
                  <ContentCopy className={mobile ? '' : 'copy-button'} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}

          {statusCode && (
            <TableRow hover className={classes.tableRow}>
              <TableCell>
                <FormattedMessage id="errorDetailsDialog.statusCode" defaultMessage="Status code" />
              </TableCell>
              <TableCell>{statusCode}</TableCell>
              <TableCell padding="none" align="right">
                <IconButton onClick={this.handleCopy(statusCode)}>
                  <ContentCopy className={mobile ? '' : 'copy-button'} />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );

    return (
      <ResponsiveDialog
        open={!!open}
        title={<FormattedMessage id="errorDetailsDialog.title.Error details" defaultMessage="Error details" />}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        disableButtons
      >
        {detailsTable}
      </ResponsiveDialog>
    );
  }
}

export default compose(
  withStyles(styles),
  withWidth(),
  injectIntl,
)(ErrorDetails);
