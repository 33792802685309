import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ControlledTextField, ControllingForm, ControlledToolbar, ControlledButton, ControlledSwitch } from '../components/Form';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import store from '../util/store';
import { authActions } from '../redux-stuff/actions';
import { withRouter } from 'react-router';
import LoadingWrapper from '../components/common/LoadingWrapper';
import { Typography } from '@material-ui/core';

class Totp extends React.Component {
    state = {
        loading: true,
        haveTotp: undefined,
        qr: undefined,
        badCode: false,
    };

    submitHandler = async data => {
        const { haveTotp } = this.state;
        try {
            const result = await fetcher.post('totp', { ...data, update: !haveTotp });
            if (result) {
                const { authorized, username, session_id: sessionId, uid, is_staff: isStaff } = await fetcher.get('auth');
                if (authorized && data.save_browser) {
                    localStorage.setItem('browser_secret', result);
                }
                store.dispatch(authActions.setIsAuth(authorized));
                store.dispatch(authActions.setAuth({ authorized, username, sessionId, uid, isStaff }));
                const { referrer = '/' } = this.props.location.state || {};
                this.props.history.push(referrer);
                this.props.history.push(`/`);
                return true;
            }
        } catch (e) {
            if (e.statusCode === 403) {
                this.setState({ badCode: true });
            } else {
                emitter.emit('addMessage', { type: 'failure', data: e });
            }
        }
    }

    async componentDidMount() {
        try {
            const { have_totp: haveTotp } = await fetcher.get('auth');
            let qr = undefined;
            if (!haveTotp) {
                qr = await fetcher.get('totp');
            }
            this.setState({ loading: false, haveTotp, qr });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const {
            loading,
            haveTotp,
            qr,
            badCode,
        } = this.state;
        return (
            <LoadingWrapper wrap={loading}>
                <ControllingForm name='TOTP' onSubmit={this.submitHandler}>
                    {haveTotp ?
                        <Typography variant='h6'>
                            <FormattedMessage
                                id='TOTP.enterCode'
                                defaultMessage='Enter the confirmation code from the app'
                            />
                        </Typography>
                        :
                        <React.Fragment>
                            <Typography variant='h5'>
                                <FormattedMessage
                                    id='TOTP.enableTOTP'
                                    defaultMessage='You have to enable 2-factor authentification to proceed.'
                                />
                            </Typography>
                            <Typography variant='body1'>
                                <FormattedMessage
                                    id='TOTP.apps'
                                    defaultMessage='Use any TOTP code-generating app, for example, Google Authenticator.'
                                />
                            </Typography>
                            <Typography variant='body1'>
                                <FormattedMessage
                                    id='TOTP.scanCode'
                                    defaultMessage='Scan the QR code in this app.'
                                />
                                {' '}
                                <FormattedMessage
                                    id='TOTP.enterCodeToConfirm'
                                    defaultMessage='Then, to confirm that the app has been set up correctly, enter the confirmation code from the app.'
                                />
                            </Typography>
                            <img src={qr} alt='qr code' />
                        </React.Fragment>
                    }
                    {badCode && (
                        <Typography color='error'>
                            <FormattedMessage id='TOTP.badCode' defaultMessage='Bad code' />
                        </Typography>
                    )}
                    <ControlledTextField
                        name='code'
                        label={<FormattedMessage id='TOTP.code' defaultMessage='Code' />}
                        required
                        autoFocus
                        autoComplete='off'
                        maxLength={6}
                    />
                    <ControlledSwitch
                        name='save_browser'
                        label={<FormattedMessage id='TOTP.trustThisBrowser' defaultMessage='Trust this browser' />}
                        checked
                    />
                    <ControlledToolbar>
                        <ControlledButton
                            isSubmitButton
                            primary
                            icon={<NavigateNextIcon />}
                            onClick={this.submitHandler}
                        >
                            <FormattedMessage id='TOTP.proceed' defaultMessage='Proceed' />
                        </ControlledButton>
                        <ControlledButton
                            icon={<ExitToAppIcon />}
                            onClick={() => {
                                store.dispatch(authActions.setAuth({ isStaff: undefined }));
                                store.dispatch(authActions.setIsAuth(false));
                                fetcher.post('logout', undefined, false);
                                this.props.history.push('/login');
                                return true;
                            }}
                        >
                            <FormattedMessage id='TOTP.logout' defaultMessage='Logout' />
                        </ControlledButton>
                    </ControlledToolbar>
                </ControllingForm>
            </LoadingWrapper>
        );
    };
};

export default withRouter(Totp);