import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { ControlledButton, ControlledTextField, ControlledToolbar, ControllingForm } from '../../Form';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import { fetcher } from '../../../util/deps';
import emitter from '../../../emitter';
import LoadingWrapper from '../../common/LoadingWrapper';
import { withRouter } from 'react-router';
import Localize from '../../common/Localize';

class AgentCreateTicket extends React.Component {
    state = {
        loading: true,
        types: [],
    }

    ticketCreate = async (data) => {
        const { customerId, callId, onSubmit } = this.props;
        try {
            const tn = await fetcher.post(`staff/ticket/create`, { customer_id: customerId, article_type: 'phone', ...data });
            if (tn) {
                await fetcher.post(`staff/call/${callId}/ticket`, { call_id: callId, tn });
                if (typeof onSubmit === 'function') {
                    await onSubmit();
                }
                this.props.history.push(`/call/${callId}`);
                return true;
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    async componentDidMount() {
        const { customerId } = this.props;
        try {
            const result = await fetcher.get(`staff/ticket/${customerId}/types`);
            if (result) {
                const { active } = result;
                const types = active.map(({ name }) => name);
                this.setState({
                    types,
                    loading: false,
                })
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => {
        const {
            loading,
            types,
        } = this.state;
        const {
            helper,
            callId,
        } = this.props;

        if (!loading && types.length === 0) {
            return (
                <ControllingForm name='agentCreateTicket' padding={1} md={12} lg={8}>
                    <Box m={1}>
                        <Typography color='error'>
                            <FormattedMessage id='ticket.create.noTypesAvailable' defaultMessage='No ticket types available' />
                        </Typography>
                    </Box>
                    <ControlledToolbar>
                        <ControlledButton
                            icon={<CancelIcon />}
                            onClick={() => {
                                this.props.history.push(`/call/${callId}`);
                                return true;
                            }}
                        >
                            <FormattedMessage id='call.cancel' defaultMessage='Cancel' />
                        </ControlledButton>
                    </ControlledToolbar>
                </ControllingForm>
            );
        }

        return (
            <LoadingWrapper wrap={loading}>
                <ControllingForm name='agentCreateTicket' padding={1} md={12} lg={8}>
                    {helper ?
                        <Box m={1}>
                            <Typography variant='body1'>
                                {helper}
                            </Typography>
                        </Box>
                        : null
                    }
                    <ControlledTextField
                        name='type'
                        label={<FormattedMessage id='ticket.ticketType' defaultMessage='Ticket type' />}
                        required
                        select
                        value={types[0]}
                    >
                        {types.map(type => (
                            <MenuItem key={type} value={type}>
                                <Localize addProductType>
                                    {type}
                                </Localize>
                            </MenuItem>
                        ))}
                    </ControlledTextField>
                    <ControlledTextField
                        name='subject'
                        label={<FormattedMessage id='ticket.ticketSubject' defaultMessage='Ticket subject' />}
                        required
                        autoFocus
                        value=''
                    />
                    <ControlledTextField
                        name='text'
                        label={<FormattedMessage id='ticket.ticketText' defaultMessage='Ticket text' />}
                        multiline
                        required
                        rows={5}
                        rowsMax={15}
                        value=''
                    />
                    <ControlledToolbar>
                        <ControlledButton
                            primary
                            isSubmitButton
                            icon={<CreateIcon />}
                            onClick={this.ticketCreate}
                        >
                            <FormattedMessage id='ticket.createTicket' defaultMessage='Create ticket' />
                        </ControlledButton>
                        <ControlledButton
                            icon={<CancelIcon />}
                            onClick={() => {
                                this.props.history.push(`/call/${callId}`);
                                return true;
                            }}
                        >
                            <FormattedMessage id='call.cancel' defaultMessage='Cancel' />
                        </ControlledButton>
                    </ControlledToolbar>
                </ControllingForm>
            </LoadingWrapper>
        );
    }
}

export default withRouter(AgentCreateTicket);