import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withTheme } from '@material-ui/core/styles';
import { Toolbar, Typography, CircularProgress, Box } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { FormattedMessage } from 'react-intl';
import { fetcher } from '../../util/deps';
import FileChip from '../FileChip';
import emitter from '../../emitter';

class Attachments extends React.Component {
    state = {
        downloading: false,
        processingFilename: null,
        downloadProgress: null,
        deleting: false,
    };

    downloadAttachment = (id, filename) => async () => {
        if (this.state.downloading || this.state.deleting) {
            return false;
        }

        this.setState({
            downloading: true,
            processingFilename: filename,
            downloadProgress: 0,
        });
        try {
            await fetcher.get(`attachment/${id}`, null, true, (progress) => {
                this.setState({
                    downloadProgress: progress * 100,
                })
            });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
        this.setState({
            downloading: false,
            processingFilename: null,
            downloadProgress: null,
        });
    }

    deleteAttachment = (id, filename) => async () => {
        if (this.state.downloading || this.state.deleting) {
            return false;
        }
        this.setState({
            deleting: true,
            processingFilename: filename,
        });
        //Handler returns true if component will umounted
        if (!await this.props.deleteHandler(id)) {
            this.setState({ deleting: false });
        }
    };

    render() {
        const { attachments, theme, deleteHandler } = this.props
        const { downloadProgress, processingFilename, downloading, deleting } = this.state;
        if (!Array.isArray(attachments) || attachments.length === 0) {
            return null;
        }
        return (
            <Box>
                <Box>
                    {attachments.map((attachment, index) => {
                        const { id, filename, content_size } = attachment;
                        const deleteble = !!deleteHandler && !this.state.downloading && !this.state.deleting;
                        const onDelete = deleteble ? this.deleteAttachment(id, filename) : null;
                        return (
                            <FileChip
                                key={index}
                                icon={<AttachmentIcon />}
                                filename={filename}
                                filesize={content_size}
                                onClick={this.downloadAttachment(id, filename)}
                                clickable={!this.state.downloading && !this.state.deleting}
                                onDelete={onDelete}
                                deleteble={deleteble}
                            />
                        );
                    })}
                </Box>
                {downloading || deleting ?
                    <Toolbar variant='dense'>
                        <Box m={1}>
                            <Typography variant='body1' color='textSecondary'>
                                {deleting ?
                                    <FormattedMessage
                                        id='attachment.deleting'
                                        defaultMessage='Deleting: {filename}'
                                        values={{ filename: processingFilename }}
                                    />

                                    :
                                    (downloadProgress === 0 ?
                                        <FormattedMessage
                                            id='attachment.preparing'
                                            defaultMessage='Preparing: {filename}'
                                            values={{ filename: processingFilename }}
                                        />
                                        :
                                        <FormattedMessage
                                            id='attachment.downloading'
                                            defaultMessage='Downloading: {filename}'
                                            values={{ filename: processingFilename }}
                                        />
                                    )
                                }
                            </Typography>
                        </Box>
                        <Box m={1}>
                            <CircularProgress
                                value={downloadProgress}
                                variant={downloadProgress === 0 || deleting ? 'indeterminate' : 'determinate'}
                                size={theme.typography.h5.fontSize}
                                color='secondary'
                            />
                        </Box>
                    </Toolbar>
                    : null
                }
            </Box>

        );
    };
};

Attachments.propTypes = {
    attachments: PropTypes.array.isRequired,
    deleteHandler: PropTypes.func,
}

export default compose(
    withTheme,
)(Attachments)