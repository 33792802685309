import React from 'react';
import { FormattedMessage } from 'react-intl';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CancelIcon from '@material-ui/icons/Cancel';
import ControllingForm from '../components/Form/ControllingForm';
import { ControlledTextField, ControlledToolbar, ControlledButton, Captcha } from '../components/Form';
import { emailValidator } from '../util/validators';
import { fetcher } from '../util/deps';
import emitter from '../emitter';

class PasswordRecovery extends React.Component {
    submitHandler = async (data) => {
        try {
            const result = await fetcher.post('password-reset', data);
            const encodedEmail = encodeURIComponent(data.email);
            if (result) {
                this.props.history.push(`/password-recovery/verify/${encodedEmail}`);
                return true;
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
    }

    render = () => (
        <ControllingForm name='passwordRecovery'>
            <ControlledTextField
                name='email'
                label={<FormattedMessage id='passwordRecovery.email' defaultMessage='E-mail' />}
                required
                autoFocus
                autoComplete='email'
                validator={emailValidator} />
            <Captcha />
            <ControlledToolbar>
                <ControlledButton
                    isSubmitButton
                    primary
                    icon={<RotateLeftIcon />}
                    onClick={this.submitHandler}
                >
                    <FormattedMessage id='passwordRecovery.submit' defaultMessage='Recover password' />
                </ControlledButton>
                <ControlledButton
                    icon={<CancelIcon />}
                    onClick={() => {
                        this.props.history.push('/');
                        return true;
                    }}
                >
                    <FormattedMessage id='signUp.cancel' defaultMessage='Cancel' />
                </ControlledButton>
            </ControlledToolbar>
        </ControllingForm>
    );
};

export default PasswordRecovery