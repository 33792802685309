import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { ControlledButton, ControlledToolbar, ControllingForm } from '../../Form';
import SearchIcon from '@material-ui/icons/Search';
import CreateIcon from '@material-ui/icons/Create';
import { withRouter } from 'react-router';

class SupportActions extends React.PureComponent {
    render = () => {
        const { callId } = this.props;
        if (!callId) {
            return null;
        }

        return (
            <ControllingForm name='supportActions' padding={1} md={12} lg={8}>
                <Box m={1}>
                    <Typography variant='body1'>
                        <FormattedMessage id='call.supportActionHelper' defaultMessage='Have ticket?' />
                    </Typography>
                </Box>
                <ControlledToolbar>
                    <ControlledButton
                        primary
                        icon={<SearchIcon />}
                        onClick={() => {
                            this.props.history.push(`/call/${callId}/find-ticket`);
                            return true;
                        }}
                    >
                        <FormattedMessage id='call.ticket.find' defaultMessage='Find ticket' />
                    </ControlledButton>
                    <ControlledButton
                        icon={<CreateIcon />}
                        onClick={() => {
                            this.props.history.push(`/call/${callId}/create-ticket`);
                            return true;
                        }}
                    >
                        <FormattedMessage id='call.ticket.create' defaultMessage='Create ticket' />
                    </ControlledButton>
                </ControlledToolbar>
            </ControllingForm>
        );
    }
}

SupportActions.propTypes = {
    callId: PropTypes.string,
};

export default withRouter(SupportActions);