import React from 'react';
import { fetcher } from '../../util/deps';
import emitter from '../../emitter';
import ButtonWithProgress from '../ButtonWithProgress';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@material-ui/core';

class RegenerateUtm5LicenseButton extends React.Component {
    state = {
        processing: false,
    };

    regenerateLicense = async () => {
        const {
            id,
            reload,
        } = this.props;
        if (this.state.processing) {
            return false;
        }

        this.setState({
            processing: true,
        });
        let result = false;
        try {
            if ((result = await fetcher.post(`license/utm5/${id}`))) {
                if (result && typeof reload === 'function') {
                    await reload();
                }
            } else {
                emitter.emit('addMessage', { type: 'failure', data: "Can't renew update subscription" });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }
        this.setState({
            processing: false,
        });
    }

    render() {
        const {
            id,
            canRegenerate,
        } = this.props;
        const {
            processing,
        } = this.state;
        if (!id || !canRegenerate) {
            return null;
        }
        return (
            <Tooltip
                title={
                    <FormattedMessage id='license.renewUpdateSubscription' defaultMessage='Renew update subscription' />
                }
            >
                <div>
                    <ButtonWithProgress showProgress={processing} onClick={this.regenerateLicense}>
                        <AutorenewIcon />
                    </ButtonWithProgress>
                </div>
            </Tooltip>
        );
    };
};

export default RegenerateUtm5LicenseButton;