import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { FormattedMessage } from 'react-intl';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button, IconButton, MenuItem, Switch } from '@material-ui/core';
import { fetcher } from '../../util/deps';
import LoadingWrapper from '../../components/common/LoadingWrapper';
import emitter from '../../emitter';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { ControlledSwitch, ControlledTextField, ControllingForm } from '../../components/Form';

const styles = theme => ({
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      display: 'none',
    },
    '&:hover .hover-button': {
      display: 'inherit',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
});

class MailingLists extends React.Component {
  state = {
    mailingLists: [],
    loading: true,
    error: false,
    showForm: false,
    formItem: {
      id: undefined,
      name: '',
      description: '',
      can_unsubscribe: true,
      auto_subscribe: false,
      lang: 'en',
    },
    canSubmit: false,
    confirmDeletionItem: undefined,
  };

  showForm = (formItem) => this.setState({ showForm: true, formItem });

  closeForm = () => this.setState({ showForm: false });

  createOrEditMailingList = async () => {
    try {
      const { formItem } = this.state;
      await fetcher.post('staff/maillist', formItem);
      this.setState({ mailingLists: await fetcher.get('staff/maillists') });
      this.closeForm();
    } catch (e) {
      this.setState({ error: true }, this.closeForm);
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  };

  deleteMailingList = async (id) => {
    try {
      await fetcher.delete(`staff/maillist/${id}`);
      this.setState({ mailingLists: await fetcher.get('staff/maillists') });
    } catch (e) {
      this.setState({ error: true });
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  }

  async componentDidMount() {
    try {
      this.setState({
        mailingLists: await fetcher.get('staff/maillists'),
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false, error: true });
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  };

  render() {
    const {
      mailingLists,
      loading,
      error,
      showForm,
      formItem,
      canSubmit,
      confirmDeletionItem,
    } = this.state;

    const {
      classes,
    } = this.props;

    return (
      <LoadingWrapper wrap={loading} error={error}>
        <ResponsiveDialog
          open={!!(typeof confirmDeletionItem === 'object' && confirmDeletionItem.id && confirmDeletionItem.name)}
          pendingBackendEvent={confirmDeletionItem}
          withSpinner
          title={<FormattedMessage id="mailLists.deleteConfirmationTitle" defaultMessage="Delete confirmation" />}
          message={
            <FormattedMessage
              id='mailLists.deleteConfirmation'
              defaultMessage='Delete {name}?'
              values={{ name: confirmDeletionItem && confirmDeletionItem.name }}
            />}
          onConfirm={async () => {
            await this.deleteMailingList(confirmDeletionItem.id);
            this.setState({ confirmDeletionItem: undefined });
          }}
          confirmButtonText={<FormattedMessage id="mailLists.delete" defaultMessage="Delete" />}
          onClose={() => this.setState({ confirmDeletionItem: undefined })}
          closeOnConfirm
          fullWidth
        />
        <ResponsiveDialog
          open={showForm}
          pendingBackendEvent={showForm}
          title={formItem.id ?
            <FormattedMessage id='mailLists.editTitle' defaultMessage='Edit mailing list' />
            :
            <FormattedMessage id='mailLists.addTitle' defaultMessage='Add mailing list' />
          }
          message={
            <ControllingForm
              name='mailListForm'
              fullWidth
              onUpdate={({ data, canSubmit }) => this.setState({ formItem: { ...formItem, ...data }, canSubmit })}
            >
              <ControlledTextField
                name='name'
                autoFocus
                required
                fullWidth
                label={<FormattedMessage id='mailLists.name' defaultMessage='Name' />}
                value={formItem.name || ''}
              />
              <ControlledTextField
                name='description'
                required
                fullWidth
                multiline
                label={<FormattedMessage id='mailLists.description' defaultMessage='Description' />}
                value={formItem.description || ''}
              />
              <ControlledSwitch
                name='can_unsubscribe'
                label={<FormattedMessage id="mailingLists.canUnsubscribe" defaultMessage="Can unsubscribe" />}
                checked={formItem.can_unsubscribe}
                fullWidth
              />
              <ControlledSwitch
                name='auto_subscribe'
                label={<FormattedMessage id="mailingLists.autoSubscribe" defaultMessage="Subscribe on sign up" />}
                checked={formItem.auto_subscribe}
                fullWidth
              />
              <ControlledTextField
                name='lang'
                required
                fullWidth
                label={<FormattedMessage id='mailLists.lang' defaultMessage='Language' />}
                select
                value={formItem.lang}
              >
                <MenuItem value='en'>
                  <FormattedMessage id='mailingLists.lang.en' defaultMessage='English' />
                </MenuItem>
                <MenuItem value='ru'>
                  <FormattedMessage id='mailingLists.lang.ru' defaultMessage='Russian' />
                </MenuItem>
              </ControlledTextField>
            </ControllingForm>
          }
          onConfirm={this.createOrEditMailingList}
          confirmButtonText={formItem.id ?
            <FormattedMessage id='mailLists.edit' defaultMessage='Edit' />
            :
            <FormattedMessage id='mailLists.add' defaultMessage='Add' />
          }
          ConfirmIcon={formItem.id ? <EditIcon /> : <CreateIcon />}
          disableConfirm={!canSubmit}
          onClose={this.closeForm}
          fullWidth
          withSpinner
        />
        <Paper className={classes.paper}>
          <Toolbar>
            <Button
              color="primary"
              variant='outlined'
              startIcon={<CreateIcon />}
              onClick={() => this.showForm({
                id: undefined,
                name: '',
                description: '',
                can_unsubscribe: true,
                auto_subscribe: false,
                lang: 'en',
              })}
            >
              <FormattedMessage id="mailLists.create" defaultMessage="Create" />
            </Button>
          </Toolbar>
          {mailingLists.length === 0 ?
            <Box padding={2}>
              <Typography color="textSecondary" variant="body1" align="center">
                <FormattedMessage id="mailLists.empty" defaultMessage="No mailing lists" />
              </Typography>
            </Box>
            :
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="mailingLists.id" defaultMessage="ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="mailingLists.nameAndDescription" defaultMessage="Name and description" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="mailingLists.lang" defaultMessage="Language" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="mailingLists.canUnsubscribe" defaultMessage="Can unsubscribe" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="mailingLists.autoSubscribe" defaultMessage="Subscribe on sign up" />
                  </TableCell>
                  <TableCell padding='checkbox' />
                </TableRow>
              </TableHead>
              <TableBody>
                {mailingLists.map(({ id, name, lang, description, can_unsubscribe, auto_subscribe }) => (
                  <TableRow
                    key={id}
                    className={classes.tableRow}
                    onClick={() => this.showForm({ id, name, lang, description, can_unsubscribe, auto_subscribe })}
                  >
                    <TableCell>{id}</TableCell>
                    <TableCell>
                      <Typography variant='body1'>
                        {name}
                      </Typography>
                      {description && description.split("\n").map((line, i) => (
                        <Typography variant='caption' component='p' key={i}>
                          {line || <br/>}
                        </Typography>
                      ))}
                    </TableCell>
                    <TableCell>
                      {
                        lang === 'en' ?
                          <FormattedMessage id='mailingLists.lang.en' defaultMessage='English' />
                          :
                          (lang === 'ru' ?
                            <FormattedMessage id='mailingLists.lang.ru' defaultMessage='Russian' />
                            :
                            lang
                          )
                      }
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={can_unsubscribe}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={auto_subscribe}
                        disabled
                      />
                    </TableCell>
                    <TableCell padding='checkbox'>
                      <IconButton
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          return this.setState({
                            showForm: false,
                            confirmDeletionItem: { id, name },
                          });
                        }}
                      >
                        <DeleteIcon className="hover-button" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </Paper>
      </LoadingWrapper>
    );
  }
}

export default withStyles(styles)(MailingLists);
