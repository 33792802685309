import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { FormattedMessage } from 'react-intl';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Button, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Switch, TableFooter, TablePagination, TextField } from '@material-ui/core';
import { fetcher } from '../../util/deps';
import LoadingWrapper from '../../components/common/LoadingWrapper';
import emitter from '../../emitter';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import { emailValidator } from '../../util/validators';
import TablePaginationActions from '../../components/TablePaginationActions';
import Dropzone from 'react-dropzone';
import validator from 'validator';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import FileSaver from 'file-saver';

const styles = theme => ({
  tableRow: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.table.hover,
    },
    '& .hover-button': {
      display: 'none',
    },
    '&:hover .hover-button': {
      display: 'inherit',
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
  },
  progressContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  progressContainerPercentage: {
    position: 'absolute',
    top: '40%',
    left: '47%',
    transform: 'translate(-50 %, -50 %)',
  }

});

class Subscriptions extends React.Component {
  state = {
    mailingLists: {},
    subscriptions: {},
    page: 0,
    rowsPerPage: + localStorage.getItem('subscriptionsPerPage') || 10,
    filtred: [],
    displayed: [],
    loading: true,
    error: false,
    showAddDialog: false,
    newItems: {
      emails: [],
      lists: [],
    },
    badEmails: [],
    parsing: false,
    importProgress: undefined,
    showImportDialog: false,
    showExportDialog: false,
    stopSpinner: false,
    filterString: undefined,
    exportLists: [],
    unsubscribe: undefined,
    exportLang: 'all',
  };

  updateDisplayed = () => {
    const {
      subscriptions,
      filterString,
      page,
      rowsPerPage,
    } = this.state;
    const filtred = Object.entries(subscriptions)
      .filter(([email]) => !filterString || email.includes(filterString))
      .map(([email, { lists }]) => ({ email, lists }));
    const displayed = filtred.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ displayed, filtred });
  }

  resetNewItems = () => {
    const { mailingLists } = this.state;
    const newItems = {
      emails: [],
      lists: Object.entries(mailingLists).filter(([, { auto_subscribe }]) => auto_subscribe).map((([id]) => id)),
    }
    this.setState({
      newItems,
      badEmails: [],
      importProgress: undefined,
    });
  }

  importSubscriptions = async (unsubscribe) => {
    try {
      let start = 0;
      const step = 20;
      const { newItems } = this.state;
      const { emails, lists } = newItems;
      while (start < emails.length) {
        const data = {
          lists,
          emails: emails.slice(start, start + step)
        };
        this.setState({ importProgress: (start / emails.length) * 100 });
        if (unsubscribe) {
          await fetcher.delete('staff/subscriptions', data);
        } else {
          await fetcher.post('staff/subscriptions', data);
        }
        start += step;

      }
      const subscriptions = await fetcher.get('staff/subscriptions');
      this.setState({
        subscriptions,
        stopSpinner: true,
      });
      this.updateDisplayed();
      this.resetNewItems();
    } catch (e) {
      this.setState({ loading: false, error: true });
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  }

  async componentDidMount() {
    try {
      const mailingLists = Object.fromEntries((await fetcher.get('staff/maillists')).map(({ id, name, auto_subscribe }) => [id, { name, auto_subscribe }]));
      const subscriptions = await fetcher.get('staff/subscriptions');
      this.setState({
        mailingLists,
        subscriptions,
        loading: false,
      });
      this.updateDisplayed();
      this.resetNewItems();
    } catch (e) {
      this.setState({ loading: false, error: true });
      emitter.emit('addMessage', { type: 'failure', data: e });
    }
  };

  render() {
    const {
      displayed,
      filtred,
      loading,
      error,
      mailingLists,
      showAddDialog,
      showImportDialog,
      showExportDialog,
      showUnsubscribeDialog,
      exportLists,
      subscriptions,
      newItems,
      badEmails,
      parsing,
      importProgress,
      stopSpinner,
      filterString,
      rowsPerPage,
      page,
      unsubscribe,
      exportLang,
    } = this.state;

    const {
      classes,
    } = this.props;

    return (
      <LoadingWrapper wrap={loading} error={error}>
        <ResponsiveDialog
          open={showAddDialog}
          title={<FormattedMessage id="subscriptions.addDialogTitle" defaultMessage="Add subscription" />}
          message={
            <React.Fragment>
              <TextField
                defaultValue={newItems.emails[0] || ''}
                variant='outlined'
                label={<FormattedMessage id='subscription.email' defaultMessage='Email' />}
                fullWidth
                onChange={({ target: { value } }) => {
                  newItems.emails = [value];
                  this.setState({ newItems });
                }}
                error={!!emailValidator(newItems.emails[0] || '')}
                helperText={emailValidator(newItems.emails[0] || '')}
              />
              {Object.entries(mailingLists).map(([id, { name }]) => {
                return (
                  <FormControlLabel
                    key={id}
                    control={<Switch
                      checked={newItems.lists.includes(id)}
                      color='primary'
                      onChange={({ target: { checked } }) => {
                        const index = newItems.lists.indexOf(id);
                        if (checked) {
                          if (index === -1) {
                            newItems.lists.push(id);
                          }
                        } else {
                          if (index > -1) {
                            newItems.lists.splice(index, 1);
                          }
                        }
                        this.setState({ newItems });
                      }}
                    />}
                    label={name}
                  />
                );
              })}
            </React.Fragment>
          }
          disableConfirm={newItems.emails.length !== 1 || !!emailValidator(newItems.emails[0]) || newItems.lists.length === 0}
          onConfirm={async () => {
            await this.importSubscriptions(false);
            this.setState({ showAddDialog: false, stopSpinner: false });
            this.resetNewItems();
          }}
          confirmButtonText={<FormattedMessage id="subsctiptions.add" defaultMessage="Add" />}
          onClose={() => {
            this.setState({ showAddDialog: false });
            this.resetNewItems();
          }}
          fullWidth
          withSpinner
          pendingBackendEvent={stopSpinner}
        />
        <ResponsiveDialog
          open={showImportDialog}
          title={importProgress === undefined ?
            <FormattedMessage id="subscriptions.importDialogTitle" defaultMessage="Import subscription" />
            :
            <FormattedMessage id='subscriptions.importing' defaultMessage='Importing' />}
          message={
            importProgress !== undefined ?
              <Box className={classes.progressContainer}>
                <Box>
                  <CircularProgress
                    color='primary'
                    size={100}
                    variant='determinate'
                    value={importProgress}
                  />
                </Box>
                <Box className={classes.progressContainerPercentage} pr={1}>
                  <Typography variant='body2'>
                    {importProgress.toFixed(2)}%
                  </Typography>
                </Box>
              </Box>
              :
              <Dropzone onDrop={async (files) => {
                this.setState({ parsing: true });
                for (const file of files) {
                  for (const email of (await file.text()).split("\n")) {
                    if (!email) {
                      continue;
                    }
                    const stripped = email.trim();
                    if (newItems.emails.indexOf(stripped) !== -1 || badEmails.indexOf(stripped) !== -1) {
                      continue;
                    }
                    if (validator.isEmail(stripped)) {
                      newItems.emails.push(stripped);
                    } else {
                      badEmails.push(stripped)
                    }
                  }
                }
                this.setState({ badEmails, parsing: false });
              }}>
                {({ getRootProps, getInputProps }) => (
                  <Paper {...{ ...getRootProps(), onClick: undefined }}>
                    <ButtonWithProgress
                      showProgress={parsing}
                      variant='outlined'
                      startIcon={<PublishIcon />}
                      onClick={getRootProps().onClick}
                    >
                      <input {...getInputProps()} />
                      <FormattedMessage id='uploadButtonText' defaultMessage='Upload file' />
                    </ButtonWithProgress>
                    <Box>
                      {Object.entries(mailingLists).map(([id, { name }]) => {
                        return (
                          <FormControlLabel
                            key={id}
                            control={<Switch
                              checked={newItems.lists.includes(id)}
                              color='primary'
                              onChange={({ target: { checked } }) => {
                                const index = newItems.lists.indexOf(id);
                                if (checked) {
                                  if (index === -1) {
                                    newItems.lists.push(id);
                                  }
                                } else {
                                  if (index > -1) {
                                    newItems.lists.splice(index, 1);
                                  }
                                }
                                this.setState({ newItems });
                              }}
                            />}
                            label={name}
                          />
                        );
                      })}
                    </Box>
                    {badEmails.length !== 0 && (
                      <React.Fragment>
                        <Typography color='error'>
                          <FormattedMessage id='subscriptions.badEmails' defaultMessage='Bad emails:' />
                        </Typography>
                        {badEmails.map(entry => <Typography variant='body2' color='error' key={entry}>{entry}</Typography>)}
                        <Button
                          variant='outlined'
                          onClick={() => this.setState({ badEmails: [] })}
                        >
                          <FormattedMessage id="subscriptions.ignore" defaultMessage="Ignore" />
                        </Button>
                      </React.Fragment>
                    )}
                  </Paper>
                )}
              </Dropzone>
          }
          disableConfirm={newItems.emails.length === 0 || badEmails.length !== 0 || newItems.lists.length === 0}
          onConfirm={async () => {
            await this.importSubscriptions(false);
            this.setState({ showImportDialog: false, stopSpinner: false });
            this.resetNewItems();
          }}
          confirmButtonText={<FormattedMessage id="subsctiptions.import" defaultMessage="Import" />}
          onClose={() => {
            this.setState({ showImportDialog: false });
            this.resetNewItems();
          }}
          fullWidth
          withSpinner
          pendingBackendEvent={stopSpinner}
        />
        <ResponsiveDialog
          open={showUnsubscribeDialog}
          title={importProgress === undefined ?
            <FormattedMessage id="subscriptions.unsubscribeDialogTitle" defaultMessage="Unsubscribe" />
            :
            <FormattedMessage id='subscriptions.unsubscribing' defaultMessage='Unsubscribing' />}
          message={
            importProgress !== undefined ?
              <Box className={classes.progressContainer}>
                <Box>
                  <CircularProgress
                    color='primary'
                    size={100}
                    variant='determinate'
                    value={importProgress}
                  />
                </Box>
                <Box className={classes.progressContainerPercentage} pr={1}>
                  <Typography variant='body2'>
                    {importProgress.toFixed(2)}%
                  </Typography>
                </Box>
              </Box>
              :
              <Dropzone onDrop={async (files) => {
                this.setState({ parsing: true });
                for (const file of files) {
                  for (const email of (await file.text()).split("\n")) {
                    if (!email) {
                      continue;
                    }
                    const stripped = email.trim();
                    if (newItems.emails.indexOf(stripped) !== -1 || badEmails.indexOf(stripped) !== -1) {
                      continue;
                    }
                    if (validator.isEmail(stripped)) {
                      newItems.emails.push(stripped);
                    } else {
                      badEmails.push(stripped)
                    }
                  }
                }
                this.setState({ badEmails, parsing: false });
              }}>
                {({ getRootProps, getInputProps }) => (
                  <Paper {...{ ...getRootProps(), onClick: undefined }}>
                    <ButtonWithProgress
                      showProgress={parsing}
                      variant='outlined'
                      startIcon={<PublishIcon />}
                      onClick={getRootProps().onClick}
                    >
                      <input {...getInputProps()} />
                      <FormattedMessage id='uploadButtonText' defaultMessage='Upload file' />
                    </ButtonWithProgress>
                    <Box>
                      {Object.entries(mailingLists).map(([id, { name }]) => {
                        return (
                          <FormControlLabel
                            key={id}
                            control={<Switch
                              checked={newItems.lists.includes(id)}
                              color='primary'
                              onChange={({ target: { checked } }) => {
                                const index = newItems.lists.indexOf(id);
                                if (checked) {
                                  if (index === -1) {
                                    newItems.lists.push(id);
                                  }
                                } else {
                                  if (index > -1) {
                                    newItems.lists.splice(index, 1);
                                  }
                                }
                                this.setState({ newItems });
                              }}
                            />}
                            label={name}
                          />
                        );
                      })}
                    </Box>
                    {badEmails.length !== 0 && (
                      <React.Fragment>
                        <Typography color='error'>
                          <FormattedMessage id='subscriptions.badEmails' defaultMessage='Bad emails:' />
                        </Typography>
                        {badEmails.map(entry => <Typography variant='body2' color='error' key={entry}>{entry}</Typography>)}
                        <Button
                          variant='outlined'
                          onClick={() => this.setState({ badEmails: [] })}
                        >
                          <FormattedMessage id="subscriptions.ignore" defaultMessage="Ignore" />
                        </Button>
                      </React.Fragment>
                    )}
                  </Paper>
                )}
              </Dropzone>
          }
          disableConfirm={newItems.emails.length === 0 || badEmails.length !== 0 || newItems.lists.length === 0}
          onConfirm={async () => {
            await this.importSubscriptions(true);
            this.setState({ showUnsubscribeDialog: false, stopSpinner: false });
            this.resetNewItems();
          }}
          confirmButtonText={<FormattedMessage id="subsctiptions.unsubscribe" defaultMessage="Unsubscribe" />}
          onClose={() => {
            this.setState({ showUnsubscribeDialog: false });
            this.resetNewItems();
          }}
          fullWidth
          withSpinner
          pendingBackendEvent={stopSpinner}
        />
        <ResponsiveDialog
          open={showExportDialog}
          title={<FormattedMessage id="subscriptions.exportDialogTitle" defaultMessage="Export subscription" />}
          message={
            <React.Fragment>
              <TextField
                variant='outlined'
                autoFocus
                label={<FormattedMessage id='subscriptions.exportLang' defaultMessage='Token language' />}
                onChange={({ target: { value } }) => this.setState({ exportLang: value })}
                fullWidth
                select
                value={exportLang}
              >
                <MenuItem value='all'>
                  <FormattedMessage id='mailingLists.lang.all' defaultMessage='All' />
                </MenuItem>
                <MenuItem value='en'>
                  <FormattedMessage id='mailingLists.lang.en' defaultMessage='English' />
                </MenuItem>
                <MenuItem value='ru'>
                  <FormattedMessage id='mailingLists.lang.ru' defaultMessage='Russian' />
                </MenuItem>
              </TextField>
              {Object.entries(mailingLists).map(([id, { name }]) => {
                return (
                  <FormControlLabel
                    key={id}
                    control={<Switch
                      checked={exportLists.includes(+id)}
                      color='primary'
                      onChange={({ target: { checked } }) => {
                        const index = exportLists.indexOf(+id);
                        if (checked) {
                          if (index === -1) {
                            exportLists.push(+id);
                          }
                        } else {
                          if (index > -1) {
                            exportLists.splice(index, 1);
                          }
                        }
                        this.setState({ exportLists });
                      }}
                    />}
                    label={name}
                  />
                );
              })}
            </React.Fragment>
          }
          disableConfirm={exportLists.length === 0}
          onConfirm={async () => {
            const data = Object.entries(subscriptions).filter(([, { lists }]) => {
              for (const listId of exportLists) {
                if (lists.includes(listId)) {
                  return true;
                }
              }
              return false;
            }).map(([email, { hash: { ru, en } }]) => {
              switch (exportLang) {
                case 'en':
                  return `${email};${en}\n`;
                case 'ru':
                  return `${email};${ru}\n`;
                default:
                  return `${email};${en};${ru}\n`;
              }
            });
            FileSaver.saveAs(new Blob(data), 'data.csv');
            this.setState({ showAddDialog: false, stopSpinner: true, exportLists: [] }, () => this.setState({ stopSpinner: false }));
          }}
          confirmButtonText={<FormattedMessage id="subsctiptions.export" defaultMessage="Export" />}
          onClose={() => {
            this.setState({ showExportDialog: false, exportLists: [] });
          }}
          fullWidth
          withSpinner
          pendingBackendEvent={stopSpinner}
        />
        <ResponsiveDialog
          open={!!unsubscribe}
          title={<FormattedMessage id="subscriptions.unsubscribeQuestionDialogTitle" defaultMessage="Unsubscribe?" />}
          message={
            <FormattedMessage
              id='subscriptions.unsubscribeQuestion'
              defaultMessage='Unsubscribe {email} from {list}?'
              values={{ email: unsubscribe && unsubscribe.email, list: unsubscribe && mailingLists[unsubscribe.list].name }} />}
          onConfirm={async () => {
            const { email, list } = unsubscribe;
            try {
              await fetcher.delete('staff/subscriptions', { email, lists: [list] });
              const subscriptions = await fetcher.get('staff/subscriptions');
              this.setState({ unsubscribe: undefined, stopSpinner: true, subscriptions }, () => {
                this.setState({ stopSpinner: false });
                this.updateDisplayed();
              });

            } catch (e) {
              this.setState({ stopSpinner: true, error: true }, () => this.setState({ stopSpinner: false }));
              emitter.emit('addMessage', { type: 'failure', data: e });
            }

          }}
          confirmButtonText={<FormattedMessage id="subsctiptions.unsubscribe" defaultMessage="Unsubscribe" />}
          onClose={() => {
            this.setState({ unsubscribe: undefined });
          }}
          fullWidth
          withSpinner
          pendingBackendEvent={stopSpinner}
        />
        <Paper className={classes.paper}>
          <Toolbar>
            <Box mr={1}>
              <Button
                color="primary"
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={() => this.setState({ showAddDialog: true })}
              >
                <FormattedMessage id="subscriptions.add" defaultMessage="Add" />
              </Button>
            </Box>
            <Box mr={1}>
              <Button
                color="primary"
                variant='outlined'
                startIcon={<PublishIcon />}
                onClick={() => this.setState({ showImportDialog: true })}
              >
                <FormattedMessage id="subscriptions.import" defaultMessage="Import" />
              </Button>
            </Box>
            <Box mr={1}>
              <Button
                color="primary"
                variant='outlined'
                startIcon={<DeleteIcon />}
                onClick={() => this.setState({ showUnsubscribeDialog: true })}
              >
                <FormattedMessage id="subscriptions.unsubscribe" defaultMessage="Unsubscribe" />
              </Button>
            </Box>
            <Box mr={1}>
              <Button
                color="primary"
                variant='outlined'
                startIcon={<GetAppIcon />}
                onClick={() => this.setState({ showExportDialog: true })}
              >
                <FormattedMessage id="subscriptions.export" defaultMessage="Export" />
              </Button>
            </Box>
            <TextField
              value={filterString || ''}
              variant='outlined'
              label={<FormattedMessage id='subscription.filter' defaultMessage='Filter' />}
              size='small'
              onChange={({ target: { value } }) => {
                this.setState({ filterString: value }, this.updateDisplayed);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Toolbar>
          {displayed.length === 0 ?
            <Box padding={2}>
              <Typography color="textSecondary" variant="body1" align="center">
                <FormattedMessage id="subscriptions.empty" defaultMessage="No subscription" />
              </Typography>
            </Box>
            :
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="subscriptions.email" defaultMessage="Email" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="subscriptions.lists" defaultMessage="Lists" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayed.map(({ email, lists }) => (
                  <TableRow key={email} className={classes.tableRow}>
                    <TableCell>{email}</TableCell>
                    <TableCell>
                      <Table size='small'>
                        <TableBody>
                          {lists.map((id) => (
                            <TableRow key={id}>
                              <TableCell>
                                {
                                  (mailingLists[id] && mailingLists[id].name) ||
                                  <FormattedMessage
                                    id='subscriptions.unknownList'
                                    defaultMessage='List #{id}'
                                    values={{ id }}
                                  />
                                }
                              </TableCell>
                              <TableCell padding='checkbox'>
                                <IconButton
                                  size='small'
                                  onClick={() => this.setState({ unsubscribe: { email, list: id } })}
                                >
                                  <DeleteIcon className='hover-button' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={filtred.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={(e, page) => this.setState({ page }, this.updateDisplayed)}
                    onChangeRowsPerPage={({ target: { value } }) => {
                      localStorage.setItem('subscriptionsPerPage', value);
                      this.setState({ rowsPerPage: value }, this.updateDisplayed);
                    }}
                    labelDisplayedRows={({ from, to, count }) => (
                      <FormattedMessage
                        id="table.paginationLabelDisplayedRows"
                        defaultMessage="{from}-{to} of {count}"
                        values={{ from, to, count }}
                      />)}
                    labelRowsPerPage={<FormattedMessage id="table.rowsPerPage" defaultMessage="Rows per page" />}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          }
        </Paper>
      </LoadingWrapper>
    );
  }
}

export default withStyles(styles)(Subscriptions);
