import { SET_ACTIONS, RESET_ACTIONS } from '../constants';

const appBar = (appBar = { leftButton: null, rightButtons: null, text: null }, action) => {
  switch (action.type) {
    case SET_ACTIONS:
      return { ...appBar, ...action.payload };
    case RESET_ACTIONS:
      return { leftButton: null, rightButtons: null, text: null };
    default:
      return appBar;
  }
};

export default appBar;