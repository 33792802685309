import React from 'react';
import compose from 'recompose/compose';
import { withTheme } from '@material-ui/core/styles';
import { TextField, Box, FormControl, Toolbar, Button, Typography, Select, MenuItem, InputLabel, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LoadingWrapper from '../components/common/LoadingWrapper';
import { fetcher } from '../util/deps';
import emitter from '../emitter';
import Localize from '../components/common/Localize';
import CreateIcon from '@material-ui/icons/Create';
import WarningIcon from '@material-ui/icons/Warning';
import { Attachments } from '../components/Ticket';
import FileUploader from '../components/FileUploader';

const CheckAvalableTypesWrapper = ({ wrap, children }) => (
    wrap ?
        <Typography color="error" variant="body1" align='center'>
            < FormattedMessage id='ticket.create.noTypesAvailable' defaultMessage='No ticket types available' />
        </Typography >
        : children
);

class NewTicket extends React.Component {
    state = {
        ticketSubject: '',
        ticketBody: '',
        ticketType: null,
        availableTypes: [],
        deprecatedTypes: [],
        attachments: [],
        loading: true,
        error: false,
    };

    createHandler = async () => {
        const {
            ticketType: type,
            ticketSubject: subject,
            ticketBody: text,
            attachments,
        } = this.state;
        const data = {
            type,
            subject,
            text,
            attachments: attachments.map((attach) => attach.id),
        };
        this.setState({ loading: true });
        try {
            const tn = await fetcher.post('ticket/create', data)
            if (tn) {
                const type = this.props.match.params.type.toLowerCase();
                localStorage.removeItem(`ticketSubject.${type}`);
                localStorage.removeItem(`ticketBody.${type}`);
                localStorage.removeItem(`ticketType.${type}`);
                this.props.history.push(`/hotline/${this.props.match.params.type}/ticket/${tn}`);
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ loading: false });
        }
    };

    getTypeChangeHandler = () => (event) => {
        const type = this.props.match.params.type.toLowerCase();
        const ticketType = event.target.value
        this.setState({ ticketType });
        localStorage.setItem(`ticketType.${type}`, ticketType);
    };

    getSubjectChangeHandler = () => (event) => {
        const type = this.props.match.params.type.toLowerCase();
        const ticketSubject = event.target.value
        this.setState({ ticketSubject });
        localStorage.setItem(`ticketSubject.${type}`, ticketSubject);
    };

    getBodyChangeHandler = () => (event) => {
        const type = this.props.match.params.type.toLowerCase();
        const ticketBody = event.target.value
        this.setState({ ticketBody });
        localStorage.setItem(`ticketBody.${type}`, ticketBody);
    };

    attachmentsUploadHandler = (attachments) => {
        this.setState({
            attachments: this.state.attachments.concat(attachments),
        });
    }

    attachmentDeleteHandler = async (id) => {
        try {
            if (await fetcher.delete(`upload/otrs/${id}`)) {
                this.setState({
                    attachments: this.state.attachments.filter((attach) => attach.id !== id),
                });
            } else {
                const errorMessage = <FormattedMessage id='attachment.cantDelete' defaultMessage="Can't delete attachment" />
                const error = new Error(errorMessage);
                error.errorMessage = errorMessage;
                error.details = this.state.attachments.filter((attach) => attach.id === id);
                error.time = new Date();

                emitter.emit('addMessage', { type: 'failure', data: error });
            }
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
        }

    };

    async componentDidMount() {
        const type = this.props.match.params.type.toLowerCase();
        try {
            const types = await fetcher.get('ticket/types');
            const attachments = await fetcher.get('upload/otrs');
            const deprecatedTypes = types.deprecated.filter(value => value.product === type).map(value => value.name);
            const availableTypes = types.active.filter(value => value.product === type).map(value => value.name).concat(deprecatedTypes);
            this.setState({
                ticketSubject: localStorage.getItem(`ticketSubject.${type}`) || '',
                ticketBody: localStorage.getItem(`ticketBody.${type}`) || '',
                availableTypes,
                deprecatedTypes,
                loading: false,
                ticketType: availableTypes.includes(localStorage.getItem(`ticketType.${type}`)) ?
                    localStorage.getItem(`ticketType.${type}`) :
                    (availableTypes.length !== 0 ?
                        availableTypes[0]
                        : null),
                attachments,
            });
        } catch (e) {
            emitter.emit('addMessage', { type: 'failure', data: e });
            this.setState({ error: true });
        }
    };

    render() {
        const {
            ticketBody,
            ticketSubject,
            loading,
            error,
            availableTypes,
            deprecatedTypes,
            ticketType,
            attachments,
        } = this.state;

        const typeLabelText = <FormattedMessage id='ticket.typetLabelText' defaultMessage='Ticket type' />

        return (
            <LoadingWrapper wrap={loading} error={error} >
                <CheckAvalableTypesWrapper wrap={availableTypes.length === 0}>
                    <FileUploader type='otrs' onUpload={this.attachmentsUploadHandler}>
                        <FormControl fullWidth>
                            <Box pb={1}>
                                <InputLabel id='ticket.typeLabel' variant='outlined'>
                                    {typeLabelText}
                                </InputLabel>
                                <Select
                                    label={typeLabelText}
                                    labelId='ticket.typeLabel'
                                    fullWidth
                                    value={ticketType}
                                    variant='outlined'
                                    onChange={this.getTypeChangeHandler()}
                                >
                                    {availableTypes.map(type => {
                                        return (
                                            <MenuItem key={type} value={type} variant='outlined'>
                                                <Localize>{type}</Localize>
                                                {deprecatedTypes.includes(type) ?
                                                    <Tooltip
                                                        title={
                                                            <FormattedMessage
                                                                id='ticket.typeIsDeprecated'
                                                                defaultMessage='This ticket type is deprecated'
                                                            />
                                                        }
                                                    >
                                                        <WarningIcon color='primary' />
                                                    </Tooltip>
                                                    : null}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                            <Box pb={1}>
                                <TextField
                                    autoFocus
                                    label={<FormattedMessage id='ticket.subjectLabel' defaultMessage='Ticket subject' />}
                                    variant='outlined'
                                    fullWidth
                                    defaultValue={ticketSubject}
                                    onChange={this.getSubjectChangeHandler()}
                                    required
                                />
                            </Box>
                            <Box pb={1}>
                                <TextField
                                    multiline
                                    label={<FormattedMessage id='ticket.bodyLabel' defaultMessage='Ticket text' />}
                                    rows={10}
                                    rowsMax={25}
                                    variant='outlined'
                                    fullWidth
                                    defaultValue={ticketBody}
                                    onChange={this.getBodyChangeHandler()}
                                    required
                                />
                            </Box>
                        </FormControl>
                        <Attachments {...{ attachments }} deleteHandler={this.attachmentDeleteHandler} />
                    </FileUploader>
                    <Toolbar>
                        <Box mr={3}>
                            <Button
                                color='primary'
                                variant='contained'
                                startIcon={<CreateIcon />}
                                disabled={ticketSubject === '' || ticketBody === ''}
                                onClick={this.createHandler}
                            >
                                <FormattedMessage id='ticket.createButtonText' defaultMessage='Create ticket' />
                            </Button>
                        </Box>
                    </Toolbar>
                </CheckAvalableTypesWrapper>
            </LoadingWrapper>
        );
    };
};

NewTicket.propTypes = {
}

export default compose(
    withTheme,
)(NewTicket)