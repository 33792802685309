import React from 'react';
import qs from 'qs';
import { saveAs } from 'file-saver';
import store from './store';
import { authActions } from '../redux-stuff/actions';
import emitter from '../emitter';
import { FormattedMessage } from 'react-intl';

class Fetcher {
  constructor(params) {
    params = params || {};
    this.urlPrefix_ = params.urlPrefix || '.';
    this.credentials_ = params.credentials || 'same-origin'
  }
  set prefix(value) {
    this.urlPrefix_ = value;
  }
  async get(method, params, throwError = true, progressCallback) {
    let uri = this.makeUri_(method);
    if (params) {
      uri += '?' + qs.stringify(params);
    }
    let response = await fetch(uri, {
      credentials: this.credentials_,
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      const contentDisposition = response.headers.get('content-disposition');
      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filename = contentDisposition.split('filename=')[1].slice(1, -1);
        let blob;
        if (progressCallback instanceof Function) {
          let chunks = [];
          const reader = response.body.getReader();
          const contentLength = +response.headers.get('Content-Length');
          let receivedLength = 0;
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            chunks.push(value);
            receivedLength += value.length;
            progressCallback(receivedLength / contentLength);
          }
          blob = new Blob(chunks);
        } else {
          blob = await response.blob();
        }

        saveAs(blob, filename);
      } else {
        return Fetcher.extract(response);
      }
    }
  }
  async put(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(params),
      credentials: this.credentials_,
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }
  async post(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(params),
      credentials: this.credentials_,
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }
  async delete(method, params, throwError = true) {
    let uri = this.makeUri_(method);
    let response = await fetch(uri, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify(params),
      credentials: this.credentials_,
    });
    const isOk = await Fetcher.validate(response, uri, throwError);
    if (isOk) {
      return Fetcher.extract(response);
    }
  }

  makeUri_(method) {
    return this.urlPrefix_ + method;
  }

  static async extract(response) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      return await response.text();
    }
  }

  static async validate(response, uri, throwError) {
    if (response.ok) {
      return true;
    }

    if (response.status === 403) {
      store.dispatch(authActions.setIsAuth(false));
      const errorMessage = <FormattedMessage id='fetcher.accessDenied' defaultMessage='Access denied' />
      const error = new Error(errorMessage);
      error.errorMessage = errorMessage;
      error.time = new Date();
      error.agent = 'fetcher';
      error.uri = uri;
      error.statusCode = response.status;

      throw error;
    }

    const contentType = response.headers.get('content-type') || '';
    const error = await parseErrorResponse(response, contentType, uri, response.status);

    if (throwError) {
      throw error;
    } else {
      emitter.emit('addMessage', { type: 'failure', data: error });
      return false;
    }
  }
}

async function parseErrorResponse(res, contentTypeStr, uri, statusCode) {
  let errorMessage;
  let errorDetails;

  if (contentTypeStr.includes('application/json')) {
    try {
      const res_ = await res.json();

      errorMessage = res_.message || res_.type || res_;
      errorDetails = res_;
    } catch (e) {
      if (statusCode === 500) {
        errorMessage = <FormattedMessage id='fetcher.internalServerError' defaultMessage='Internal server error' />
      } else {
        errorMessage = <FormattedMessage id='fetcher.unknownEror' defaultMessage='Unknown error' />
      }
      errorDetails = e;
    }
  } else if (contentTypeStr.includes('text/html')) {
    const res_ = await res.text();

    const matches = res_.match(/<title>(.*?)<\/title>/);
    errorMessage = matches[1].trim();
  } else {
    errorMessage = await res.text();
  }

  const error = new Error(errorMessage);
  error.errorMessage = errorMessage;
  error.details = errorDetails;
  error.contentType = contentTypeStr;
  error.time = new Date();
  error.agent = 'fetcher';
  error.uri = uri;
  error.statusCode = statusCode;

  return error;
}

export default Fetcher;
