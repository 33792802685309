import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, IconButton } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { injectIntl } from 'react-intl';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

class ControlledPhone extends React.Component {
    static controlType = 'input';

    static cleanup = (formName, name) => {
        localStorage.removeItem(`${formName}.${name}`)
    }

    constructor(props) {
        super(props);
        const { name, formName, onChange } = props;
        const value = localStorage.getItem(`${formName}.${name}`);
        this.state = {
            value,
        };
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null);
        }
    };

    changeHandler = (value) => {
        const { formName, onChange, name } = this.props
        localStorage.setItem(`${formName}.${name}`, value);
        this.setState({ value });
        if (typeof onChange === 'function') {
            onChange(value !== '' ? value : null);
        }
    };

    render() {
        const {
            value,
        } = this.state;
        const {
            index,
            validator,
            intl: {
                locale,
            },
            formName,
            ...restProps
        } = this.props;

        return (
            <Box mt={index === 0 ? 0 : 1} key={index}>
                <MuiPhoneNumber
                    {...restProps}
                    defaultCountry={locale === 'ru' ? 'ru' : 'us'}
                    fullWidth
                    variant='outlined'
                    onChange={this.changeHandler}
                    value={value}
                    error={typeof validator === 'function' && !!validator(value)}
                    helperText={typeof validator === 'function' ? validator(value) : undefined}
                    InputProps={value && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="Clear" onClick={() => this.changeHandler('')} >
                                    <HighlightOffIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        );
    };
};

ControlledPhone.propTypes = {
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    label: PropTypes.element.isRequired,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    validator: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
};

export default injectIntl(ControlledPhone);